// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

const MoneyStack = createIcon({
  displayName: "MoneyStackIcon",
  viewBox: "0 0 83 69",
  path: (
    <>
      <path
        d="M77.59 0H5.4C3.98 0 2.61.55 1.6 1.52A5.06 5.06 0 0 0 0 5.17V48.3H77.6c1.43 0 2.8-.55 3.82-1.52A5.06 5.06 0 0 0 83 43.12V5.17c0-1.37-.57-2.68-1.59-3.65A5.54 5.54 0 0 0 77.6 0Z"
        fill="#35DC86"
      />
      <path
        d="M41.5 44.85c11.96 0 21.65-9.27 21.65-20.7s-9.7-20.7-21.65-20.7c-11.96 0-21.65 9.27-21.65 20.7s9.7 20.7 21.65 20.7Z"
        fill="#F1F2F2"
      />
      <path
        d="M79.4 62.1a3.68 3.68 0 0 0 2.56-.99A3.37 3.37 0 0 0 83 58.65v6.9A3.25 3.25 0 0 1 81.96 68a3.55 3.55 0 0 1-2.57.99H3.61A3.68 3.68 0 0 1 1.04 68 3.37 3.37 0 0 1 0 65.55V62.1h79.4Z"
        fill="#35C286"
      />
      <path
        d="M83 55.2v3.45a3.25 3.25 0 0 1-1.04 2.46 3.55 3.55 0 0 1-2.57.99H0V51.75a3.25 3.25 0 0 0 1.04 2.46 3.55 3.55 0 0 0 2.57.99H83Z"
        fill="#35DC86"
      />
      <path
        d="M0 48.3H77.6c1.43 0 2.8-.54 3.82-1.52A5.06 5.06 0 0 0 83 43.13V55.2H3.6a3.68 3.68 0 0 1-2.56-.99A3.37 3.37 0 0 1 0 51.75V48.3Z"
        fill="#35C286"
      />
      <path
        d="M9.02 25.88h-1.8c-.48 0-.94-.19-1.28-.51a1.69 1.69 0 0 1-.53-1.22c0-.46.2-.9.53-1.22.34-.32.8-.5 1.28-.5h1.8c.48 0 .94.18 1.28.5.34.32.53.76.53 1.22 0 .46-.2.9-.53 1.22-.34.32-.8.5-1.28.5ZM75.78 25.88h-1.8c-.48 0-.94-.19-1.28-.51a1.69 1.69 0 0 1-.53-1.22c0-.46.2-.9.53-1.22.34-.32.8-.5 1.28-.5h1.8c.48 0 .94.18 1.28.5.34.32.53.76.53 1.22 0 .46-.2.9-.53 1.22-.34.32-.8.5-1.28.5Z"
        fill="#2EBC70"
      />
      <path
        d="M48.72 20.7c.48 0 .93-.18 1.27-.5.34-.33.53-.77.53-1.22v-1.73c0-1.37-.57-2.69-1.58-3.66a5.54 5.54 0 0 0-3.83-1.51h-1.8v-1.73c0-.46-.2-.9-.53-1.22a1.85 1.85 0 0 0-2.56 0c-.33.32-.52.76-.52 1.22v1.73h-1.8c-1.44 0-2.82.54-3.84 1.51a5.06 5.06 0 0 0-1.58 3.66v3.45c0 1.37.57 2.69 1.58 3.66a5.54 5.54 0 0 0 3.83 1.52h7.22c.48 0 .94.18 1.27.5.34.32.53.76.53 1.22v3.45c0 .46-.19.9-.53 1.22-.33.32-.8.5-1.27.5h-7.22c-.48 0-.94-.18-1.27-.5a1.69 1.69 0 0 1-.53-1.22v-1.73c0-.45-.2-.9-.53-1.21a1.85 1.85 0 0 0-2.55 0c-.34.32-.53.76-.53 1.21v1.73c0 1.37.57 2.69 1.58 3.66a5.54 5.54 0 0 0 3.83 1.51h1.8v1.73c0 .46.2.9.53 1.22a1.85 1.85 0 0 0 2.56 0c.33-.32.52-.76.52-1.22v-1.73h1.8c1.44 0 2.82-.54 3.84-1.51a5.06 5.06 0 0 0 1.58-3.66V27.6c0-1.37-.57-2.69-1.58-3.66a5.54 5.54 0 0 0-3.83-1.51h-7.22c-.48 0-.94-.19-1.27-.51a1.69 1.69 0 0 1-.53-1.22v-3.45c0-.46.19-.9.53-1.22.33-.32.8-.5 1.27-.5h7.22c.48 0 .94.18 1.27.5.34.32.53.76.53 1.22v1.73c0 .45.2.9.53 1.21.34.33.8.51 1.28.51Z"
        fill="#D0D7DF"
      />
    </>
  )
});

export default MoneyStack;
