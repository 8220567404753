import { Box, Button, Divider, Flex, Input, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tooltip from "../../components/Tooltip";
import { AdjustInput, Toggle, Icon } from "../../components";
import { Form } from "react-final-form";
import BigNumber from "bignumber.js";
import { format } from "../../Utils/number";
import moment from "moment";

export default function CreateLock({ balances, isloading, createLock }) {
  const [show, setShow] = React.useState(true);
  const handleToggle = () => setShow(!show);
  const [isLocked, setIsLocked] = useState(false);

  const [selectedDate, setSelectedDate] = useState(moment().add(112, "days").format("YYYY-MM-DD"));

  const [amountError, setAmountError] = useState(false);
  const [amount, setAmount] = useState(0);
  const [calcVePreon, setCalcVePreon] = useState(0);

  const onAmountChanged = event => {
    if (Number.isNaN(event.target.value)) {
      return;
    }
    setAmount(() => event.target.value);
  };

  const onDateChange = event => {
    console.log(event.target.value);
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (!amount) {
      setCalcVePreon(() => 0);
      return;
    }
    const now = moment();
    const expiry = moment(selectedDate);
    const dayToExpire = expiry.diff(now, "days");

    const _calc = BigNumber(amount)
      .times(parseInt(dayToExpire) + 1)
      .div(112)
      .toFixed(3);

    setCalcVePreon(() => _calc);
  }, [selectedDate, amount]);

  const createLockHandler = () => {
    const now = moment();
    const expiry = moment(selectedDate).add(1, "days");
    const secondsToExpire = expiry.diff(now, "seconds");

    let _amount = BigNumber(amount)
      .times(10 ** 18)
      .toFixed(0);
    console.log("creating lock for", +_amount, secondsToExpire);
    createLock(_amount, secondsToExpire);
  };

  return (
    <>
      <Box layerStyle="card" flex={1} margin="2">
        <Flex>
          <Text textStyle="title2" mt={-3} mb={1}>
            Create New Lock{" "}
            <Tooltip>
              New STAR/MATIC LP LP Farm will provide boosted yields based on vePREON balances.
            </Tooltip>
          </Text>
        </Flex>

        <Form
          onSubmit={() => {}}
          render={({ values }) => (
            <>
              {setAmount(+values["govtoken"])}
              <AdjustInput
                mt={4}
                max={format(balances.govToken / 1e18)}
                name="govtoken"
                // onChange={onAmountChanged}
                token="LP"
                fillContainer
                noCurrencyConvert={true}
                borderRadius={50}
              />
              <Input
                // inputRef={inputEl}
                ml={1}
                w={"98%"}
                id="someDate"
                type="date"
                placeholder="Expiry Date"
                fullWidth={false}
                style={{
                  color: "white",
                  marginTop: "0.5em",
                  outlineColor: "#C157F9",
                  border: "0px",
                  borderRadius: "0"
                }}
                error={amountError}
                helperText={amountError}
                onChange={onDateChange}
                disabled={isloading}
                value={selectedDate}
                max={moment().add(112, "days").format("YYYY-MM-DD")}
                min={moment().add(7, "days").format("YYYY-MM-DD")}
              />
              <Flex>
                <Text textStyle="body1" fontWeight="bold" mb={2} color="white" mt={2}>
                  Note: You can lock for any time period between 1 to 16 weeks.
                </Text>
              </Flex>
              <Box>
                <Flex>
                  <Text textStyle="body1" paddingTop="5" marginRight="2">
                    Expires:
                  </Text>
                  <Toggle
                    style={{
                      width: "100%"
                    }}
                    options={[
                      { value: "16W", key: "112" },
                      { value: "12W", key: "84" },
                      { value: "8W", key: "56" },
                      { value: "4W", key: "28" },
                      { value: "1W", key: "7" }
                    ]}
                    size="md"
                    onChange={value => {
                      setSelectedDate(moment().add(value, "days").format("YYYY-MM-DD"));
                    }}
                  />
                </Flex>
                <Flex
                  style={{
                    padding: "10px"
                  }}
                >
                  <Text textStyle="body2">Your Voting Power Will Be:</Text>
                </Flex>
                <Flex>
                  <Text textStyle="title3" color="white">
                    {Number.isNaN(calcVePreon) ? "0.00" : calcVePreon} vePREON
                  </Text>
                  <Spacer />
                  <Text textStyle="body2">
                    {+values["govtoken"] > 0 ? BigNumber(values["govtoken"]).toFixed(3) : "0.00"}{" "}
                    locked expires in {moment(selectedDate).from(moment())}
                  </Text>
                </Flex>
                <Flex>
                  <Text textStyle="title4">Boosted</Text>
                  <Spacer />
                  <Text textStyle="body2">
                    Locked until {moment(selectedDate).format("DD MMM YYYY")}
                  </Text>
                </Flex>
                <Divider mt={3} mb={1} height="0.5px" opacity="0.4" />

                {isLocked ? (
                  <Flex>
                    <Flex
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Text textStyle="body1" color="white">
                        Locked for 16 weeks = 1.00 vePREON
                      </Text>
                    </Flex>
                    <Flex
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Text textStyle="body1" color="white">
                        Locked for 12 weeks = 0.75 vePREON
                      </Text>
                    </Flex>
                    <Flex
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Text textStyle="body1" color="white">
                        Locked for 8 weeks = 0.50 vePREON
                      </Text>
                    </Flex>
                    <Flex
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Text textStyle="body1" color="white">
                        Locked for 4 weeks = 0.25 vePREON
                      </Text>
                    </Flex>
                    <Flex
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Text textStyle="body1" color="white">
                        Lock for more than 8 weeks to get additional PREON tokens in vePREON Boost
                      </Text>
                    </Flex>{" "}
                  </Flex>
                ) : (
                  <>
                    <Flex p={"10px"}>
                      <Text textStyle="body1">Please Create A Lock To Earn vePREON</Text>
                    </Flex>
                  </>
                )}
              </Box>
              <Flex justifyContent={"right"} pb={1}>
                <Button onClick={createLockHandler} variant="gradient">
                  Lock
                </Button>
              </Flex>
            </>
          )}
        />
      </Box>
    </>
  );
}
