// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

const WETHAVAX = createIcon({
  displayName: "BlueAddIcon",
  viewBox: "0 0 2500 2500",
  path: (
    <g>
      <path d="M2029.36 496L2019.28 530.251V1524.06L2029.36 1534.12L2490.67 1261.44L2029.36 496Z" fill="#343434"/>
<path d="M2029.36 496L1568.05 1261.44L2029.36 1534.12V1051.75V496Z" fill="#8C8C8C"/>
<path d="M2029.36 1621.46L2023.68 1628.39V1982.4L2029.36 1998.99L2490.95 1348.92L2029.36 1621.46Z" fill="#3C3C3B"/>
<path d="M2029.36 1998.99V1621.46L1568.05 1348.92L2029.36 1998.99Z" fill="#8C8C8C"/>
<path d="M2029.36 1534.12L2490.67 1261.44L2029.36 1051.75V1534.12Z" fill="#141414"/>
<path d="M1568.05 1261.44L2029.36 1534.12V1051.75L1568.05 1261.44Z" fill="#393939"/>
<path d="M1215 754H287V1598H1215V754Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1502.5 1248C1502.5 1662.77 1166.27 1999 751.5 1999C336.734 1999 0.5 1662.77 0.5 1248C0.5 833.234 336.734 497 751.5 497C1166.27 497 1502.5 833.234 1502.5 1248ZM538.688 1546.86H392.94C362.314 1546.86 347.186 1546.86 337.962 1540.96C327.999 1534.5 321.911 1523.8 321.173 1511.99C320.619 1501.11 328.184 1487.82 343.312 1461.26L703.182 826.935C718.495 799.999 726.243 786.531 736.021 781.55C746.537 776.2 759.083 776.2 769.599 781.55C779.377 786.531 787.126 799.999 802.438 826.935L876.42 956.079L876.797 956.738C893.336 985.635 901.723 1000.29 905.385 1015.67C909.443 1032.46 909.443 1050.17 905.385 1066.96C901.695 1082.46 893.393 1097.22 876.604 1126.55L687.573 1460.7L687.084 1461.56C670.436 1490.69 661.999 1505.46 650.306 1516.6C637.576 1528.78 622.263 1537.63 605.474 1542.62C590.161 1546.86 573.004 1546.86 538.688 1546.86ZM906.75 1546.86H1115.59C1146.4 1546.86 1161.9 1546.86 1171.13 1540.78C1181.09 1534.32 1187.36 1523.43 1187.92 1511.63C1188.45 1501.1 1181.05 1488.33 1166.55 1463.31C1166.05 1462.46 1165.55 1461.59 1165.04 1460.71L1060.43 1281.75L1059.24 1279.73C1044.54 1254.88 1037.12 1242.32 1027.59 1237.47C1017.08 1232.12 1004.71 1232.12 994.199 1237.47C984.605 1242.45 976.857 1255.55 961.544 1281.93L857.306 1460.89L856.949 1461.51C841.69 1487.85 834.064 1501.01 834.614 1511.81C835.352 1523.62 841.44 1534.5 851.402 1540.96C860.443 1546.86 875.94 1546.86 906.75 1546.86Z" fill="#E84142"/>
    </g>
  )
});

export default WETHAVAX;
