// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

// #TODO!! pending, getting error from this svg, but can't figure out why
const stMATIC = createIcon({
    displayName: "stMATIC",
    viewBox: "0 0 250 250",
    path: (
        <g>
            <linearGradient id="a" x1="207.097" x2="30.692" y1="52.077" y2="182.805" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#00a3ff"/><stop offset="1" stop-color="#3adcff"/>
            </linearGradient>
            <path fill="#fff" d="M125 0c69.031 0 125 55.969 125 125s-55.969 125-125 125S0 194.047 0 125 55.96 0 125 0zm0 0"/>
            <path fill="url(#a)" d="M166.504 96.637a10.386 10.386 0 0 0-10.379 0l-23.809 14.18-16.175 9.25-23.809 14.183a10.381 10.381 0 0 1-5.187 1.387c-1.82 0-3.61-.477-5.188-1.387l-18.922-11.102a10.627 10.627 0 0 1-3.754-3.796 10.686 10.686 0 0 1-1.437-5.141V92.32a9.993 9.993 0 0 1 5.191-8.941l18.617-10.794a10.381 10.381 0 0 1 5.188-1.387c1.82 0 3.61.477 5.187 1.387l18.621 10.793a10.648 10.648 0 0 1 5.188 8.941v14.18l16.176-9.555V82.762a10.02 10.02 0 0 0-5.188-8.941L92.332 53.472a10.385 10.385 0 0 0-5.187-1.391c-1.82 0-3.61.48-5.188 1.39L46.855 73.82a10.02 10.02 0 0 0-5.187 8.942v41.004a10.003 10.003 0 0 0 1.352 5.191 9.992 9.992 0 0 0 3.835 3.75l35.102 20.348a10.374 10.374 0 0 0 10.375 0l23.809-13.871 16.175-9.559 23.809-13.875c1.578-.91 3.367-1.387 5.188-1.387s3.613.477 5.19 1.387l18.618 10.793a10.648 10.648 0 0 1 5.188 8.937v21.891a10.01 10.01 0 0 1-5.188 8.941l-18.617 11.102a10.396 10.396 0 0 1-10.379 0l-18.617-10.793a10.659 10.659 0 0 1-3.754-3.797 10.689 10.689 0 0 1-1.438-5.144V143.5l-16.175 9.555v14.183a10.02 10.02 0 0 0 5.188 8.941l35.1 20.348a10.385 10.385 0 0 0 10.38 0l35.097-20.347a10.659 10.659 0 0 0 3.754-3.797 10.689 10.689 0 0 0 1.438-5.145v-41.004a10.03 10.03 0 0 0-1.352-5.191 9.976 9.976 0 0 0-3.84-3.75zm0 0"/>
        </g>
    )
  });

export default stMATIC;
