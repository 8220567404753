import { Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import CreateLock from "./CreateLock";
import Increase from "./Increase";
import Rewards from "./Rewards";
import TestTable from "./Table";
import Unlock from "./Unlock";
import { useWeb3React } from "@web3-react/core";
import { useLiquity } from "../../hooks/LiquityContext";
import useVesting from "./store/useVesting";

export default function Vesting() {
  const { chainId, library } = useWeb3React();
  const { account } = useLiquity();
  const [manageNft, setManageNft] = useState();

  const {
    nfts,
    balances,
    isloading,
    createLock,
    increaseVestAmount,
    rageQuit,
    claimReward,
    withdrawLock,
    getUserNfts,
    increaseVestDuration,
    claimMany,
    getLockEnded,
    claimable
  } = useVesting(account, chainId, library);

  return (
    <>
      <TestTable nfts={nfts} rageQuit={rageQuit} setManageNft={setManageNft} />
      <Flex justifyContent={"space-between"}>
        <CreateLock balances={balances} isloading={isloading} createLock={createLock} />
        <Unlock withdrawLock={withdrawLock} selectedNft={manageNft} endUnixToken={getLockEnded} />
      </Flex>
      <Flex justifyContent={"space-between"}>
        <Rewards claimMany={claimMany} claimReward={claimReward} nfts={nfts} selectedNft={manageNft} claimable={claimable} />
        <Increase
          increaseVestAmount={increaseVestAmount}
          increaseVestDuration={increaseVestDuration}
          selectedNft={manageNft}
          balances={balances}
          isloading={isloading}
        />
      </Flex>
    </>
  );
}
