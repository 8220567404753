import { Box, Button, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import moment from "moment";

export default function Unlock({ withdrawLock, selectedNft, endUnixToken }) {
  const [nftEndUnix, setNftEndUnix] = useState(false);

  const handleUnlock = () => {
    withdrawLock(+selectedNft?.id);
  };

  useEffect(() => {
    async function _check() {
      const _result = await endUnixToken(+selectedNft?.id);
      console.log("check if ended", +_result);
      setNftEndUnix(() => +_result);
    }

    _check();
  }, [selectedNft?.lockEnds]);

  return (
    <>
      <Box layerStyle="card" flex={1} margin="2">
        {!selectedNft && (
          <Text textStyle="title3" mt={-3}>
            select "Manage" from Vesting Table
          </Text>
        )}

        {selectedNft && (
          <div>
            <Text textStyle="title2" mt={-3}>
              Unlock NFT #{+selectedNft?.id}
            </Text>
            <Spacer />

            <Divider mt={3} />

            {moment().unix() <= nftEndUnix && (
              <Text textStyle="title4">
                You can unlock your token after{" "}
                {moment.unix(selectedNft?.lockEnds).format("DD MMM YYYY")}
              </Text>
            )}

            {moment().unix() >= nftEndUnix && (
              <>
                <Flex mt={5}>
                  <Text textStyle="title4">
                    Your lock has expired. Please withdraw your lock before you can re-lock.
                  </Text>
                </Flex>
                <Flex justifyContent={"right"} pb={1} mt={3}>
                  <Button
                    variant="gradient"
                    onClick={() => {
                      handleUnlock();
                    }}
                  >
                    Unlock
                  </Button>
                </Flex>
              </>
            )}
          </div>
        )}
      </Box>
    </>
  );
}
