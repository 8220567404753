const Modal = {
  baseStyle: {
    dialog: {
      bg: "brand.1100",
      color: "white",
      backdropFilter: "blur(10px)"
    }
  }
};

export default Modal;
