// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

const sJOE = createIcon({
  displayName: "sJOE",
  viewBox: "0 0 50 50",
  path: (
    <>
      <path
        d="M41.9826 36.7269L41.9954 41.3138L34.9562 45.3775L34.9434 40.7906L41.9826 36.7269Z"
        fill="#3C1A26"
      />
      <path
        d="M34.9561 45.3775L22.5147 38.1457L22.5002 33.5588L34.9433 40.7907L34.9561 45.3775Z"
        fill="#29121A"
      />
      <path
        d="M34.9434 40.7909L22.5004 33.559L29.5396 29.4954L41.9826 36.7272L34.9434 40.7909Z"
        fill="#421D2A"
      />
      <path
        d="M33.9147 41.3495L33.9275 45.9364L26.8899 50.0001L26.8771 45.4132L33.9147 41.3495Z"
        fill="#3C1A26"
      />
      <path
        d="M26.8899 50L14.4469 42.7682L14.4341 38.1813L26.8771 45.4131L26.8899 50Z"
        fill="#29121A"
      />
      <path
        d="M26.8771 45.4132L14.4341 38.1814L21.4733 34.1177L33.9147 41.3495L26.8771 45.4132Z"
        fill="#421D2A"
      />
      <path
        d="M41.7565 28.8037L41.7758 35.3454L24.6961 45.2061L24.6785 38.6628L41.7565 28.8037Z"
        fill="#DB5B54"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="24"
        y="28"
        width="18"
        height="18"
      >
        <path
          d="M41.7565 28.8037L41.7758 35.3454L24.6961 45.2061L24.6785 38.6628L41.7565 28.8037Z"
          fill="#DB5B54"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7517 4.4132C26.8978 4.4132 27.039 4.39394 27.1818 4.3811C40.9377 10.7142 51.7405 19.1256 59.0541 29.7807C66.1672 40.1534 69.9083 52.1904 72.1696 64.4215C72.0493 64.5323 71.9241 64.6559 71.7989 64.7778C69.4637 52.5226 65.6648 40.4631 58.5325 30.0375C51.2124 19.339 40.4402 10.8442 26.7517 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1819 4.38116C27.3199 4.36029 27.4627 4.34585 27.604 4.33301C41.4176 10.5778 52.2589 18.9186 59.5678 29.5304C66.6824 39.8565 70.3561 51.8694 72.5372 64.0621C72.42 64.1808 72.2949 64.3044 72.1697 64.4264C69.9083 52.1904 66.1672 40.1534 59.0542 29.7808C51.7405 19.1256 40.9377 10.7142 27.1819 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1837 4.56569C25.3204 4.54042 25.4588 4.5254 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C70.6562 65.8515 70.5214 65.951 70.3962 66.073C67.7721 53.7407 63.7967 41.5754 56.6275 30.9684C49.2866 20.1078 38.6378 11.3561 25.1837 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7517 4.4132C26.8978 4.4132 27.039 4.39394 27.1818 4.3811C40.9377 10.7142 51.7405 19.1256 59.0541 29.7807C66.1672 40.1534 69.9083 52.1904 72.1696 64.4215C72.0493 64.5323 71.9241 64.6559 71.7989 64.7778C69.4637 52.5226 65.6648 40.4631 58.5325 30.0375C51.2124 19.339 40.4402 10.8442 26.7517 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1819 4.38116C27.3199 4.36029 27.4627 4.34585 27.604 4.33301C41.4176 10.5778 52.2589 18.9186 59.5678 29.5304C66.6824 39.8565 70.3561 51.8694 72.5372 64.0621C72.42 64.1808 72.2949 64.3044 72.1697 64.4264C69.9083 52.1904 66.1672 40.1534 59.0542 29.7808C51.7405 19.1256 40.9377 10.7142 27.1819 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1837 4.56569C25.3204 4.54042 25.4588 4.5254 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C70.6562 65.8515 70.5214 65.951 70.3962 66.073C67.7721 53.7407 63.7967 41.5754 56.6275 30.9684C49.2866 20.1078 38.6378 11.3561 25.1837 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7517 4.4132C26.8978 4.4132 27.039 4.39394 27.1818 4.3811C40.9377 10.7142 51.7405 19.1256 59.0541 29.7807C66.1672 40.1534 69.9083 52.1904 72.1696 64.4215C72.0493 64.5323 71.9241 64.6559 71.7989 64.7778C69.4637 52.5226 65.6648 40.4631 58.5325 30.0375C51.2124 19.339 40.4402 10.8442 26.7517 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.1819 4.38116C27.3199 4.36029 27.4627 4.34585 27.604 4.33301C41.4176 10.5778 52.2589 18.9186 59.5678 29.5304C66.6824 39.8565 70.3561 51.8694 72.5372 64.0621C72.42 64.1808 72.2949 64.3044 72.1697 64.4264C69.9083 52.1904 66.1672 40.1534 59.0542 29.7808C51.7405 19.1256 40.9377 10.7142 27.1819 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.1837 4.56569C25.3204 4.54042 25.4588 4.5254 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C70.6562 65.8515 70.5214 65.951 70.3962 66.073C67.7721 53.7407 63.7967 41.5754 56.6275 30.9684C49.2866 20.1078 38.6378 11.3561 25.1837 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
      </g>
      <path
        d="M24.6962 45.2061L7.73044 35.3454L7.71118 28.8037L24.6785 38.6628L24.6962 45.2061Z"
        fill="#A24741"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="7"
        y="28"
        width="18"
        height="18"
      >
        <path
          d="M24.6962 45.2061L7.73044 35.3454L7.71118 28.8037L24.6785 38.6628L24.6962 45.2061Z"
          fill="#A24741"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.33521 19.9843 -7.74299 19.2444 -7.16201 18.5254C-3.35511 31.0438 0.674864 43.2798 5.54422 55.0713C10.3943 66.8161 16.2973 78.163 23.131 89.176C21.8054 89.4248 20.4588 89.627 19.13 89.818C12.1902 79.2672 6.3194 68.3488 1.82239 56.8977C-2.7003 45.3903 -6.03856 33.2779 -8.94348 20.7049Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.86321 20.6025 -8.77693 20.505 -8.68509 20.4128C-5.6614 32.973 -2.22364 45.1079 2.33597 56.649C6.87792 68.1435 12.7471 79.118 19.6772 89.741C19.5023 89.7683 19.3177 89.7908 19.1283 89.8229C12.1902 79.272 6.3194 68.3537 1.82239 56.9025C-2.69709 45.3904 -6.04177 33.278 -8.94348 20.7049Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.81494 21.9488C-9.73951 21.8397 -9.65445 21.7177 -9.58062 21.5957C-7.02076 34.1767 -3.93127 46.2185 0.448585 57.6135C4.79955 68.9363 10.6511 79.6942 17.639 90.0476C17.4641 90.0732 17.2715 90.0989 17.0853 90.1134C10.075 79.8418 4.24585 69.1546 -0.0617838 57.8767C-4.38386 46.5363 -7.37866 34.5106 -9.81494 21.9488Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.40442 18.8191C-7.32417 18.7228 -7.24393 18.6201 -7.16528 18.5254C-3.35839 31.0438 0.67159 43.2798 5.54095 55.0713C10.391 66.8177 16.294 78.163 23.1278 89.176C22.9416 89.2161 22.7635 89.2531 22.5821 89.2835C15.7371 78.3283 9.84216 67.0472 5.03539 55.3216C0.220604 43.5735 -3.73234 31.3456 -7.40442 18.8191Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.16187 19.7611C-8.08162 19.6648 -8.00137 19.5621 -7.92273 19.4674C-4.50583 32.0003 -0.777582 44.1994 3.93931 55.8672C8.64175 67.4949 14.5318 78.6572 21.4202 89.4825C21.2276 89.5065 21.0382 89.5403 20.8649 89.5659C13.9637 78.8129 8.08324 67.7052 3.43054 56.1112C-1.2398 44.4818 -4.86694 32.3085 -8.16187 19.7611Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.508C-12.8612 27.3637 -12.3637 26.2787 -11.8308 25.2853C-10.5372 37.762 -8.45084 49.4347 -4.60864 60.2728C-0.788905 71.0049 4.95996 81.01 12.081 90.5369C10.7056 90.5738 9.33981 90.6156 8.0013 90.6557C0.817632 81.851 -4.83814 72.52 -8.23417 62.3399C-11.6687 52.0395 -13.0137 40.768 -13.3523 28.508Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.5081C-13.2801 28.3476 -13.2127 28.1871 -13.1501 28.0266C-12.6782 40.3204 -11.2242 51.6576 -7.73183 62.0271C-4.27962 72.281 1.38417 81.7179 8.55821 90.6333L8.0013 90.6478C0.817633 81.8431 -4.83814 72.5121 -8.23417 62.3304C-11.6687 52.0315 -13.0137 40.7601 -13.3523 28.5001V28.5081Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.245 24.1955C-11.1776 24.0704 -11.0989 23.9227 -11.0235 23.7943C-9.25809 36.3256 -6.79452 48.1732 -2.75171 59.2296C1.26062 70.2105 7.07368 80.5206 14.1434 90.3764C13.9588 90.3989 13.771 90.4134 13.5945 90.431C6.50553 80.6634 0.721362 70.4304 -3.24121 59.5201C-7.23909 48.5102 -9.60315 36.714 -11.245 24.1955Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0299 23.7896C-10.9609 23.6628 -10.879 23.5488 -10.8068 23.4301C-8.90498 35.979 -6.33709 47.8491 -2.23971 58.96C1.83682 69.9987 7.64185 80.3682 14.7035 90.3187C14.519 90.3412 14.3232 90.3588 14.1434 90.3701C7.0737 80.5142 1.26225 70.2041 -2.75168 59.2232C-6.7945 48.1668 -9.25646 36.3192 -11.0235 23.788L-11.0299 23.7896Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0442 25.6849C-11.9688 25.5565 -11.903 25.4233 -11.8307 25.2853C-10.5372 37.762 -8.45075 49.4347 -4.60855 60.2728C-0.788815 71.0049 4.96004 81.0101 12.0811 90.5369C11.8885 90.5369 11.6975 90.5369 11.5066 90.5498C4.37745 81.1064 -1.35696 71.1911 -5.1125 60.5295C-8.90334 49.7765 -10.8902 38.1343 -12.0442 25.6849Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7216 27.0299C-12.6525 26.8598 -12.5851 26.709 -12.5081 26.5597C-11.6334 38.9578 -9.85194 50.4732 -6.17986 61.0978C-2.5463 71.6037 3.16083 81.3392 10.3124 90.5836C10.1326 90.5948 9.94166 90.6012 9.75067 90.6076C2.59429 81.4595 -3.08877 71.83 -6.67418 61.3979C-10.2933 50.8551 -11.9705 39.3879 -12.7216 27.0299Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.33521 19.9843 -7.74299 19.2444 -7.16201 18.5254C-3.35511 31.0438 0.674864 43.2798 5.54422 55.0713C10.3943 66.8161 16.2973 78.163 23.131 89.176C21.8054 89.4248 20.4588 89.627 19.13 89.818C12.1902 79.2672 6.3194 68.3488 1.82239 56.8977C-2.7003 45.3903 -6.03856 33.2779 -8.94348 20.7049Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.86321 20.6025 -8.77693 20.505 -8.68509 20.4128C-5.6614 32.973 -2.22364 45.1079 2.33597 56.649C6.87792 68.1435 12.7471 79.118 19.6772 89.741C19.5023 89.7683 19.3177 89.7908 19.1283 89.8229C12.1902 79.272 6.3194 68.3537 1.82239 56.9025C-2.69709 45.3904 -6.04177 33.278 -8.94348 20.7049Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.81494 21.9488C-9.73951 21.8397 -9.65445 21.7177 -9.58062 21.5957C-7.02076 34.1767 -3.93127 46.2185 0.448585 57.6135C4.79955 68.9363 10.6511 79.6942 17.639 90.0476C17.4641 90.0732 17.2715 90.0989 17.0853 90.1134C10.075 79.8418 4.24585 69.1546 -0.0617838 57.8767C-4.38386 46.5363 -7.37866 34.5106 -9.81494 21.9488Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.40442 18.8191C-7.32417 18.7228 -7.24393 18.6201 -7.16528 18.5254C-3.35839 31.0438 0.67159 43.2798 5.54095 55.0713C10.391 66.8177 16.294 78.163 23.1278 89.176C22.9416 89.2161 22.7635 89.2531 22.5821 89.2835C15.7371 78.3283 9.84216 67.0472 5.03539 55.3216C0.220604 43.5735 -3.73234 31.3456 -7.40442 18.8191Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.16187 19.7611C-8.08162 19.6648 -8.00137 19.5621 -7.92273 19.4674C-4.50583 32.0003 -0.777582 44.1994 3.93931 55.8672C8.64175 67.4949 14.5318 78.6572 21.4202 89.4825C21.2276 89.5065 21.0382 89.5403 20.8649 89.5659C13.9637 78.8129 8.08324 67.7052 3.43054 56.1112C-1.2398 44.4818 -4.86694 32.3085 -8.16187 19.7611Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.508C-12.8612 27.3637 -12.3637 26.2787 -11.8308 25.2853C-10.5372 37.762 -8.45084 49.4347 -4.60864 60.2728C-0.788905 71.0049 4.95996 81.01 12.081 90.5369C10.7056 90.5738 9.33981 90.6156 8.0013 90.6557C0.817632 81.851 -4.83814 72.52 -8.23417 62.3399C-11.6687 52.0395 -13.0137 40.768 -13.3523 28.508Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.5081C-13.2801 28.3476 -13.2127 28.1871 -13.1501 28.0266C-12.6782 40.3204 -11.2242 51.6576 -7.73183 62.0271C-4.27962 72.281 1.38417 81.7179 8.55821 90.6333L8.0013 90.6478C0.817633 81.8431 -4.83814 72.5121 -8.23417 62.3304C-11.6687 52.0315 -13.0137 40.7601 -13.3523 28.5001V28.5081Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.245 24.1955C-11.1776 24.0704 -11.0989 23.9227 -11.0235 23.7943C-9.25809 36.3256 -6.79452 48.1732 -2.75171 59.2296C1.26062 70.2105 7.07368 80.5206 14.1434 90.3764C13.9588 90.3989 13.771 90.4134 13.5945 90.431C6.50553 80.6634 0.721362 70.4304 -3.24121 59.5201C-7.23909 48.5102 -9.60315 36.714 -11.245 24.1955Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0299 23.7896C-10.9609 23.6628 -10.879 23.5488 -10.8068 23.4301C-8.90498 35.979 -6.33709 47.8491 -2.23971 58.96C1.83682 69.9987 7.64185 80.3682 14.7035 90.3187C14.519 90.3412 14.3232 90.3588 14.1434 90.3701C7.0737 80.5142 1.26225 70.2041 -2.75168 59.2232C-6.7945 48.1668 -9.25646 36.3192 -11.0235 23.788L-11.0299 23.7896Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0442 25.6849C-11.9688 25.5565 -11.903 25.4233 -11.8307 25.2853C-10.5372 37.762 -8.45075 49.4347 -4.60855 60.2728C-0.788815 71.0049 4.96004 81.0101 12.0811 90.5369C11.8885 90.5369 11.6975 90.5369 11.5066 90.5498C4.37745 81.1064 -1.35696 71.1911 -5.1125 60.5295C-8.90334 49.7765 -10.8902 38.1343 -12.0442 25.6849Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7216 27.0299C-12.6525 26.8598 -12.5851 26.709 -12.5081 26.5597C-11.6334 38.9578 -9.85194 50.4732 -6.17986 61.0978C-2.5463 71.6037 3.16083 81.3392 10.3124 90.5836C10.1326 90.5948 9.94166 90.6012 9.75067 90.6076C2.59429 81.4595 -3.08877 71.83 -6.67418 61.3979C-10.2933 50.8551 -11.9705 39.3879 -12.7216 27.0299Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89631C23.6141 4.76149 24.606 4.64112 25.5978 4.52075C39.1209 11.2053 49.7969 19.9024 57.1427 30.7212C64.3071 41.2737 68.2456 53.4214 70.7846 65.736C69.8361 66.5513 68.8779 67.3618 67.9214 68.1803C64.864 55.7389 60.5933 43.4291 53.3952 32.5509C46.0462 21.4431 35.6398 12.2453 22.6271 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.6271 4.89633C22.7654 4.88643 22.903 4.86929 23.0395 4.84497C36.1133 12.096 46.5646 21.2153 53.9136 32.2893C61.1053 43.1338 65.3359 55.4115 68.3226 67.8369C68.175 67.9508 68.0498 68.0728 67.9182 68.1803C64.8608 55.7405 60.5901 43.4291 53.392 32.5509C46.0462 21.4432 35.6398 12.2453 22.6271 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1442 5.09698C21.279 5.08735 21.417 5.06648 21.5486 5.04883C34.3608 12.6401 44.6612 22.0113 52.0086 33.2202C59.2195 44.2187 63.5946 56.5863 66.8381 69.0759C66.7065 69.1834 66.5637 69.2861 66.4241 69.3969C63.1179 56.8881 58.6899 44.5028 51.4806 33.4625C44.1348 22.2136 33.8809 12.7669 21.1442 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.8966 4.73427C24.0362 4.71341 24.1743 4.69254 24.3171 4.6781C37.6091 11.6531 48.192 20.5573 55.5282 31.5061C62.7054 42.2062 66.79 54.4165 69.5537 66.7905C69.4221 66.898 69.2937 67.0136 69.154 67.1227C66.3101 54.7295 62.1934 42.4919 55.0114 31.7517C47.6672 20.7643 37.1293 11.7879 23.8966 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8382 40.5338 -15.7821 39.3622 -15.6761 38.1778C-9.17458 27.3622 -2.10807 19.6119 6.47189 15.0556C15.1626 10.435 25.3748 8.10299 36.2112 5.86572C37.6001 6.41943 38.9597 7.04404 40.2846 7.73707C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3707 -8.44594 31.1113 -15.8607 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8607 41.6733C-15.8607 41.5128 -15.8607 41.362 -15.8607 41.1918C-8.5615 30.5993 -0.705363 22.8539 8.4973 18.0102C17.8155 13.1072 28.4995 10.2456 39.7357 7.453C39.925 7.55411 40.1 7.64399 40.2846 7.73708C28.9858 10.6003 18.2344 13.5421 8.81829 18.4901C-0.477463 23.3675 -8.44594 31.1081 -15.8607 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.9009 43.4002C-15.9009 43.2397 -15.8912 43.0792 -15.8896 42.9332C-8.15388 32.4771 0.116322 24.759 9.65763 19.7724C19.3193 14.7265 30.2441 11.5825 41.692 8.4978C41.8525 8.59249 42.013 8.70323 42.1735 8.80595C30.6854 11.9628 19.6917 15.1839 9.95936 20.2635C0.328175 25.2918 -8.05277 32.9971 -15.9009 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5591 36.8392C-15.5591 36.6691 -15.5334 36.499 -15.5157 36.3401C-9.51813 25.4266 -2.96038 17.6988 5.22155 13.3382C13.503 8.92306 23.3958 6.93455 33.9642 5.05518C34.1584 5.1274 34.3655 5.20283 34.5677 5.27184C23.9334 7.25714 13.9572 9.33231 5.55217 13.8085C-2.72445 18.2156 -9.42183 25.9482 -15.5591 36.8392Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.5142 36.3401C-15.4869 36.1587 -15.4676 35.9822 -15.4291 35.8056C-9.58555 24.8825 -3.16583 17.1788 4.88129 12.8888C13.044 8.53306 22.834 6.63603 33.311 4.85938C33.5333 4.90893 33.7517 4.97491 33.9642 5.05678C23.3958 6.93455 13.503 8.92306 5.22154 13.3382C-2.96361 17.6988 -9.51814 25.4185 -15.5142 36.3401Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7163 38.6673C-15.7003 38.5068 -15.6906 38.3463 -15.6762 38.1858C-9.17461 27.3702 -2.10811 19.62 6.47185 15.0636C15.1625 10.435 25.3747 8.10299 36.2112 5.86572L36.8275 6.0856C25.914 8.42398 15.6199 10.8522 6.8153 15.5162C-1.88181 20.1271 -9.0735 27.887 -15.7163 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8447 40.1952C-15.8335 40.0347 -15.8239 39.8838 -15.8094 39.7137C-8.87612 28.996 -1.40036 21.2281 7.50701 16.5192C16.5299 11.7478 27.0053 9.13656 38.0569 6.61682L38.5929 6.88805C27.4723 9.48965 16.9263 12.1843 7.81355 16.9991C-1.20134 21.7529 -8.78303 29.5111 -15.8447 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34079C15.8783 6.13215 16.7626 5.92351 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8567 35.6998C54.0339 47.0787 58.8118 59.6132 62.6781 72.228C61.6284 72.9518 60.5628 73.6804 59.5131 74.4043C55.2296 61.7333 50.2158 49.1218 43.0963 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9972 6.34077C15.116 6.3183 15.2379 6.27817 15.3551 6.24768C26.8721 15.1695 36.3925 25.5181 43.6019 37.2501C50.7294 48.8361 55.7159 61.4332 59.9433 74.0993L59.5051 74.4042C55.2216 61.7333 50.2078 49.1218 43.0883 37.5037C35.895 25.7508 26.4339 15.3396 14.9972 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6395 6.67295C13.7647 6.63925 13.8835 6.61838 14.0006 6.58789C25.2047 15.7793 34.5325 26.3365 41.6953 38.1585C48.7843 49.8584 53.8799 62.4924 58.3063 75.1746C58.1458 75.2725 58.0046 75.3607 57.8489 75.4506C53.3664 62.7717 48.2434 50.1345 41.1673 38.4008C34.0205 26.5484 24.7601 15.9366 13.6395 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7158 5.54314C18.8554 5.52067 18.9806 5.48857 19.1074 5.46289C31.4413 13.587 41.4513 23.3547 48.7762 34.7882C55.9711 46.0227 60.5965 58.5042 64.2429 71.0788C64.1032 71.188 63.97 71.2875 63.8304 71.3998C60.1246 58.814 55.4575 46.318 48.2626 35.0369C40.9505 23.5729 30.9679 13.7363 18.7158 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1074 5.46293C19.239 5.44527 19.361 5.43083 19.4894 5.40515C31.9003 13.4298 41.9697 23.1412 49.2897 34.5282C56.4911 45.7322 61.0892 58.1897 64.6537 70.7451C64.5141 70.8542 64.3825 70.9617 64.2429 71.066C60.5965 58.4978 55.9711 46.0227 48.7762 34.7882C41.4513 23.3547 31.4413 13.5871 19.1074 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3035 5.80796C17.4319 5.78228 17.557 5.74858 17.679 5.73413C29.7256 14.1456 39.5703 24.1507 46.8566 35.6998C54.0339 47.0787 58.8118 59.6132 62.678 72.228C62.5368 72.3307 62.3827 72.4286 62.2479 72.5281C58.3223 59.8989 53.5139 47.3564 46.3366 35.9357C39.0599 24.3561 29.2538 14.3093 17.3035 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1399 6.06956C16.2555 6.04067 16.3807 6.00697 16.501 5.9845C28.2828 14.6511 37.9734 24.8248 45.2293 36.4653C52.3873 47.9582 57.2663 60.5296 61.3171 73.1701C61.1711 73.2648 61.0298 73.3675 60.8757 73.4654C56.7848 60.8153 51.8593 48.2407 44.7045 36.7125C37.4662 25.0527 27.8367 14.81 16.1367 6.06635L16.1399 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.5687 49.5358 -15.673 48.4894 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C47.4778 12.494 48.5563 13.3286 49.5674 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94567 33.6647 -6.0081 41.0473 -15.4756 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.4756 50.5758C-15.4884 50.4298 -15.5077 50.2949 -15.5205 50.1489C-6.1429 40.553 3.71939 33.1366 14.4917 27.7056C25.3458 22.2183 37.0843 18.0134 49.155 13.8197C49.2817 13.9272 49.4182 14.0588 49.5594 14.1792C37.4614 18.4387 25.6925 22.7046 14.7661 28.2079C3.94569 33.6647 -6.00809 41.0473 -15.4756 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.3007 52.1519C-15.3007 52.0219 -15.3296 51.8791 -15.3521 51.7363C-5.64864 42.3731 4.52661 35.0578 15.5172 29.5545C26.6024 24.0031 38.4805 19.5815 50.6459 15.1615C50.7791 15.2851 50.9203 15.4054 51.0535 15.529C38.8673 20.01 26.9571 24.4829 15.8109 30.0488C4.75612 35.5698 -5.50902 42.8498 -15.3007 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8078 46.2217C-15.8191 46.0836 -15.8271 45.9264 -15.8255 45.7739C-7.41725 35.6323 1.50777 27.9929 11.5691 22.8041C21.7203 17.5656 33.0109 13.977 44.7333 10.4349C44.8938 10.5377 45.0543 10.6484 45.2148 10.7559C33.4411 14.375 22.1038 18.0182 11.8612 23.292C1.73246 28.5097 -7.29688 36.1315 -15.8142 46.2265L-15.8078 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.8254 45.7739C-15.8254 45.6262 -15.8366 45.477 -15.8414 45.3277C-7.53913 35.13 1.28156 27.4841 11.2546 22.3275C21.332 17.1147 32.5584 13.5934 44.2471 10.13L44.7286 10.4398C33.0126 13.9834 21.7155 17.5705 11.5644 22.809C1.51107 27.9929 -7.41715 35.6323 -15.8254 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.7308 47.8747C-15.7452 47.7287 -15.75 47.5794 -15.7693 47.4446C-6.98551 37.4764 2.30223 29.9316 12.638 24.6401C23.07 19.2957 34.5372 15.468 46.4105 11.6659C46.5598 11.7831 46.709 11.9002 46.8551 12.0093C34.9449 15.8692 23.4343 19.766 12.9349 25.1313C2.53334 30.4468 -6.84748 37.9707 -15.7308 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-15.6104 49.2325C-15.6104 49.0849 -15.6297 48.9388 -15.6393 48.8008C-6.55698 39.0236 3.01803 31.5333 13.5704 26.1713C24.2159 20.7466 35.8228 16.7311 47.7908 12.73L48.2385 13.0815C36.2305 17.1435 24.5771 21.2233 13.8625 26.6592C3.23951 32.0469 -6.43019 39.5131 -15.6104 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47698C8.87119 8.16883 9.67366 7.8655 10.5082 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7482 77.9095C52.6039 78.537 51.4596 79.1645 50.3201 79.7824C45.0624 67.1452 39.551 54.4695 32.7686 42.3875C25.8972 30.1522 17.6067 18.77 8.06873 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.06873 8.47699C8.17947 8.43045 8.29021 8.38551 8.38971 8.35181C18.0153 18.5651 26.3683 29.9069 33.2661 42.1292C40.0823 54.2079 45.5808 66.8868 50.7952 79.5256C50.6347 79.6155 50.4742 79.7022 50.3137 79.7824C45.0559 67.1452 39.5446 54.4695 32.7622 42.3876C25.8928 30.1525 17.6044 18.7704 8.06873 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85217 8.93915C6.96291 8.89421 7.06884 8.86051 7.17316 8.81396C16.4602 19.2952 24.5677 30.7648 31.3498 43.0166C38.0665 55.1531 43.6307 67.8096 49.0041 80.4211C48.8516 80.5014 48.6831 80.5816 48.5226 80.6619C43.114 68.06 37.5192 55.4179 30.833 43.267C24.0862 31.0021 16.0468 19.494 6.85217 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4391 7.32142C11.5562 7.29092 11.6686 7.25401 11.7906 7.21228C22.4377 16.8563 31.3932 27.7265 38.4565 39.7025C45.4508 51.5629 50.7214 64.2354 55.4687 76.9224C55.3243 77.0171 55.167 77.1086 55.0129 77.2065C50.2142 64.5163 44.9227 51.8486 37.9365 39.9529C30.8892 27.9656 21.9947 17.0377 11.4391 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7954 7.21223C11.9147 7.18221 12.0316 7.14359 12.1453 7.09668C22.871 16.6797 31.8956 27.5002 38.9701 39.4424C45.9804 51.2836 51.2253 63.9513 55.9117 76.6351C55.7737 76.7266 55.6212 76.8245 55.464 76.9159C50.7214 64.237 45.4508 51.5581 38.4517 39.6976C31.3932 27.7265 22.4377 16.8563 11.7954 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1552 7.72106C10.2771 7.68093 10.3943 7.65044 10.5066 7.61353C20.8151 17.5111 29.5331 28.5627 36.5225 40.6093C43.4494 52.5516 48.8179 65.2369 53.7483 77.9095C53.5878 77.9913 53.4273 78.0828 53.2668 78.1663C48.2915 65.4873 42.9054 52.8164 35.9945 40.8404C29.0372 28.7761 20.3721 17.6909 10.1552 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.10876 8.0789C9.21148 8.03717 9.32543 8.00026 9.42975 7.95532C19.3344 17.9333 27.8942 29.1615 34.892 41.3555C41.7723 53.37 47.1889 66.0538 52.2605 78.7119C52.108 78.8114 51.9556 78.89 51.7967 78.9735C46.6786 66.3154 41.2234 53.6381 34.3752 41.6027C27.4116 29.3999 18.9211 18.1346 9.10876 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.137 58.862C-14.3345 57.883 -14.527 56.8928 -14.7277 55.9058C-4.60604 47.4874 6.48698 40.311 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C55.3965 19.8366 56.2857 20.7738 57.0994 21.7496C44.6612 27.1053 32.3032 32.4048 20.3112 38.1761C8.38975 43.9137 -3.08069 50.64 -14.137 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.1371 58.8621C-14.1676 58.7225 -14.1965 58.5829 -14.2222 58.4513C-3.23161 50.1586 8.17303 43.397 20.0495 37.677C31.9822 31.9105 44.3128 26.6576 56.7559 21.3405C56.8682 21.4801 56.987 21.6101 57.0993 21.7513C44.6611 27.1054 32.3031 32.4048 20.3111 38.1762C8.38969 43.9122 -3.08074 50.6401 -14.1371 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.7984 60.3386C-13.8353 60.2022 -13.8706 60.0738 -13.9043 59.9438C-2.65213 51.9191 9.00287 45.3068 21.0382 39.5451C33.1394 33.7513 45.5391 28.3283 58.003 22.8346C58.1153 22.9758 58.2293 23.1155 58.3352 23.2422C45.8713 28.7809 33.4588 34.2505 21.3159 40.0459C9.23077 45.8156 -2.4804 52.3814 -13.7984 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.909 54.7792C-14.9315 54.6364 -14.9523 54.4935 -14.97 54.3587C-4.74015 45.3807 5.94869 38.2805 17.3068 32.6713C28.7531 27.0171 40.827 22.2569 53.132 17.4774C53.2572 17.6042 53.3856 17.739 53.4995 17.861C41.1833 22.6983 29.0757 27.5098 17.5764 33.1833C6.16054 38.8005 -4.61015 45.875 -14.909 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.9701 54.3586C-14.9964 54.2222 -15.0157 54.0845 -15.0278 53.9462C-4.88307 44.8992 5.72231 37.7588 17.0323 32.1592C28.4273 26.5211 40.4642 21.8138 52.7628 17.0938C52.888 17.2205 53.01 17.3393 53.1319 17.4773C40.8269 22.2568 28.7531 27.017 17.3067 32.6712C5.9486 38.2804 -4.74023 45.3806 -14.9701 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.6458 56.3022C-14.6731 56.1706 -14.6988 56.039 -14.7277 55.8993C-4.6057 47.4832 6.48731 40.309 18.3146 34.5297C29.9568 28.829 42.1462 23.8746 54.5058 18.8993C54.6246 19.0293 54.7497 19.1561 54.8749 19.2829C42.4913 24.3175 30.2858 29.3121 18.6035 35.0256C6.73564 40.8094 -4.42191 47.9493 -14.6458 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.3923 57.5782C-14.4179 57.4466 -14.4549 57.3102 -14.4741 57.1753C-3.72109 48.6692 7.47811 41.7969 19.2053 36.0994C30.9951 30.3682 43.252 25.2565 55.6645 20.1111C55.7817 20.2411 55.9101 20.3759 56.0337 20.4947C43.6147 25.6946 31.3402 30.8545 19.491 36.5969C7.71564 42.3057 -3.54776 49.1378 -14.3923 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.406C2.51885 10.9871 3.24107 10.5826 4.01304 10.2247C12.3146 21.3572 19.723 33.1286 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C42.8844 83.2121 41.6695 83.7353 40.4241 84.2088C34.4233 71.8942 28.6118 59.4463 22.3991 47.1831C16.1768 34.8925 9.36227 22.886 1.79663 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79663 11.4059L2.10157 11.2278C9.7635 22.6693 16.6583 34.6405 22.9191 46.9439C29.1607 59.2104 34.9641 71.6823 40.9328 84.0178C40.7724 84.09 40.6022 84.1542 40.4241 84.2168C34.4233 71.8941 28.6118 59.4463 22.3991 47.183C16.1768 34.8925 9.36227 22.886 1.79663 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.700562 12.0415C0.801672 11.9725 0.901179 11.9244 0.997475 11.8666C8.25657 23.4718 14.8689 35.5361 20.9917 47.817C27.1145 60.0979 32.9452 72.4816 39.0295 84.7176C38.869 84.785 38.6973 84.8524 38.5207 84.9166C32.4011 72.6934 26.5576 60.3242 20.4717 48.0594C14.3858 35.7945 7.85694 23.6885 0.700562 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.85889 9.81864C4.96642 9.76567 5.06913 9.72395 5.16864 9.67419C13.8542 20.5773 21.5301 32.2477 28.1015 44.5413C34.6528 56.7548 40.3278 69.3647 45.9531 81.9057C45.7798 81.9763 45.6241 82.0485 45.4508 82.1191C39.7934 69.6151 34.1103 57.0052 27.5815 44.7804C21.0388 32.4877 13.4358 20.7893 4.85889 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.16223 9.67418C5.26976 9.62122 5.3789 9.57628 5.48322 9.52332C14.2601 20.3671 22.0034 32.008 28.6119 44.2941C35.1921 56.4916 40.8478 69.1144 46.4266 81.6553C46.2661 81.7468 46.1152 81.8158 45.9451 81.9057C40.3279 69.3631 34.6528 56.7548 28.1015 44.5413C21.528 32.2474 13.85 20.577 5.16223 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69531 10.3724C3.79849 10.3153 3.90578 10.2659 4.0163 10.2247C12.3167 21.3573 19.7241 33.1287 26.1691 45.4289C32.6113 57.6665 38.3425 70.2299 44.101 82.697C43.9277 82.7692 43.7576 82.8478 43.6003 82.912C37.8017 70.4594 32.0625 57.9104 25.6475 45.66C19.2334 33.359 11.8949 21.5626 3.69531 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.72754 10.865L3.03248 10.6869C11.0148 22.0068 18.2013 33.8674 24.5401 46.1831C30.878 58.4416 36.6574 70.9616 42.5234 83.3774C42.3629 83.4448 42.188 83.5234 42.021 83.5908C36.1181 71.1911 30.3275 58.7016 24.0153 46.4239C17.6887 34.1269 10.627 22.1894 2.72754 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.3026 65.7569 -12.5947 64.8164 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6928 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.9303 27.9495 62.6669 28.9671 63.3233 30.0071C50.8627 36.2759 38.2961 42.4132 25.6075 48.2744C12.9398 54.1227 0.297746 59.9711 -12.0121 66.6765L-12.0169 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0169 66.6813C-12.0555 66.5641 -12.094 66.4277 -12.1389 66.2945C0.121144 59.512 12.7118 53.6043 25.3522 47.7575C38.0184 41.8979 50.5866 35.8056 63.0537 29.5785C63.1403 29.7213 63.2366 29.869 63.3233 30.0118C50.8626 36.2807 38.296 42.4179 25.6074 48.2791C12.9397 54.1275 0.297688 59.9759 -12.0121 66.6813H-12.0169Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.5354 68.1065C-11.59 67.9765 -11.6398 67.8545 -11.6847 67.7213C0.769565 61.2342 13.5432 55.4997 26.2847 49.6562C39.0552 43.7998 51.6234 37.555 64.0696 31.1851C64.1563 31.3295 64.251 31.4691 64.336 31.612C51.9123 38.0317 39.3392 44.3118 26.548 50.1634C13.7567 56.0149 0.963762 61.6884 -11.5354 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.1757 62.8086C-13.2078 62.6883 -13.2366 62.5486 -13.2736 62.4122C-1.60411 54.869 10.4377 48.5071 22.733 42.7005C35.0733 36.8746 47.5613 31.1803 60.0685 25.3865C60.1729 25.5309 60.2675 25.6705 60.3687 25.807C47.8711 31.6425 35.3622 37.3769 22.9946 43.2108C10.6576 49.0223 -1.44843 55.3313 -13.1757 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.2769 62.4122C-13.3073 62.2918 -13.3443 62.1554 -13.3748 62.0157C-1.77272 54.3891 10.2177 47.9871 22.4633 42.1852C34.7844 36.3513 47.2611 30.7164 59.7475 24.9772C59.847 25.1072 59.9513 25.25 60.054 25.3864C47.5628 31.1802 35.0733 36.8745 22.733 42.7004C10.4376 48.5071 -1.6042 54.8642 -13.2769 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7664 64.2627C-12.8081 64.1375 -12.845 64.0011 -12.8835 63.8839C-0.991014 56.6152 11.2594 50.4202 23.6912 44.5975C36.1744 38.7507 48.6976 32.8991 61.1872 26.9432C61.2851 27.0716 61.391 27.2161 61.4953 27.3589C49.0058 33.3507 36.4964 39.2589 23.9673 45.0838C11.4969 50.9048 -0.812866 57.0646 -12.7664 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.3892 65.4792C-12.4342 65.3476 -12.4791 65.2144 -12.5128 65.086C-0.437325 58.0596 11.9913 52.001 24.537 46.1671C37.1132 40.3187 49.6483 34.3462 62.1421 28.2496C62.248 28.3941 62.3491 28.5305 62.4519 28.6669C49.9751 34.8095 37.4278 40.8136 24.8098 46.679C12.2304 52.5178 -0.254362 58.525 -12.3892 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.354C-3.20266 14.8035 -2.55267 14.253 -1.86576 13.7667C4.28754 25.7796 10.0749 38.0012 15.827 50.2259C21.5646 62.4234 27.4675 74.5583 33.8488 86.4973C32.5648 86.8975 31.2809 87.2688 29.9969 87.6112C23.4231 75.961 17.5106 64.0765 12.0811 51.9817C6.65161 39.887 1.46929 27.5868 -3.84624 15.354H-3.85266Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.85266 15.3541C-3.76599 15.2738 -3.67612 15.1936 -3.58143 15.1182C1.84645 27.3381 7.1058 39.6206 12.5947 51.7427C18.0835 63.8647 23.9897 75.7733 30.5233 87.4716L29.9905 87.6096C23.4231 75.9611 17.5106 64.0766 12.0811 51.9818C6.65161 39.887 1.46929 27.5869 -3.84624 15.3541H-3.85266Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.8606 16.2031C-4.75788 16.1165 -4.67122 16.0426 -4.58134 15.964C0.408388 28.2834 5.34034 40.566 10.6815 52.6318C16.0137 64.721 21.9751 76.5226 28.5412 87.9884C28.3582 88.0366 28.1753 88.0847 28.0084 88.1249C21.4044 76.7169 15.444 64.9482 10.1551 52.8742C4.8717 40.8372 0.0248103 28.537 -4.85899 16.2031H-4.8606Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.09863 13.2307L-0.819377 13.0365C5.76084 24.9034 11.8596 37.088 17.7513 49.3416C23.643 61.5953 29.5186 73.8264 35.8019 85.8843C35.6206 85.9404 35.4408 85.9966 35.2771 86.0448C28.9697 74.0158 23.0796 61.8103 17.2313 49.5792C11.3829 37.348 5.35479 25.1473 -1.09863 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.819336 13.0366C-0.72625 12.9708 -0.631558 12.9066 -0.522423 12.8359C6.14285 24.6771 12.3427 36.8329 18.2793 49.0993C24.216 61.3658 30.0691 73.6275 36.33 85.7126C36.1534 85.7768 35.9737 85.833 35.802 85.8843C29.5187 73.8265 23.6366 61.5745 17.7513 49.3417C11.866 37.1089 5.75446 24.9034 -0.819336 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-2.15308 13.9642L-1.86579 13.7635C4.28751 25.7765 10.0749 37.998 15.827 50.2227C21.5646 62.4202 27.4675 74.5551 33.8487 86.4942L33.3159 86.6547C26.9026 74.7509 21.0045 62.6465 15.3037 50.4571C9.60304 38.2676 3.88307 26.0076 -2.15308 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.00525 14.6254C-2.913 14.559 -2.82607 14.4855 -2.74525 14.4055C3.05657 26.5324 8.5984 38.8005 14.2157 50.9722C19.8329 63.144 25.7407 75.1842 32.2053 87.0077L31.6725 87.1682C25.1789 75.3929 19.2728 63.3735 13.7021 51.2274C8.11852 39.0556 2.66818 26.7795 -3.00525 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08153 74.1571C-9.47635 73.2584 -9.87277 72.3788 -10.2676 71.4801C2.66174 65.7954 15.8527 60.5778 28.8333 54.7423C41.814 48.9067 54.3517 42.3217 66.6375 35.5521C67.2265 36.629 67.8058 37.7108 68.3435 38.8005C56.2166 45.8253 43.7544 52.6623 30.6806 58.4513C17.5828 64.2499 4.13827 69.0936 -9.08153 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.08157 74.1572C-9.13454 74.0288 -9.19552 73.902 -9.24207 73.7864C3.94244 68.6394 17.3548 63.7364 30.4414 57.9329C43.504 52.1407 55.9727 45.3342 68.13 38.3528L68.3435 38.8086C56.2166 45.8253 43.7543 52.6623 30.6806 58.4497C17.5827 64.2499 4.13824 69.0936 -9.08157 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.46204 75.5053C-8.51982 75.3865 -8.58562 75.271 -8.64661 75.1442C4.67431 70.3214 18.2039 65.6237 31.3274 59.8412C44.4188 54.073 56.8458 47.1622 68.9004 40.0411C68.9598 40.1856 69.0336 40.3429 69.1074 40.5001C57.0801 47.6501 44.6788 54.5946 31.5826 60.3515C18.4382 66.1293 4.89579 70.7836 -8.46204 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.6479 70.461C-10.7009 70.331 -10.7394 70.2122 -10.7924 70.0822C1.97004 64.1006 15.0085 58.6744 27.917 52.8372C40.8254 47.0001 53.3888 40.521 65.7435 33.899C65.8222 34.0451 65.904 34.1879 65.9891 34.342C53.6488 41.0137 41.0934 47.5153 28.1641 53.3508C15.2348 59.1863 2.16424 64.5564 -10.6479 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.7924 70.0822C-10.8454 69.9538 -10.8887 69.8286 -10.9385 69.7051C1.77899 63.6352 14.7821 58.1656 27.6697 52.3237C40.5573 46.4817 53.1271 40.0395 65.5027 33.4513L65.7435 33.9039C53.3855 40.521 40.8269 47.0001 27.9169 52.8421C15.0068 58.684 1.96997 64.1006 -10.7924 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.1103 71.8444C-10.1568 71.7289 -10.2178 71.6021 -10.2708 71.4801C2.66177 65.7954 15.8527 60.5794 28.8334 54.7423C41.814 48.9051 54.3518 42.3217 66.6375 35.5521C66.7161 35.6982 66.798 35.8522 66.8863 36.0031C54.6118 42.8 42.0805 49.4284 29.0934 55.251C16.1063 61.0737 2.86399 66.2448 -10.1103 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.58548 72.9951C-9.64326 72.8764 -9.69622 72.7464 -9.74597 72.6356C3.31816 67.2158 16.6086 62.1506 29.6518 56.3295C42.6694 50.5197 55.1702 43.8111 67.4046 36.9452C67.48 37.0832 67.5651 37.2373 67.6422 37.3914C55.4447 44.2926 42.9439 51.0333 29.907 56.8399C16.8702 62.6465 3.52199 67.6668 -9.57424 72.9951H-9.58548Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.33521 19.9843 -7.74299 19.2444 -7.16201 18.5254C-3.35511 31.0438 0.674864 43.2798 5.54422 55.0713C10.3943 66.8161 16.2973 78.163 23.131 89.176C21.8054 89.4248 20.4588 89.627 19.13 89.818C12.1902 79.2672 6.3194 68.3488 1.82239 56.8977C-2.7003 45.3903 -6.03856 33.2779 -8.94348 20.7049Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.94348 20.7049C-8.86321 20.6025 -8.77693 20.505 -8.68509 20.4128C-5.6614 32.973 -2.22364 45.1079 2.33597 56.649C6.87792 68.1435 12.7471 79.118 19.6772 89.741C19.5023 89.7683 19.3177 89.7908 19.1283 89.8229C12.1902 79.272 6.3194 68.3537 1.82239 56.9025C-2.69709 45.3904 -6.04177 33.278 -8.94348 20.7049Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.81494 21.9488C-9.73951 21.8397 -9.65445 21.7177 -9.58062 21.5957C-7.02076 34.1767 -3.93127 46.2185 0.448585 57.6135C4.79955 68.9363 10.6511 79.6942 17.639 90.0476C17.4641 90.0732 17.2715 90.0989 17.0853 90.1134C10.075 79.8418 4.24585 69.1546 -0.0617838 57.8767C-4.38386 46.5363 -7.37866 34.5106 -9.81494 21.9488Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.46704 17.8209C-6.37556 17.7294 -6.2905 17.6427 -6.20704 17.5544C-1.9604 29.9991 2.40983 42.2704 7.4477 54.1661C12.5195 66.1026 18.4331 77.6637 25.1437 88.762L24.5996 88.8743C17.8757 77.8358 11.9749 66.3165 6.94536 54.4101C1.95403 42.5464 -2.33274 30.288 -6.46062 17.8177L-6.46704 17.8209Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.20715 17.5545L-5.95518 17.2913C-1.58496 29.7198 2.87194 42.0072 7.96759 53.927C13.0895 65.8871 19.01 77.4891 25.6892 88.6545C25.5191 88.6882 25.3329 88.7299 25.1435 88.762C18.433 77.6637 12.5194 66.1026 7.44759 54.1661C2.40971 42.2688 -1.96051 29.9991 -6.20715 17.5545Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.40442 18.8191C-7.32417 18.7228 -7.24393 18.6201 -7.16528 18.5254C-3.35839 31.0438 0.67159 43.2798 5.54095 55.0713C10.391 66.8177 16.294 78.163 23.1278 89.176C22.9416 89.2161 22.7635 89.2531 22.5821 89.2835C15.7371 78.3283 9.84216 67.0472 5.03539 55.3216C0.220604 43.5735 -3.73234 31.3456 -7.40442 18.8191Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.16187 19.7611C-8.08162 19.6648 -8.00137 19.5621 -7.92273 19.4674C-4.50583 32.0003 -0.777582 44.1994 3.93931 55.8672C8.64175 67.4949 14.5318 78.6572 21.4202 89.4825C21.2276 89.5065 21.0382 89.5403 20.8649 89.5659C13.9637 78.8129 8.08324 67.7052 3.43054 56.1112C-1.2398 44.4818 -4.86694 32.3085 -8.16187 19.7611Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.16386 81.2412C-5.69027 80.4051 -6.22953 79.5561 -6.74792 78.7167C6.86028 74.7862 20.6193 70.6503 33.799 64.9512C46.9081 59.2842 59.1056 52.0797 70.8216 44.6456C71.2324 45.7867 71.6433 46.9278 72.0333 48.0593C60.627 55.7148 48.6478 63.0911 35.6014 68.6682C22.4522 74.2855 8.58076 77.9768 -5.16386 81.2412Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.26308 77.7521C-7.32407 77.6253 -7.38826 77.5098 -7.45728 77.3862C6.05945 73.1187 19.7527 68.7741 32.9244 63.0349C46.0415 57.323 58.3336 50.2276 70.1941 42.8962L70.3545 43.3777C58.5375 50.7267 46.2662 57.8574 33.1555 63.5581C19.9806 69.2941 6.27772 73.5632 -7.26147 77.7521H-7.26308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.4574 77.3862C-7.51518 77.2674 -7.58098 77.1519 -7.64197 77.0251C5.83944 72.6725 19.5006 68.2718 32.6691 62.5277C45.7845 56.8078 58.104 49.7365 69.9869 42.4388C70.0607 42.5993 70.1313 42.7454 70.1939 42.8995C58.3335 50.2308 46.0413 57.3262 32.9242 63.0349C19.7526 68.7709 6.05932 73.1155 -7.4574 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.53614 79.065C-6.61318 78.9462 -6.6838 78.8403 -6.75281 78.7183C6.86021 74.7862 20.6193 70.6503 33.799 64.9608C46.908 59.2842 59.1055 52.0797 70.8215 44.6456L70.982 45.1127C59.3061 52.5868 47.1423 59.8106 34.0461 65.4664C20.8713 71.1543 7.09614 75.2324 -6.53614 79.065Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.508C-12.8612 27.3637 -12.3637 26.2787 -11.8308 25.2853C-10.5372 37.762 -8.45084 49.4347 -4.60864 60.2728C-0.788905 71.0049 4.95996 81.01 12.081 90.5369C10.7056 90.5738 9.33981 90.6156 8.0013 90.6557C0.817632 81.851 -4.83814 72.52 -8.23417 62.3399C-11.6687 52.0395 -13.0137 40.768 -13.3523 28.508Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.3523 28.5081C-13.2801 28.3476 -13.2127 28.1871 -13.1501 28.0266C-12.6782 40.3204 -11.2242 51.6576 -7.73183 62.0271C-4.27962 72.281 1.38417 81.7179 8.55821 90.6333L8.0013 90.6478C0.817633 81.8431 -4.83814 72.5121 -8.23417 62.3304C-11.6687 52.0315 -13.0137 40.7601 -13.3523 28.5001V28.5081Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.245 24.1955C-11.1776 24.0704 -11.0989 23.9227 -11.0235 23.7943C-9.25809 36.3256 -6.79452 48.1732 -2.75171 59.2296C1.26062 70.2105 7.07368 80.5206 14.1434 90.3764C13.9588 90.3989 13.771 90.4134 13.5945 90.431C6.50553 80.6634 0.721362 70.4304 -3.24121 59.5201C-7.23909 48.5102 -9.60315 36.714 -11.245 24.1955Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0299 23.7896C-10.9609 23.6628 -10.879 23.5488 -10.8068 23.4301C-8.90498 35.979 -6.33709 47.8491 -2.23971 58.96C1.83682 69.9987 7.64185 80.3682 14.7035 90.3187C14.519 90.3412 14.3232 90.3588 14.1434 90.3701C7.0737 80.5142 1.26225 70.2041 -2.75168 59.2232C-6.7945 48.1668 -9.25646 36.3192 -11.0235 23.788L-11.0299 23.7896Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.0442 25.6849C-11.9688 25.5565 -11.903 25.4233 -11.8307 25.2853C-10.5372 37.762 -8.45075 49.4347 -4.60855 60.2728C-0.788815 71.0049 4.96004 81.0101 12.0811 90.5369C11.8885 90.5369 11.6975 90.5369 11.5066 90.5498C4.37745 81.1064 -1.35696 71.1911 -5.1125 60.5295C-8.90334 49.7765 -10.8902 38.1343 -12.0442 25.6849Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.7216 27.0299C-12.6525 26.8598 -12.5851 26.709 -12.5081 26.5597C-11.6334 38.9578 -9.85194 50.4732 -6.17986 61.0978C-2.5463 71.6037 3.16083 81.3392 10.3124 90.5836C10.1326 90.5948 9.94166 90.6012 9.75067 90.6076C2.59429 81.4595 -3.08877 71.83 -6.67418 61.3979C-10.2933 50.8551 -11.9705 39.3879 -12.7216 27.0299Z"
          fill="#985B00"
        />
      </g>
      <path
        d="M24.6784 38.6628L7.71106 28.8037L24.7891 18.943L41.7564 28.8037L24.6784 38.6628Z"
        fill="#DB5B54"
      />
      <path
        d="M41.668 15.7136L41.7065 28.797L24.6269 38.6577L24.59 25.5743L41.668 15.7136Z"
        fill="#BD967F"
      />
      <path
        d="M24.6268 38.6577L7.65947 28.797L7.62256 15.7152L24.5899 25.5743L24.6268 38.6577Z"
        fill="#886B59"
      />
      <path
        d="M24.59 25.5745L7.62268 15.7154L24.7023 5.85474L41.6681 15.7138L24.59 25.5745Z"
        fill="#B88D73"
      />
      <path
        d="M33.1492 24.0129L33.1572 26.629L30.8798 27.945L30.8862 30.5611L33.1652 29.2466L28.6104 31.8755L28.5944 26.6418L33.1492 24.0129Z"
        fill="white"
      />
      <path
        d="M33.1572 26.629L33.1652 29.2467L30.8862 30.5611L30.8798 27.9451L33.1572 26.629Z"
        fill="#493239"
      />
      <path
        d="M39.8481 20.1451L39.8561 22.7628L37.5787 24.0772L37.5852 26.6933L39.8626 25.3788L35.3094 28.0077L35.2949 22.774L39.8481 20.1451Z"
        fill="white"
      />
      <path
        d="M39.8561 22.7627L39.8626 25.3787L37.5852 26.6932L37.5787 24.0771L39.8561 22.7627Z"
        fill="#493239"
      />
      <path
        d="M14.4002 41.8772L14.3906 45.8029L8.69629 42.516L8.70752 38.5903L14.4002 41.8772Z"
        fill="#886B59"
      />
      <path
        d="M14.4003 41.8772L20.056 38.5903L20.0464 42.516L14.3906 45.8029L14.4003 41.8772Z"
        fill="#BD967F"
      />
      <path
        d="M8.70752 38.5904L14.3633 35.3035L20.056 38.5904L14.4002 41.8773L8.70752 38.5904Z"
        fill="#CAA390"
      />
      <path
        d="M16.116 40.2467L16.1096 42.8643L14.4003 41.8773L8.70765 38.5904L7 37.6049L7.00802 34.9873L16.116 40.2467Z"
        fill="#A24741"
      />
      <mask
        id="mask2"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="7"
        y="34"
        width="10"
        height="9"
      >
        <path
          d="M16.116 40.2467L16.1096 42.8643L14.4003 41.8773L8.70765 38.5904L7 37.6049L7.00802 34.9873L16.116 40.2467Z"
          fill="#A24741"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89643C13.614 4.76161 14.6058 4.64124 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C59.836 66.5514 58.8778 67.3619 57.9213 68.1804C54.8639 55.739 50.5932 43.4292 43.3951 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89643Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89645C12.7652 4.88655 12.9029 4.86941 13.0394 4.84509C26.1132 12.0962 36.5645 21.2154 43.9135 32.2894C51.1051 43.1339 55.3357 55.4116 58.3225 67.837C58.1749 67.9509 58.0497 68.0729 57.9181 68.1804C54.8607 55.7406 50.59 43.4292 43.3919 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89645Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.0971C11.2789 5.08747 11.4169 5.0666 11.5485 5.04895C24.3606 12.6403 34.6611 22.0115 42.0085 33.2203C49.2194 44.2189 53.5944 56.5865 56.838 69.076C56.7064 69.1836 56.5636 69.2863 56.4239 69.397C53.1178 56.8882 48.6898 44.5029 41.4804 33.4626C34.1347 22.2137 23.8808 12.7671 11.144 5.0971Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56581C15.3203 4.54054 15.4587 4.52552 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C60.6561 65.8517 60.5213 65.9512 60.3961 66.0731C57.772 53.7409 53.7966 41.5755 46.6274 30.9685C39.2864 20.108 28.6377 11.3563 15.1836 4.56581Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.7344C14.0361 4.71353 14.1741 4.69267 14.317 4.67822C27.609 11.6532 38.1919 20.5574 45.5281 31.5062C52.7053 42.2063 56.7898 54.4166 59.5535 66.7906C59.4219 66.8982 59.2935 67.0137 59.1539 67.1228C56.31 54.7296 52.1933 42.492 45.0113 31.7518C37.6671 20.7644 27.1291 11.7881 13.8965 4.7344Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8384 40.5339 -25.7822 39.3623 -25.6763 38.1779C-19.1747 27.3623 -12.1082 19.6121 -3.52824 15.0557C5.16246 10.4351 15.3746 8.10312 26.2111 5.86584C27.6 6.41955 28.9596 7.04416 30.2844 7.73719C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3708 -18.4461 31.1114 -25.8608 41.6782V41.6734Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8608 41.513 -25.8608 41.3621 -25.8608 41.192C-18.5616 30.5994 -10.7055 22.854 -1.50282 18.0104C7.81541 13.1073 18.4994 10.2457 29.7355 7.45312C29.9249 7.55424 30.0999 7.64411 30.2844 7.7372C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3676 -18.4461 31.1082 -25.8608 41.6734Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4003C-25.901 43.2398 -25.8914 43.0793 -25.8898 42.9333C-18.154 32.4772 -9.8838 24.7591 -0.342491 19.7726C9.31919 14.7267 20.2439 11.5826 31.6919 8.49792C31.8524 8.59262 32.0129 8.70336 32.1734 8.80607C20.6853 11.963 9.69153 15.1841 -0.0407619 20.2637C-9.67195 25.2919 -18.0529 32.9972 -25.901 43.4003Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8393C-25.5592 36.6692 -25.5335 36.4991 -25.5159 36.3402C-19.5182 25.4267 -12.9605 17.6989 -4.77857 13.3383C3.50287 8.92318 13.3957 6.93467 23.9641 5.0553C24.1583 5.12752 24.3654 5.20295 24.5676 5.27196C13.9333 7.25726 3.95707 9.33244 -4.44795 13.8086C-12.7246 18.2157 -19.422 25.9483 -25.5592 36.8393Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.3402C-25.487 36.1588 -25.4677 35.9823 -25.4292 35.8058C-19.5857 24.8826 -13.166 17.1789 -5.11883 12.889C3.04384 8.53318 12.8339 6.63615 23.3109 4.8595C23.5332 4.90905 23.7516 4.97504 23.9641 5.0569C13.3956 6.93467 3.50285 8.92318 -4.77859 13.3383C-12.9637 17.6989 -19.5183 25.4186 -25.5143 36.3402Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6674C-25.7004 38.5069 -25.6908 38.3464 -25.6763 38.1859C-19.1747 27.3703 -12.1082 19.6201 -3.52827 15.0637C5.16242 10.4351 15.3746 8.10312 26.2111 5.86584L26.8274 6.08572C15.9139 8.4241 5.61983 10.8524 -3.18482 15.5163C-11.8819 20.1273 -19.0736 27.8871 -25.7164 38.6674Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1953C-25.8336 40.0348 -25.824 39.8839 -25.8095 39.7138C-18.8762 28.9961 -11.4005 21.2282 -2.49312 16.5194C6.5298 11.7479 17.0052 9.13668 28.0567 6.61694L28.5928 6.88818C17.4722 9.48977 6.92622 12.1844 -2.18657 16.9992C-11.2015 21.753 -18.7832 29.5113 -25.8448 40.1953Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34092C5.87818 6.13228 6.76249 5.92363 7.67891 5.73425C19.7255 14.1457 29.5702 24.1508 36.8565 35.6999C44.0338 47.0788 48.8117 59.6134 52.6779 72.2281C51.6283 72.9519 50.5626 73.6806 49.513 74.4044C45.2295 61.7335 40.2157 49.1219 33.0962 37.5038C25.8949 25.7509 16.4338 15.3398 4.99707 6.34092Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34089C5.11584 6.31842 5.23781 6.2783 5.35497 6.2478C16.8719 15.1696 26.3924 25.5182 33.6017 37.2502C40.7292 48.8362 45.7157 61.4333 49.9431 74.0994L49.505 74.4044C45.2214 61.7334 40.2076 49.1219 33.0882 37.5038C25.8949 25.7509 16.4338 15.3397 4.99707 6.34089Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67307C3.76459 6.63937 3.88335 6.61851 4.00051 6.58801C15.2045 15.7794 24.5324 26.3367 31.6952 38.1586C38.7842 49.8585 43.8798 62.4925 48.3062 75.1747C48.1457 75.2726 48.0045 75.3609 47.8488 75.4507C43.3662 62.7718 38.2433 50.1346 31.1672 38.4009C24.0204 26.5485 14.76 15.9367 3.6394 6.67307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54326C8.85533 5.52079 8.98051 5.48869 9.1073 5.46301C21.4412 13.5872 31.4511 23.3548 38.776 34.7883C45.9709 46.0228 50.5963 58.5043 54.2427 71.079C54.1031 71.1881 53.9699 71.2876 53.8303 71.4C50.1245 58.8141 45.4574 46.3181 38.2625 35.0371C30.9504 23.573 20.9677 13.7364 8.7157 5.54165V5.54326Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46305C9.2389 5.4454 9.36088 5.43095 9.48927 5.40527C21.9002 13.4299 31.9695 23.1414 39.2896 34.5283C46.4909 45.7323 51.0891 58.1898 54.6536 70.7452C54.514 70.8543 54.3824 70.9618 54.2427 71.0662C50.5963 58.498 45.9709 46.0228 38.776 34.7883C31.4511 23.3548 21.4412 13.5872 9.1073 5.46305Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80808C7.43174 5.7824 7.55692 5.7487 7.6789 5.73425C19.7255 14.1457 29.5701 24.1508 36.8565 35.6999C44.0338 47.0789 48.8116 59.6134 52.6779 72.2281C52.5367 72.3308 52.3826 72.4287 52.2478 72.5282C48.3221 59.899 43.5138 47.3565 36.3365 35.9358C29.0598 24.3563 19.2537 14.3094 7.30334 5.80808Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06968C6.25536 6.04079 6.38055 6.00709 6.50092 5.98462C18.2827 14.6512 27.9733 24.8249 35.2292 36.4655C42.3871 47.9584 47.2661 60.5298 51.317 73.1702C51.1709 73.2649 51.0297 73.3676 50.8756 73.4655C46.7847 60.8154 41.8591 48.2408 34.7044 36.7126C27.4661 25.0528 17.8365 14.8101 6.1366 6.06647L6.13981 6.06968Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.5688 49.5359 -25.6731 48.4895 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C37.4777 12.4942 38.5562 13.3287 39.5673 14.1793C27.4613 18.4388 15.6924 22.7047 4.76599 28.208C-6.05445 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.4885 50.4299 -25.5078 50.2951 -25.5206 50.149C-16.143 40.5531 -6.28073 33.1368 4.49156 27.7057C15.3457 22.2184 27.0842 18.0135 39.1548 13.8198C39.2816 13.9274 39.418 14.059 39.5593 14.1793C27.4613 18.4388 15.6924 22.7047 4.766 28.208C-6.05444 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1521C-25.3008 52.022 -25.3297 51.8792 -25.3522 51.7364C-15.6488 42.3732 -5.47351 35.0579 5.51705 29.5546C16.6023 24.0032 28.4804 19.5816 40.6458 15.1616C40.779 15.2852 40.9202 15.4056 41.0534 15.5292C28.8672 20.0101 16.957 24.4831 5.81075 30.049C-5.24401 35.5699 -15.5091 42.8499 -25.3008 52.1521Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2218C-25.8192 46.0838 -25.8272 45.9265 -25.8256 45.774C-17.4174 35.6325 -8.49236 27.993 1.56895 22.8043C11.7201 17.5658 23.0108 13.9771 34.7332 10.4351C34.8937 10.5378 35.0542 10.6485 35.2147 10.756C23.4409 14.3752 12.1037 18.0184 1.86105 23.2922C-8.26767 28.5098 -17.297 36.1316 -25.8144 46.2266L-25.8079 46.2218Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.774C-25.8255 45.6264 -25.8367 45.4771 -25.8416 45.3278C-17.5392 35.1301 -8.71856 27.4842 1.25448 22.3276C11.3318 17.1148 22.5583 13.5936 34.247 10.1301L34.7285 10.4399C23.0125 13.9836 11.7154 17.5706 1.56423 22.8091C-8.48905 27.993 -17.4173 35.6325 -25.8255 45.774Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8748C-25.7453 47.7288 -25.7502 47.5795 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C36.5597 11.7832 36.7089 11.9003 36.855 12.0095C24.9448 15.8693 13.4342 19.7661 2.93476 25.1314C-7.46679 30.4469 -16.8476 37.9708 -25.7309 47.8748Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2326C-25.6105 49.085 -25.6298 48.9389 -25.6394 48.8009C-16.5571 39.0237 -6.98209 31.5335 3.57032 26.1714C14.2158 20.7467 25.8227 16.7312 37.7906 12.7301L38.2384 13.0816C26.2303 17.1437 14.5769 21.2234 3.86242 26.6593C-6.76061 32.047 -16.4303 39.5132 -25.6105 49.2326Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.4771C-1.12893 8.16895 -0.326466 7.86562 0.508097 7.61365C10.815 17.5113 19.5329 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C42.6038 78.5371 41.4595 79.1646 40.32 79.7825C35.0622 67.1453 29.5509 54.4696 22.7685 42.3877C15.8971 30.1523 7.60656 18.7702 -1.9314 8.4771Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47711C-1.82066 8.43057 -1.70992 8.38563 -1.61041 8.35193C8.01522 18.5652 16.3682 29.907 23.266 42.1293C30.0821 54.208 35.5806 66.8869 40.7951 79.5258C40.6346 79.6156 40.4741 79.7023 40.3136 79.7825C35.0558 67.1453 29.5445 54.4696 22.7621 42.3877C15.8927 30.1527 7.60431 18.7705 -1.9314 8.47711Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93927C-3.03721 8.89433 -2.93128 8.86063 -2.82696 8.81409C6.46005 19.2953 14.5676 30.765 21.3497 43.0167C28.0663 55.1532 33.6306 67.8097 39.0039 80.4212C38.8515 80.5015 38.6829 80.5817 38.5225 80.662C33.1138 68.0601 27.5191 55.418 20.8329 43.2671C14.0861 31.0022 6.04672 19.4942 -3.14795 8.93927Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32154C1.55612 7.29104 1.66847 7.25413 1.79044 7.2124C12.4375 16.8564 21.3931 27.7266 28.4564 39.7026C35.4506 51.563 40.7212 64.2356 45.4686 76.9225C45.3242 77.0172 45.1669 77.1087 45.0128 77.2066C40.2141 64.5164 34.9226 51.8487 27.9364 39.953C20.8891 27.9658 11.9946 17.0378 1.43896 7.32154Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21236C1.91455 7.18234 2.03148 7.14372 2.14516 7.0968C12.8709 16.6798 21.8954 27.5003 28.97 39.4426C35.9803 51.2837 41.2252 63.9514 45.9116 76.6352C45.7736 76.7267 45.6211 76.8246 45.4638 76.9161C40.7213 64.2371 35.4507 51.5582 28.4516 39.6977C21.3931 27.7266 12.4376 16.8564 1.79529 7.21236Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72118C0.277004 7.68105 0.394164 7.65056 0.506509 7.61365C10.815 17.5113 19.533 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C43.5876 77.9914 43.4272 78.0829 43.2667 78.1664C38.2914 65.4874 32.9052 52.8165 25.9944 40.8405C19.037 28.7763 10.372 17.691 0.155029 7.72118Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07902C-0.788642 8.0373 -0.674692 8.00038 -0.570371 7.95544C9.33431 17.9334 17.894 29.1617 24.8918 41.3556C31.7722 53.3701 37.1888 66.0539 42.2604 78.712C42.1079 78.8115 41.9555 78.8901 41.7966 78.9736C36.6785 66.3155 31.2233 53.6382 24.3751 41.6028C17.4115 29.4001 8.92095 18.1347 -0.891357 8.07902Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.3346 57.8832 -24.5272 56.8929 -24.7278 55.9059C-14.6062 47.4875 -3.51314 40.3111 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C45.3964 19.8367 46.2856 20.774 47.0993 21.7498C34.661 27.1054 22.3031 32.4049 10.311 38.1762C-1.61037 43.9139 -13.0808 50.6401 -24.1372 58.8622Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.1677 58.7226 -24.1966 58.583 -24.2223 58.4514C-13.2317 50.1587 -1.8271 43.3971 10.0494 37.6772C21.982 31.9106 34.3127 26.6577 46.7557 21.3406C46.8681 21.4802 46.9869 21.6102 47.0992 21.7514C34.661 27.1055 22.303 32.405 10.311 38.1763C-1.61043 43.9123 -13.0809 50.6402 -24.1372 58.8622Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3387C-23.8354 60.2023 -23.8707 60.0739 -23.9044 59.9439C-12.6523 51.9193 -0.997252 45.3069 11.0381 39.5452C23.1393 33.7515 35.539 28.3284 48.0029 22.8347C48.1152 22.976 48.2292 23.1156 48.3351 23.2424C35.8712 28.781 23.4587 34.2506 11.3158 40.046C-0.769354 45.8157 -12.4805 52.3815 -23.7985 60.3387Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7793C-24.9316 54.6365 -24.9524 54.4936 -24.9701 54.3588C-14.7403 45.3808 -4.05143 38.2806 7.30666 32.6714C18.753 27.0172 30.8269 22.257 43.1319 17.4775C43.2571 17.6043 43.3855 17.7391 43.4994 17.8611C31.1832 22.6984 19.0756 27.51 7.57628 33.1834C-3.83958 38.8006 -14.6103 45.8752 -24.9091 54.7793Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3587C-24.9965 54.2223 -25.0158 54.0847 -25.028 53.9463C-14.8832 44.8993 -4.27781 37.7589 7.03213 32.1593C18.4271 26.5212 30.4641 21.814 42.7627 17.0939C42.8879 17.2207 43.0098 17.3394 43.1318 17.4775C30.8268 22.2569 18.7529 27.0172 7.30657 32.6713C-4.05152 38.2806 -14.7404 45.3808 -24.9702 54.3587Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3023C-24.6732 56.1707 -24.6989 56.0391 -24.7278 55.8995C-14.6058 47.4833 -3.51282 40.3091 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C44.6245 19.0294 44.7496 19.1562 44.8748 19.283C32.4912 24.3177 20.2857 29.3122 8.6034 35.0257C-3.26448 40.8096 -14.422 47.9494 -24.6459 56.3023Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5783C-24.4181 57.4467 -24.455 57.3103 -24.4742 57.1755C-13.7212 48.6693 -2.52201 41.797 9.20521 36.0995C20.995 30.3683 33.2519 25.2566 45.6644 20.1112C45.7816 20.2412 45.91 20.376 46.0335 20.4948C33.6146 25.6948 21.3401 30.8546 9.49089 36.597C-2.28448 42.3058 -13.5479 49.138 -24.3924 57.5783Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061C-7.48127 10.9872 -6.75905 10.5828 -5.98708 10.2249C2.31446 21.3574 9.72289 33.1287 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C32.8843 83.2123 31.6694 83.7355 30.424 84.2089C24.4231 71.8943 18.6117 59.4465 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061L-7.89855 11.2279C-0.236618 22.6695 6.65816 34.6406 12.919 46.944C19.1606 59.2105 24.964 71.6824 30.9327 84.0179C30.7722 84.0901 30.6021 84.1543 30.424 84.2169C24.4231 71.8942 18.6117 59.4464 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0416C-9.19845 11.9726 -9.09894 11.9245 -9.00265 11.8667C-1.74355 23.4719 4.86876 35.5362 10.9916 47.8171C17.1144 60.098 22.9451 72.4817 29.0294 84.7177C28.8689 84.7851 28.6972 84.8525 28.5206 84.9167C22.401 72.6935 16.5575 60.3243 10.4716 48.0595C4.38568 35.7946 -2.14318 23.6886 -9.29956 12.0416Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81876C-5.03371 9.7658 -4.93099 9.72407 -4.83148 9.67432C3.85406 20.5774 11.53 32.2478 18.1014 44.5414C24.6527 56.7549 30.3277 69.3649 35.953 81.9058C35.7797 81.9764 35.624 82.0486 35.4507 82.1193C29.7933 69.6152 24.1102 57.0053 17.5814 44.7806C11.0387 32.4878 3.43563 20.7895 -5.14124 9.81876Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.6743C-4.73036 9.62134 -4.62123 9.5764 -4.5169 9.52344C4.25997 20.3672 12.0033 32.0082 18.6117 44.2942C25.192 56.4917 30.8477 69.1145 36.4265 81.6554C36.266 81.7469 36.1151 81.8159 35.945 81.9058C30.3277 69.3632 24.6527 56.7549 18.1014 44.5414C11.5279 32.2476 3.84988 20.5771 -4.83789 9.6743Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3725C-6.20163 10.3154 -6.09434 10.266 -5.98382 10.2249C2.31661 21.3575 9.72397 33.1288 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C33.9276 82.7693 33.7575 82.8479 33.6002 82.9121C27.8016 70.4595 22.0623 57.9105 15.6474 45.6601C9.23325 33.3592 1.89477 21.5628 -6.30481 10.3725Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8652L-6.96765 10.687C1.01468 22.0069 8.20119 33.8675 14.54 46.1833C20.8779 58.4417 26.6572 70.9618 32.5233 83.3775C32.3628 83.4449 32.1878 83.5236 32.0209 83.591C26.118 71.1913 20.3274 58.7017 14.0152 46.424C7.68857 34.127 0.626882 22.1895 -7.27258 10.8652Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6814C-22.3027 65.757 -22.5948 64.8165 -22.8837 63.884C-10.9911 56.6153 1.2593 50.4203 13.6927 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.9301 27.9497 52.6668 28.9672 53.3232 30.0072C40.8626 36.276 28.2959 42.4133 15.6074 48.2745C2.93966 54.1229 -9.70238 59.9712 -22.0122 66.6766L-22.017 66.6814Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6814C-22.0556 66.5642 -22.0941 66.4278 -22.139 66.2946C-9.87898 59.5122 2.7117 53.6044 15.3521 47.7577C28.0182 41.8981 40.5864 35.8057 53.0535 29.5786C53.1402 29.7215 53.2365 29.8691 53.3232 30.0119C40.8625 36.2808 28.2959 42.4181 15.6073 48.2793C2.9396 54.1276 -9.70243 59.976 -22.0122 66.6814H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1066C-21.5901 67.9766 -21.6399 67.8546 -21.6848 67.7214C-9.23056 61.2343 3.54308 55.4999 16.2846 49.6563C29.055 43.7999 41.6233 37.5551 54.0695 31.1852C54.1562 31.3296 54.2508 31.4693 54.3359 31.6121C41.9121 38.0318 29.3391 44.3119 16.5478 50.1635C3.75654 56.015 -9.03636 61.6885 -21.5356 68.1066Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8088C-23.2079 62.6884 -23.2368 62.5488 -23.2737 62.4123C-11.6042 54.8692 0.437553 48.5072 12.7329 42.7006C25.0732 36.8747 37.5612 31.1804 50.0684 25.3866C50.1727 25.531 50.2674 25.6707 50.3685 25.8071C37.8709 31.6426 25.3621 37.377 12.9945 43.211C0.657429 49.0224 -11.4486 55.3314 -23.1758 62.8088Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4123C-23.3075 62.2919 -23.3444 62.1555 -23.3749 62.0159C-11.7728 54.3892 0.21759 47.9872 12.4632 42.1854C24.7843 36.3514 37.261 30.7165 49.7473 24.9773C49.8468 25.1073 49.9512 25.2501 50.0539 25.3866C37.5627 31.1803 25.0731 36.8746 12.7328 42.7005C0.437466 48.5072 -11.6043 54.8643 -23.277 62.4123Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2628C-22.8082 64.1376 -22.8451 64.0012 -22.8837 63.884C-10.9911 56.6153 1.25929 50.4203 13.6911 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.285 27.0718 51.3909 27.2162 51.4952 27.359C39.0056 33.3508 26.4963 39.2591 13.9671 45.0839C1.49683 50.905 -10.813 57.0647 -22.7665 64.2564V64.2628Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4793C-22.4343 65.3477 -22.4792 65.2145 -22.5129 65.0861C-10.4374 58.0597 1.99113 52.0011 14.5369 46.1672C27.1131 40.3188 39.6481 34.3464 52.142 28.2498C52.2479 28.3942 52.349 28.5306 52.4517 28.667C39.975 34.8096 27.4277 40.8137 14.8097 46.6792C2.23026 52.5179 -10.2545 58.5252 -22.3894 65.4793Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.2028 14.8036 -12.5528 14.2531 -11.8659 13.7668C-5.71258 25.7797 0.0747986 38.0013 5.82687 50.226C11.5645 62.4235 17.4674 74.5584 23.8486 86.4975C22.5647 86.8976 21.2807 87.2689 19.9968 87.6113C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.8871 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3542C-13.7661 15.274 -13.6762 15.1937 -13.5815 15.1183C-8.15368 27.3382 -2.89432 39.6208 2.59454 51.7428C8.0834 63.8648 13.9895 75.7734 20.5232 87.4717L19.9904 87.6098C13.423 75.9612 7.51044 64.0767 2.08096 51.9819C-3.34852 39.8872 -8.53084 27.587 -13.8464 15.3542H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2032C-14.758 16.1166 -14.6713 16.0428 -14.5815 15.9641C-9.59173 28.2836 -4.65978 40.5661 0.681424 52.6319C6.01356 64.7211 11.9749 76.5228 18.5411 87.9886C18.3581 88.0367 18.1752 88.0849 18.0083 88.125C11.4042 76.717 5.44385 64.9484 0.155007 52.8743C-5.12842 40.8373 -9.97531 28.5371 -14.8591 16.2032H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2308L-10.8195 13.0366C-4.23929 24.9035 1.85945 37.0881 7.75115 49.3417C13.6428 61.5954 19.5185 73.8266 25.8018 85.8844C25.6204 85.9406 25.4407 85.9967 25.277 86.0449C18.9696 74.0159 13.0795 61.8104 7.23115 49.5793C1.38278 37.3481 -4.64533 25.1474 -11.0988 13.2308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0367C-10.7264 12.9709 -10.6317 12.9067 -10.5225 12.8361C-3.85727 24.6772 2.34257 36.833 8.27921 49.0995C14.2158 61.3659 20.069 73.6276 26.3299 85.7127C26.1533 85.7769 25.9736 85.8331 25.8018 85.8844C19.5185 73.8266 13.6365 61.5746 7.75119 49.3418C1.86591 37.109 -4.24566 24.9035 -10.8195 13.0367Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9643L-11.8659 13.7637C-5.71261 25.7766 0.0747623 37.9981 5.82683 50.2229C11.5645 62.4203 17.4674 74.5552 23.8486 86.4943L23.3158 86.6548C16.9025 74.751 11.0043 62.6466 5.30363 50.4572C-0.397086 38.2677 -6.11706 26.0077 -12.1532 13.9643Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6255C-12.9131 14.5591 -12.8262 14.4856 -12.7454 14.4056C-6.94355 26.5325 -1.40173 38.8006 4.21553 50.9724C9.83278 63.1442 15.7405 75.1843 22.2052 87.0079L21.6724 87.1684C15.1788 75.393 9.27266 63.3737 3.70195 51.2275C-1.8816 39.0558 -7.33194 26.7797 -13.0054 14.6255Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.4765 73.2585 -19.8729 72.379 -20.2677 71.4802C-7.33838 65.7955 5.85254 60.5779 18.8332 54.7424C31.8139 48.9069 44.3516 42.3218 56.6373 35.5522C57.2263 36.6292 57.8057 37.7109 58.3434 38.8006C46.2165 45.8254 33.7542 52.6624 20.6805 58.4514C7.58265 64.25 -5.86185 69.0937 -19.0817 74.1572Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1573C-19.1347 74.0289 -19.1956 73.9021 -19.2422 73.7865C-6.05769 68.6395 7.35471 63.7365 20.4413 57.933C33.5038 52.1409 45.9725 45.3344 58.1299 38.3529L58.3433 38.8087C46.2165 45.8255 33.7542 52.6625 20.6804 58.4498C7.58261 64.2501 -5.86189 69.0937 -19.0817 74.1573Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5054C-18.5199 75.3866 -18.5857 75.2711 -18.6467 75.1443C-5.32581 70.3215 8.20375 65.6238 21.3273 59.8413C34.4187 54.0732 46.8456 47.1623 58.9003 40.0413C58.9597 40.1857 59.0335 40.343 59.1073 40.5003C47.08 47.6502 34.6787 54.5948 21.5824 60.3517C8.43807 66.1294 -5.10433 70.7837 -18.4622 75.5054Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4611C-20.701 70.3311 -20.7395 70.2123 -20.7925 70.0823C-8.03008 64.1007 5.00838 58.6745 17.9168 52.8373C30.8253 47.0002 43.3887 40.5211 55.7434 33.8992C55.8221 34.0452 55.9039 34.1881 55.989 34.3421C43.6487 41.0138 31.0933 47.5154 18.164 53.3509C5.23467 59.1864 -7.83588 64.5565 -20.648 70.4611Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0823C-20.8455 69.9539 -20.8888 69.8288 -20.9386 69.7052C-8.22113 63.6353 4.78201 58.1657 17.6696 52.3238C30.5572 46.4818 43.127 40.0397 55.5026 33.4514L55.7433 33.904C43.3854 40.5211 30.8268 47.0002 17.9168 52.8422C5.0067 58.6841 -8.03015 64.1008 -20.7926 70.0823Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8445C-20.1569 71.729 -20.2179 71.6022 -20.2709 71.4802C-7.33835 65.7955 5.85257 60.5795 18.8332 54.7424C31.8139 48.9053 44.3516 42.3218 56.6374 35.5522C56.716 35.6983 56.7979 35.8524 56.8861 36.0032C44.6116 42.8001 32.0803 49.4285 19.0932 55.2512C6.10615 61.0738 -7.13613 66.2449 -20.1104 71.8445Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9953C-19.6434 72.8765 -19.6963 72.7465 -19.7461 72.6357C-6.68196 67.2159 6.60846 62.1507 19.6517 56.3297C32.6693 50.5198 45.1701 43.8112 57.4045 36.9453C57.4799 37.0833 57.565 37.2374 57.642 37.3915C45.4446 44.2927 32.9438 51.0334 19.9069 56.84C6.87007 62.6467 -6.47814 67.6669 -19.5744 72.9953H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.705C-18.3353 19.9844 -17.7431 19.2445 -17.1621 18.5255C-13.3552 31.044 -9.32526 43.28 -4.4559 55.0714C0.394197 66.8162 6.29713 78.1631 13.1309 89.1761C11.8053 89.4249 10.4587 89.6271 9.12983 89.8181C2.19011 79.2673 -3.68072 68.349 -8.17773 56.8978C-12.7004 45.3904 -16.0387 33.278 -18.9436 20.705Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7051C-18.8633 20.6027 -18.777 20.5051 -18.6852 20.413C-15.6615 32.9731 -12.2238 45.108 -7.66416 56.6491C-3.1222 68.1436 2.74703 79.1181 9.67711 89.7411C9.50218 89.7684 9.31761 89.7909 9.12823 89.823C2.19011 79.2722 -3.68072 68.3538 -8.17773 56.9027C-12.6972 45.3905 -16.0419 33.2781 -18.9436 20.7051Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9489C-19.7396 21.8398 -19.6546 21.7178 -19.5807 21.5958C-17.0209 34.1769 -13.9314 46.2187 -9.55154 57.6137C-5.20057 68.9364 0.651003 79.6943 7.63887 90.0477C7.46393 90.0734 7.27134 90.0991 7.08517 90.1135C0.074831 79.8419 -5.75427 69.1547 -10.0619 57.8769C-14.384 46.5364 -17.3788 34.5107 -19.8151 21.9489Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.821C-16.3757 17.7295 -16.2906 17.6428 -16.2072 17.5546C-11.9605 29.9992 -7.59029 42.2705 -2.55242 54.1662C2.51942 66.1027 8.43297 77.6638 15.1435 88.7621L14.5995 88.8744C7.87557 77.8359 1.97483 66.3166 -3.05476 54.4102C-8.04609 42.5465 -12.3329 30.2881 -16.4607 17.8178L-16.4672 17.821Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5546L-15.9553 17.2914C-11.5851 29.72 -7.12819 42.0073 -2.03253 53.9271C3.08934 65.8872 9.00992 77.4892 15.6891 88.6546C15.519 88.6883 15.3328 88.73 15.1434 88.7621C8.43285 77.6638 2.51931 66.1028 -2.55253 54.1663C-7.59041 42.2689 -11.9606 29.9992 -16.2073 17.5546Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.8192C-17.3243 18.7229 -17.244 18.6202 -17.1654 18.5255C-13.3585 31.044 -9.32853 43.28 -4.45918 55.0714C0.390923 66.8179 6.29386 78.1631 13.1276 89.1761C12.9415 89.2163 12.7633 89.2532 12.582 89.2837C5.73694 78.3284 -0.157963 67.0474 -4.96473 55.3217C-9.77952 43.5737 -13.7325 31.3457 -17.4045 18.8192Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.7612C-18.0817 19.6649 -18.0015 19.5622 -17.9229 19.4675C-14.506 32.0004 -10.7777 44.1995 -6.06081 55.8673C-1.35837 67.4951 4.53172 78.6573 11.4201 89.4826C11.2275 89.5067 11.0381 89.5404 10.8648 89.5661C3.96358 78.813 -1.91689 67.7053 -6.56958 56.1113C-11.2399 44.482 -14.8671 32.3086 -18.162 19.7612Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3863C-17.5153 77.2676 -17.5811 77.152 -17.6421 77.0252C-4.16068 72.6726 9.50049 68.2719 22.6689 62.5279C35.7844 56.8079 48.1039 49.7366 59.9868 42.439C60.0606 42.5995 60.1312 42.7455 60.1938 42.8996C48.3334 50.2309 36.0412 57.3263 22.9241 63.035C9.75246 68.771 -3.9408 73.1156 -17.4575 77.3831V77.3863Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89643C13.614 4.76161 14.6058 4.64124 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C59.836 66.5514 58.8778 67.3619 57.9213 68.1804C54.8639 55.739 50.5932 43.4292 43.3951 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89643Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89645C12.7652 4.88655 12.9029 4.86941 13.0394 4.84509C26.1132 12.0962 36.5645 21.2154 43.9135 32.2894C51.1051 43.1339 55.3357 55.4116 58.3225 67.837C58.1749 67.9509 58.0497 68.0729 57.9181 68.1804C54.8607 55.7406 50.59 43.4292 43.3919 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89645Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.0971C11.2789 5.08747 11.4169 5.0666 11.5485 5.04895C24.3606 12.6403 34.6611 22.0115 42.0085 33.2203C49.2194 44.2189 53.5944 56.5865 56.838 69.076C56.7064 69.1836 56.5636 69.2863 56.4239 69.397C53.1178 56.8882 48.6898 44.5029 41.4804 33.4626C34.1347 22.2137 23.8808 12.7671 11.144 5.0971Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56581C15.3203 4.54054 15.4587 4.52552 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C60.6561 65.8517 60.5213 65.9512 60.3961 66.0731C57.772 53.7409 53.7966 41.5755 46.6274 30.9685C39.2864 20.108 28.6377 11.3563 15.1836 4.56581Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.7344C14.0361 4.71353 14.1741 4.69267 14.317 4.67822C27.609 11.6532 38.1919 20.5574 45.5281 31.5062C52.7053 42.2063 56.7898 54.4166 59.5535 66.7906C59.4219 66.8982 59.2935 67.0137 59.1539 67.1228C56.31 54.7296 52.1933 42.492 45.0113 31.7518C37.6671 20.7644 27.1291 11.7881 13.8965 4.7344Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8384 40.5339 -25.7822 39.3623 -25.6763 38.1779C-19.1747 27.3623 -12.1082 19.6121 -3.52824 15.0557C5.16246 10.4351 15.3746 8.10312 26.2111 5.86584C27.6 6.41955 28.9596 7.04416 30.2844 7.73719C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3708 -18.4461 31.1114 -25.8608 41.6782V41.6734Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8608 41.513 -25.8608 41.3621 -25.8608 41.192C-18.5616 30.5994 -10.7055 22.854 -1.50282 18.0104C7.81541 13.1073 18.4994 10.2457 29.7355 7.45312C29.9249 7.55424 30.0999 7.64411 30.2844 7.7372C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3676 -18.4461 31.1082 -25.8608 41.6734Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4003C-25.901 43.2398 -25.8914 43.0793 -25.8898 42.9333C-18.154 32.4772 -9.8838 24.7591 -0.342491 19.7726C9.31919 14.7267 20.2439 11.5826 31.6919 8.49792C31.8524 8.59262 32.0129 8.70336 32.1734 8.80607C20.6853 11.963 9.69153 15.1841 -0.0407619 20.2637C-9.67195 25.2919 -18.0529 32.9972 -25.901 43.4003Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8393C-25.5592 36.6692 -25.5335 36.4991 -25.5159 36.3402C-19.5182 25.4267 -12.9605 17.6989 -4.77857 13.3383C3.50287 8.92318 13.3957 6.93467 23.9641 5.0553C24.1583 5.12752 24.3654 5.20295 24.5676 5.27196C13.9333 7.25726 3.95707 9.33244 -4.44795 13.8086C-12.7246 18.2157 -19.422 25.9483 -25.5592 36.8393Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.3402C-25.487 36.1588 -25.4677 35.9823 -25.4292 35.8058C-19.5857 24.8826 -13.166 17.1789 -5.11883 12.889C3.04384 8.53318 12.8339 6.63615 23.3109 4.8595C23.5332 4.90905 23.7516 4.97504 23.9641 5.0569C13.3956 6.93467 3.50285 8.92318 -4.77859 13.3383C-12.9637 17.6989 -19.5183 25.4186 -25.5143 36.3402Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6674C-25.7004 38.5069 -25.6908 38.3464 -25.6763 38.1859C-19.1747 27.3703 -12.1082 19.6201 -3.52827 15.0637C5.16242 10.4351 15.3746 8.10312 26.2111 5.86584L26.8274 6.08572C15.9139 8.4241 5.61983 10.8524 -3.18482 15.5163C-11.8819 20.1273 -19.0736 27.8871 -25.7164 38.6674Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1953C-25.8336 40.0348 -25.824 39.8839 -25.8095 39.7138C-18.8762 28.9961 -11.4005 21.2282 -2.49312 16.5194C6.5298 11.7479 17.0052 9.13668 28.0567 6.61694L28.5928 6.88818C17.4722 9.48977 6.92622 12.1844 -2.18657 16.9992C-11.2015 21.753 -18.7832 29.5113 -25.8448 40.1953Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34092C5.87818 6.13228 6.76249 5.92363 7.67891 5.73425C19.7255 14.1457 29.5702 24.1508 36.8565 35.6999C44.0338 47.0788 48.8117 59.6134 52.6779 72.2281C51.6283 72.9519 50.5626 73.6806 49.513 74.4044C45.2295 61.7335 40.2157 49.1219 33.0962 37.5038C25.8949 25.7509 16.4338 15.3398 4.99707 6.34092Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34089C5.11584 6.31842 5.23781 6.2783 5.35497 6.2478C16.8719 15.1696 26.3924 25.5182 33.6017 37.2502C40.7292 48.8362 45.7157 61.4333 49.9431 74.0994L49.505 74.4044C45.2214 61.7334 40.2076 49.1219 33.0882 37.5038C25.8949 25.7509 16.4338 15.3397 4.99707 6.34089Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67307C3.76459 6.63937 3.88335 6.61851 4.00051 6.58801C15.2045 15.7794 24.5324 26.3367 31.6952 38.1586C38.7842 49.8585 43.8798 62.4925 48.3062 75.1747C48.1457 75.2726 48.0045 75.3609 47.8488 75.4507C43.3662 62.7718 38.2433 50.1346 31.1672 38.4009C24.0204 26.5485 14.76 15.9367 3.6394 6.67307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54326C8.85533 5.52079 8.98051 5.48869 9.1073 5.46301C21.4412 13.5872 31.4511 23.3548 38.776 34.7883C45.9709 46.0228 50.5963 58.5043 54.2427 71.079C54.1031 71.1881 53.9699 71.2876 53.8303 71.4C50.1245 58.8141 45.4574 46.3181 38.2625 35.0371C30.9504 23.573 20.9677 13.7364 8.7157 5.54165V5.54326Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46305C9.2389 5.4454 9.36088 5.43095 9.48927 5.40527C21.9002 13.4299 31.9695 23.1414 39.2896 34.5283C46.4909 45.7323 51.0891 58.1898 54.6536 70.7452C54.514 70.8543 54.3824 70.9618 54.2427 71.0662C50.5963 58.498 45.9709 46.0228 38.776 34.7883C31.4511 23.3548 21.4412 13.5872 9.1073 5.46305Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80808C7.43174 5.7824 7.55692 5.7487 7.6789 5.73425C19.7255 14.1457 29.5701 24.1508 36.8565 35.6999C44.0338 47.0789 48.8116 59.6134 52.6779 72.2281C52.5367 72.3308 52.3826 72.4287 52.2478 72.5282C48.3221 59.899 43.5138 47.3565 36.3365 35.9358C29.0598 24.3563 19.2537 14.3094 7.30334 5.80808Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06968C6.25536 6.04079 6.38055 6.00709 6.50092 5.98462C18.2827 14.6512 27.9733 24.8249 35.2292 36.4655C42.3871 47.9584 47.2661 60.5298 51.317 73.1702C51.1709 73.2649 51.0297 73.3676 50.8756 73.4655C46.7847 60.8154 41.8591 48.2408 34.7044 36.7126C27.4661 25.0528 17.8365 14.8101 6.1366 6.06647L6.13981 6.06968Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.5688 49.5359 -25.6731 48.4895 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C37.4777 12.4942 38.5562 13.3287 39.5673 14.1793C27.4613 18.4388 15.6924 22.7047 4.76599 28.208C-6.05445 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.4885 50.4299 -25.5078 50.2951 -25.5206 50.149C-16.143 40.5531 -6.28073 33.1368 4.49156 27.7057C15.3457 22.2184 27.0842 18.0135 39.1548 13.8198C39.2816 13.9274 39.418 14.059 39.5593 14.1793C27.4613 18.4388 15.6924 22.7047 4.766 28.208C-6.05444 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1521C-25.3008 52.022 -25.3297 51.8792 -25.3522 51.7364C-15.6488 42.3732 -5.47351 35.0579 5.51705 29.5546C16.6023 24.0032 28.4804 19.5816 40.6458 15.1616C40.779 15.2852 40.9202 15.4056 41.0534 15.5292C28.8672 20.0101 16.957 24.4831 5.81075 30.049C-5.24401 35.5699 -15.5091 42.8499 -25.3008 52.1521Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2218C-25.8192 46.0838 -25.8272 45.9265 -25.8256 45.774C-17.4174 35.6325 -8.49236 27.993 1.56895 22.8043C11.7201 17.5658 23.0108 13.9771 34.7332 10.4351C34.8937 10.5378 35.0542 10.6485 35.2147 10.756C23.4409 14.3752 12.1037 18.0184 1.86105 23.2922C-8.26767 28.5098 -17.297 36.1316 -25.8144 46.2266L-25.8079 46.2218Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.774C-25.8255 45.6264 -25.8367 45.4771 -25.8416 45.3278C-17.5392 35.1301 -8.71856 27.4842 1.25448 22.3276C11.3318 17.1148 22.5583 13.5936 34.247 10.1301L34.7285 10.4399C23.0125 13.9836 11.7154 17.5706 1.56423 22.8091C-8.48905 27.993 -17.4173 35.6325 -25.8255 45.774Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8748C-25.7453 47.7288 -25.7502 47.5795 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C36.5597 11.7832 36.7089 11.9003 36.855 12.0095C24.9448 15.8693 13.4342 19.7661 2.93476 25.1314C-7.46679 30.4469 -16.8476 37.9708 -25.7309 47.8748Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2326C-25.6105 49.085 -25.6298 48.9389 -25.6394 48.8009C-16.5571 39.0237 -6.98209 31.5335 3.57032 26.1714C14.2158 20.7467 25.8227 16.7312 37.7906 12.7301L38.2384 13.0816C26.2303 17.1437 14.5769 21.2234 3.86242 26.6593C-6.76061 32.047 -16.4303 39.5132 -25.6105 49.2326Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.4771C-1.12893 8.16895 -0.326466 7.86562 0.508097 7.61365C10.815 17.5113 19.5329 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C42.6038 78.5371 41.4595 79.1646 40.32 79.7825C35.0622 67.1453 29.5509 54.4696 22.7685 42.3877C15.8971 30.1523 7.60656 18.7702 -1.9314 8.4771Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47711C-1.82066 8.43057 -1.70992 8.38563 -1.61041 8.35193C8.01522 18.5652 16.3682 29.907 23.266 42.1293C30.0821 54.208 35.5806 66.8869 40.7951 79.5258C40.6346 79.6156 40.4741 79.7023 40.3136 79.7825C35.0558 67.1453 29.5445 54.4696 22.7621 42.3877C15.8927 30.1527 7.60431 18.7705 -1.9314 8.47711Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93927C-3.03721 8.89433 -2.93128 8.86063 -2.82696 8.81409C6.46005 19.2953 14.5676 30.765 21.3497 43.0167C28.0663 55.1532 33.6306 67.8097 39.0039 80.4212C38.8515 80.5015 38.6829 80.5817 38.5225 80.662C33.1138 68.0601 27.5191 55.418 20.8329 43.2671C14.0861 31.0022 6.04672 19.4942 -3.14795 8.93927Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32154C1.55612 7.29104 1.66847 7.25413 1.79044 7.2124C12.4375 16.8564 21.3931 27.7266 28.4564 39.7026C35.4506 51.563 40.7212 64.2356 45.4686 76.9225C45.3242 77.0172 45.1669 77.1087 45.0128 77.2066C40.2141 64.5164 34.9226 51.8487 27.9364 39.953C20.8891 27.9658 11.9946 17.0378 1.43896 7.32154Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21236C1.91455 7.18234 2.03148 7.14372 2.14516 7.0968C12.8709 16.6798 21.8954 27.5003 28.97 39.4426C35.9803 51.2837 41.2252 63.9514 45.9116 76.6352C45.7736 76.7267 45.6211 76.8246 45.4638 76.9161C40.7213 64.2371 35.4507 51.5582 28.4516 39.6977C21.3931 27.7266 12.4376 16.8564 1.79529 7.21236Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72118C0.277004 7.68105 0.394164 7.65056 0.506509 7.61365C10.815 17.5113 19.533 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C43.5876 77.9914 43.4272 78.0829 43.2667 78.1664C38.2914 65.4874 32.9052 52.8165 25.9944 40.8405C19.037 28.7763 10.372 17.691 0.155029 7.72118Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07902C-0.788642 8.0373 -0.674692 8.00038 -0.570371 7.95544C9.33431 17.9334 17.894 29.1617 24.8918 41.3556C31.7722 53.3701 37.1888 66.0539 42.2604 78.712C42.1079 78.8115 41.9555 78.8901 41.7966 78.9736C36.6785 66.3155 31.2233 53.6382 24.3751 41.6028C17.4115 29.4001 8.92095 18.1347 -0.891357 8.07902Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.3346 57.8832 -24.5272 56.8929 -24.7278 55.9059C-14.6062 47.4875 -3.51314 40.3111 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C45.3964 19.8367 46.2856 20.774 47.0993 21.7498C34.661 27.1054 22.3031 32.4049 10.311 38.1762C-1.61037 43.9139 -13.0808 50.6401 -24.1372 58.8622Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.1677 58.7226 -24.1966 58.583 -24.2223 58.4514C-13.2317 50.1587 -1.8271 43.3971 10.0494 37.6772C21.982 31.9106 34.3127 26.6577 46.7557 21.3406C46.8681 21.4802 46.9869 21.6102 47.0992 21.7514C34.661 27.1055 22.303 32.405 10.311 38.1763C-1.61043 43.9123 -13.0809 50.6402 -24.1372 58.8622Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3387C-23.8354 60.2023 -23.8707 60.0739 -23.9044 59.9439C-12.6523 51.9193 -0.997252 45.3069 11.0381 39.5452C23.1393 33.7515 35.539 28.3284 48.0029 22.8347C48.1152 22.976 48.2292 23.1156 48.3351 23.2424C35.8712 28.781 23.4587 34.2506 11.3158 40.046C-0.769354 45.8157 -12.4805 52.3815 -23.7985 60.3387Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7793C-24.9316 54.6365 -24.9524 54.4936 -24.9701 54.3588C-14.7403 45.3808 -4.05143 38.2806 7.30666 32.6714C18.753 27.0172 30.8269 22.257 43.1319 17.4775C43.2571 17.6043 43.3855 17.7391 43.4994 17.8611C31.1832 22.6984 19.0756 27.51 7.57628 33.1834C-3.83958 38.8006 -14.6103 45.8752 -24.9091 54.7793Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3587C-24.9965 54.2223 -25.0158 54.0847 -25.028 53.9463C-14.8832 44.8993 -4.27781 37.7589 7.03213 32.1593C18.4271 26.5212 30.4641 21.814 42.7627 17.0939C42.8879 17.2207 43.0098 17.3394 43.1318 17.4775C30.8268 22.2569 18.7529 27.0172 7.30657 32.6713C-4.05152 38.2806 -14.7404 45.3808 -24.9702 54.3587Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3023C-24.6732 56.1707 -24.6989 56.0391 -24.7278 55.8995C-14.6058 47.4833 -3.51282 40.3091 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C44.6245 19.0294 44.7496 19.1562 44.8748 19.283C32.4912 24.3177 20.2857 29.3122 8.6034 35.0257C-3.26448 40.8096 -14.422 47.9494 -24.6459 56.3023Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5783C-24.4181 57.4467 -24.455 57.3103 -24.4742 57.1755C-13.7212 48.6693 -2.52201 41.797 9.20521 36.0995C20.995 30.3683 33.2519 25.2566 45.6644 20.1112C45.7816 20.2412 45.91 20.376 46.0335 20.4948C33.6146 25.6948 21.3401 30.8546 9.49089 36.597C-2.28448 42.3058 -13.5479 49.138 -24.3924 57.5783Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061C-7.48127 10.9872 -6.75905 10.5828 -5.98708 10.2249C2.31446 21.3574 9.72289 33.1287 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C32.8843 83.2123 31.6694 83.7355 30.424 84.2089C24.4231 71.8943 18.6117 59.4465 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061L-7.89855 11.2279C-0.236618 22.6695 6.65816 34.6406 12.919 46.944C19.1606 59.2105 24.964 71.6824 30.9327 84.0179C30.7722 84.0901 30.6021 84.1543 30.424 84.2169C24.4231 71.8942 18.6117 59.4464 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0416C-9.19845 11.9726 -9.09894 11.9245 -9.00265 11.8667C-1.74355 23.4719 4.86876 35.5362 10.9916 47.8171C17.1144 60.098 22.9451 72.4817 29.0294 84.7177C28.8689 84.7851 28.6972 84.8525 28.5206 84.9167C22.401 72.6935 16.5575 60.3243 10.4716 48.0595C4.38568 35.7946 -2.14318 23.6886 -9.29956 12.0416Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81876C-5.03371 9.7658 -4.93099 9.72407 -4.83148 9.67432C3.85406 20.5774 11.53 32.2478 18.1014 44.5414C24.6527 56.7549 30.3277 69.3649 35.953 81.9058C35.7797 81.9764 35.624 82.0486 35.4507 82.1193C29.7933 69.6152 24.1102 57.0053 17.5814 44.7806C11.0387 32.4878 3.43563 20.7895 -5.14124 9.81876Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.6743C-4.73036 9.62134 -4.62123 9.5764 -4.5169 9.52344C4.25997 20.3672 12.0033 32.0082 18.6117 44.2942C25.192 56.4917 30.8477 69.1145 36.4265 81.6554C36.266 81.7469 36.1151 81.8159 35.945 81.9058C30.3277 69.3632 24.6527 56.7549 18.1014 44.5414C11.5279 32.2476 3.84988 20.5771 -4.83789 9.6743Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3725C-6.20163 10.3154 -6.09434 10.266 -5.98382 10.2249C2.31661 21.3575 9.72397 33.1288 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C33.9276 82.7693 33.7575 82.8479 33.6002 82.9121C27.8016 70.4595 22.0623 57.9105 15.6474 45.6601C9.23325 33.3592 1.89477 21.5628 -6.30481 10.3725Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8652L-6.96765 10.687C1.01468 22.0069 8.20119 33.8675 14.54 46.1833C20.8779 58.4417 26.6572 70.9618 32.5233 83.3775C32.3628 83.4449 32.1878 83.5236 32.0209 83.591C26.118 71.1913 20.3274 58.7017 14.0152 46.424C7.68857 34.127 0.626882 22.1895 -7.27258 10.8652Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6814C-22.3027 65.757 -22.5948 64.8165 -22.8837 63.884C-10.9911 56.6153 1.2593 50.4203 13.6927 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.9301 27.9497 52.6668 28.9672 53.3232 30.0072C40.8626 36.276 28.2959 42.4133 15.6074 48.2745C2.93966 54.1229 -9.70238 59.9712 -22.0122 66.6766L-22.017 66.6814Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6814C-22.0556 66.5642 -22.0941 66.4278 -22.139 66.2946C-9.87898 59.5122 2.7117 53.6044 15.3521 47.7577C28.0182 41.8981 40.5864 35.8057 53.0535 29.5786C53.1402 29.7215 53.2365 29.8691 53.3232 30.0119C40.8625 36.2808 28.2959 42.4181 15.6073 48.2793C2.9396 54.1276 -9.70243 59.976 -22.0122 66.6814H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1066C-21.5901 67.9766 -21.6399 67.8546 -21.6848 67.7214C-9.23056 61.2343 3.54308 55.4999 16.2846 49.6563C29.055 43.7999 41.6233 37.5551 54.0695 31.1852C54.1562 31.3296 54.2508 31.4693 54.3359 31.6121C41.9121 38.0318 29.3391 44.3119 16.5478 50.1635C3.75654 56.015 -9.03636 61.6885 -21.5356 68.1066Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8088C-23.2079 62.6884 -23.2368 62.5488 -23.2737 62.4123C-11.6042 54.8692 0.437553 48.5072 12.7329 42.7006C25.0732 36.8747 37.5612 31.1804 50.0684 25.3866C50.1727 25.531 50.2674 25.6707 50.3685 25.8071C37.8709 31.6426 25.3621 37.377 12.9945 43.211C0.657429 49.0224 -11.4486 55.3314 -23.1758 62.8088Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4123C-23.3075 62.2919 -23.3444 62.1555 -23.3749 62.0159C-11.7728 54.3892 0.21759 47.9872 12.4632 42.1854C24.7843 36.3514 37.261 30.7165 49.7473 24.9773C49.8468 25.1073 49.9512 25.2501 50.0539 25.3866C37.5627 31.1803 25.0731 36.8746 12.7328 42.7005C0.437466 48.5072 -11.6043 54.8643 -23.277 62.4123Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2628C-22.8082 64.1376 -22.8451 64.0012 -22.8837 63.884C-10.9911 56.6153 1.25929 50.4203 13.6911 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.285 27.0718 51.3909 27.2162 51.4952 27.359C39.0056 33.3508 26.4963 39.2591 13.9671 45.0839C1.49683 50.905 -10.813 57.0647 -22.7665 64.2564V64.2628Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4793C-22.4343 65.3477 -22.4792 65.2145 -22.5129 65.0861C-10.4374 58.0597 1.99113 52.0011 14.5369 46.1672C27.1131 40.3188 39.6481 34.3464 52.142 28.2498C52.2479 28.3942 52.349 28.5306 52.4517 28.667C39.975 34.8096 27.4277 40.8137 14.8097 46.6792C2.23026 52.5179 -10.2545 58.5252 -22.3894 65.4793Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.2028 14.8036 -12.5528 14.2531 -11.8659 13.7668C-5.71258 25.7797 0.0747986 38.0013 5.82687 50.226C11.5645 62.4235 17.4674 74.5584 23.8486 86.4975C22.5647 86.8976 21.2807 87.2689 19.9968 87.6113C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.8871 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3542C-13.7661 15.274 -13.6762 15.1937 -13.5815 15.1183C-8.15368 27.3382 -2.89432 39.6208 2.59454 51.7428C8.0834 63.8648 13.9895 75.7734 20.5232 87.4717L19.9904 87.6098C13.423 75.9612 7.51044 64.0767 2.08096 51.9819C-3.34852 39.8872 -8.53084 27.587 -13.8464 15.3542H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2032C-14.758 16.1166 -14.6713 16.0428 -14.5815 15.9641C-9.59173 28.2836 -4.65978 40.5661 0.681424 52.6319C6.01356 64.7211 11.9749 76.5228 18.5411 87.9886C18.3581 88.0367 18.1752 88.0849 18.0083 88.125C11.4042 76.717 5.44385 64.9484 0.155007 52.8743C-5.12842 40.8373 -9.97531 28.5371 -14.8591 16.2032H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2308L-10.8195 13.0366C-4.23929 24.9035 1.85945 37.0881 7.75115 49.3417C13.6428 61.5954 19.5185 73.8266 25.8018 85.8844C25.6204 85.9406 25.4407 85.9967 25.277 86.0449C18.9696 74.0159 13.0795 61.8104 7.23115 49.5793C1.38278 37.3481 -4.64533 25.1474 -11.0988 13.2308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0367C-10.7264 12.9709 -10.6317 12.9067 -10.5225 12.8361C-3.85727 24.6772 2.34257 36.833 8.27921 49.0995C14.2158 61.3659 20.069 73.6276 26.3299 85.7127C26.1533 85.7769 25.9736 85.8331 25.8018 85.8844C19.5185 73.8266 13.6365 61.5746 7.75119 49.3418C1.86591 37.109 -4.24566 24.9035 -10.8195 13.0367Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9643L-11.8659 13.7637C-5.71261 25.7766 0.0747623 37.9981 5.82683 50.2229C11.5645 62.4203 17.4674 74.5552 23.8486 86.4943L23.3158 86.6548C16.9025 74.751 11.0043 62.6466 5.30363 50.4572C-0.397086 38.2677 -6.11706 26.0077 -12.1532 13.9643Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6255C-12.9131 14.5591 -12.8262 14.4856 -12.7454 14.4056C-6.94355 26.5325 -1.40173 38.8006 4.21553 50.9724C9.83278 63.1442 15.7405 75.1843 22.2052 87.0079L21.6724 87.1684C15.1788 75.393 9.27266 63.3737 3.70195 51.2275C-1.8816 39.0558 -7.33194 26.7797 -13.0054 14.6255Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.4765 73.2585 -19.8729 72.379 -20.2677 71.4802C-7.33838 65.7955 5.85254 60.5779 18.8332 54.7424C31.8139 48.9069 44.3516 42.3218 56.6373 35.5522C57.2263 36.6292 57.8057 37.7109 58.3434 38.8006C46.2165 45.8254 33.7542 52.6624 20.6805 58.4514C7.58265 64.25 -5.86185 69.0937 -19.0817 74.1572Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1573C-19.1347 74.0289 -19.1956 73.9021 -19.2422 73.7865C-6.05769 68.6395 7.35471 63.7365 20.4413 57.933C33.5038 52.1409 45.9725 45.3344 58.1299 38.3529L58.3433 38.8087C46.2165 45.8255 33.7542 52.6625 20.6804 58.4498C7.58261 64.2501 -5.86189 69.0937 -19.0817 74.1573Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5054C-18.5199 75.3866 -18.5857 75.2711 -18.6467 75.1443C-5.32581 70.3215 8.20375 65.6238 21.3273 59.8413C34.4187 54.0732 46.8456 47.1623 58.9003 40.0413C58.9597 40.1857 59.0335 40.343 59.1073 40.5003C47.08 47.6502 34.6787 54.5948 21.5824 60.3517C8.43807 66.1294 -5.10433 70.7837 -18.4622 75.5054Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4611C-20.701 70.3311 -20.7395 70.2123 -20.7925 70.0823C-8.03008 64.1007 5.00838 58.6745 17.9168 52.8373C30.8253 47.0002 43.3887 40.5211 55.7434 33.8992C55.8221 34.0452 55.9039 34.1881 55.989 34.3421C43.6487 41.0138 31.0933 47.5154 18.164 53.3509C5.23467 59.1864 -7.83588 64.5565 -20.648 70.4611Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0823C-20.8455 69.9539 -20.8888 69.8288 -20.9386 69.7052C-8.22113 63.6353 4.78201 58.1657 17.6696 52.3238C30.5572 46.4818 43.127 40.0397 55.5026 33.4514L55.7433 33.904C43.3854 40.5211 30.8268 47.0002 17.9168 52.8422C5.0067 58.6841 -8.03015 64.1008 -20.7926 70.0823Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8445C-20.1569 71.729 -20.2179 71.6022 -20.2709 71.4802C-7.33835 65.7955 5.85257 60.5795 18.8332 54.7424C31.8139 48.9053 44.3516 42.3218 56.6374 35.5522C56.716 35.6983 56.7979 35.8524 56.8861 36.0032C44.6116 42.8001 32.0803 49.4285 19.0932 55.2512C6.10615 61.0738 -7.13613 66.2449 -20.1104 71.8445Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9953C-19.6434 72.8765 -19.6963 72.7465 -19.7461 72.6357C-6.68196 67.2159 6.60846 62.1507 19.6517 56.3297C32.6693 50.5198 45.1701 43.8112 57.4045 36.9453C57.4799 37.0833 57.565 37.2374 57.642 37.3915C45.4446 44.2927 32.9438 51.0334 19.9069 56.84C6.87007 62.6467 -6.47814 67.6669 -19.5744 72.9953H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.705C-18.3353 19.9844 -17.7431 19.2445 -17.1621 18.5255C-13.3552 31.044 -9.32526 43.28 -4.4559 55.0714C0.394197 66.8162 6.29713 78.1631 13.1309 89.1761C11.8053 89.4249 10.4587 89.6271 9.12983 89.8181C2.19011 79.2673 -3.68072 68.349 -8.17773 56.8978C-12.7004 45.3904 -16.0387 33.278 -18.9436 20.705Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7051C-18.8633 20.6027 -18.777 20.5051 -18.6852 20.413C-15.6615 32.9731 -12.2238 45.108 -7.66416 56.6491C-3.1222 68.1436 2.74703 79.1181 9.67711 89.7411C9.50218 89.7684 9.31761 89.7909 9.12823 89.823C2.19011 79.2722 -3.68072 68.3538 -8.17773 56.9027C-12.6972 45.3905 -16.0419 33.2781 -18.9436 20.7051Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9489C-19.7396 21.8398 -19.6546 21.7178 -19.5807 21.5958C-17.0209 34.1769 -13.9314 46.2187 -9.55154 57.6137C-5.20057 68.9364 0.651003 79.6943 7.63887 90.0477C7.46393 90.0734 7.27134 90.0991 7.08517 90.1135C0.074831 79.8419 -5.75427 69.1547 -10.0619 57.8769C-14.384 46.5364 -17.3788 34.5107 -19.8151 21.9489Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.821C-16.3757 17.7295 -16.2906 17.6428 -16.2072 17.5546C-11.9605 29.9992 -7.59029 42.2705 -2.55242 54.1662C2.51942 66.1027 8.43297 77.6638 15.1435 88.7621L14.5995 88.8744C7.87557 77.8359 1.97483 66.3166 -3.05476 54.4102C-8.04609 42.5465 -12.3329 30.2881 -16.4607 17.8178L-16.4672 17.821Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5546L-15.9553 17.2914C-11.5851 29.72 -7.12819 42.0073 -2.03253 53.9271C3.08934 65.8872 9.00992 77.4892 15.6891 88.6546C15.519 88.6883 15.3328 88.73 15.1434 88.7621C8.43285 77.6638 2.51931 66.1028 -2.55253 54.1663C-7.59041 42.2689 -11.9606 29.9992 -16.2073 17.5546Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.8192C-17.3243 18.7229 -17.244 18.6202 -17.1654 18.5255C-13.3585 31.044 -9.32853 43.28 -4.45918 55.0714C0.390923 66.8179 6.29386 78.1631 13.1276 89.1761C12.9415 89.2163 12.7633 89.2532 12.582 89.2837C5.73694 78.3284 -0.157963 67.0474 -4.96473 55.3217C-9.77952 43.5737 -13.7325 31.3457 -17.4045 18.8192Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.7612C-18.0817 19.6649 -18.0015 19.5622 -17.9229 19.4675C-14.506 32.0004 -10.7777 44.1995 -6.06081 55.8673C-1.35837 67.4951 4.53172 78.6573 11.4201 89.4826C11.2275 89.5067 11.0381 89.5404 10.8648 89.5661C3.96358 78.813 -1.91689 67.7053 -6.56958 56.1113C-11.2399 44.482 -14.8671 32.3086 -18.162 19.7612Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3863C-17.5153 77.2676 -17.5811 77.152 -17.6421 77.0252C-4.16068 72.6726 9.50049 68.2719 22.6689 62.5279C35.7844 56.8079 48.1039 49.7366 59.9868 42.439C60.0606 42.5995 60.1312 42.7455 60.1938 42.8996C48.3334 50.2309 36.0412 57.3263 22.9241 63.035C9.75246 68.771 -3.9408 73.1156 -17.4575 77.3831V77.3863Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89643C13.614 4.76161 14.6058 4.64124 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C59.836 66.5514 58.8778 67.3619 57.9213 68.1804C54.8639 55.739 50.5932 43.4292 43.3951 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89643Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89645C12.7652 4.88655 12.9029 4.86941 13.0394 4.84509C26.1132 12.0962 36.5645 21.2154 43.9135 32.2894C51.1051 43.1339 55.3357 55.4116 58.3225 67.837C58.1749 67.9509 58.0497 68.0729 57.9181 68.1804C54.8607 55.7406 50.59 43.4292 43.3919 32.551C36.0461 21.4433 25.6397 12.2454 12.627 4.89645Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.0971C11.2789 5.08747 11.4169 5.0666 11.5485 5.04895C24.3606 12.6403 34.6611 22.0115 42.0085 33.2203C49.2194 44.2189 53.5944 56.5865 56.838 69.076C56.7064 69.1836 56.5636 69.2863 56.4239 69.397C53.1178 56.8882 48.6898 44.5029 41.4804 33.4626C34.1347 22.2137 23.8808 12.7671 11.144 5.0971Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56581C15.3203 4.54054 15.4587 4.52552 15.5977 4.52087C29.1208 11.2054 39.7968 19.9025 47.1426 30.7214C54.307 41.2738 58.2455 53.4215 60.7845 65.7361C60.6561 65.8517 60.5213 65.9512 60.3961 66.0731C57.772 53.7409 53.7966 41.5755 46.6274 30.9685C39.2864 20.108 28.6377 11.3563 15.1836 4.56581Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.7344C14.0361 4.71353 14.1741 4.69267 14.317 4.67822C27.609 11.6532 38.1919 20.5574 45.5281 31.5062C52.7053 42.2063 56.7898 54.4166 59.5535 66.7906C59.4219 66.8982 59.2935 67.0137 59.1539 67.1228C56.31 54.7296 52.1933 42.492 45.0113 31.7518C37.6671 20.7644 27.1291 11.7881 13.8965 4.7344Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8384 40.5339 -25.7822 39.3623 -25.6763 38.1779C-19.1747 27.3623 -12.1082 19.6121 -3.52824 15.0557C5.16246 10.4351 15.3746 8.10312 26.2111 5.86584C27.6 6.41955 28.9596 7.04416 30.2844 7.73719C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3708 -18.4461 31.1114 -25.8608 41.6782V41.6734Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6734C-25.8608 41.513 -25.8608 41.3621 -25.8608 41.192C-18.5616 30.5994 -10.7055 22.854 -1.50282 18.0104C7.81541 13.1073 18.4994 10.2457 29.7355 7.45312C29.9249 7.55424 30.0999 7.64411 30.2844 7.7372C18.9857 10.6004 8.23429 13.5422 -1.18183 18.4902C-10.4776 23.3676 -18.4461 31.1082 -25.8608 41.6734Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4003C-25.901 43.2398 -25.8914 43.0793 -25.8898 42.9333C-18.154 32.4772 -9.8838 24.7591 -0.342491 19.7726C9.31919 14.7267 20.2439 11.5826 31.6919 8.49792C31.8524 8.59262 32.0129 8.70336 32.1734 8.80607C20.6853 11.963 9.69153 15.1841 -0.0407619 20.2637C-9.67195 25.2919 -18.0529 32.9972 -25.901 43.4003Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8393C-25.5592 36.6692 -25.5335 36.4991 -25.5159 36.3402C-19.5182 25.4267 -12.9605 17.6989 -4.77857 13.3383C3.50287 8.92318 13.3957 6.93467 23.9641 5.0553C24.1583 5.12752 24.3654 5.20295 24.5676 5.27196C13.9333 7.25726 3.95707 9.33244 -4.44795 13.8086C-12.7246 18.2157 -19.422 25.9483 -25.5592 36.8393Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.3402C-25.487 36.1588 -25.4677 35.9823 -25.4292 35.8058C-19.5857 24.8826 -13.166 17.1789 -5.11883 12.889C3.04384 8.53318 12.8339 6.63615 23.3109 4.8595C23.5332 4.90905 23.7516 4.97504 23.9641 5.0569C13.3956 6.93467 3.50285 8.92318 -4.77859 13.3383C-12.9637 17.6989 -19.5183 25.4186 -25.5143 36.3402Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6674C-25.7004 38.5069 -25.6908 38.3464 -25.6763 38.1859C-19.1747 27.3703 -12.1082 19.6201 -3.52827 15.0637C5.16242 10.4351 15.3746 8.10312 26.2111 5.86584L26.8274 6.08572C15.9139 8.4241 5.61983 10.8524 -3.18482 15.5163C-11.8819 20.1273 -19.0736 27.8871 -25.7164 38.6674Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1953C-25.8336 40.0348 -25.824 39.8839 -25.8095 39.7138C-18.8762 28.9961 -11.4005 21.2282 -2.49312 16.5194C6.5298 11.7479 17.0052 9.13668 28.0567 6.61694L28.5928 6.88818C17.4722 9.48977 6.92622 12.1844 -2.18657 16.9992C-11.2015 21.753 -18.7832 29.5113 -25.8448 40.1953Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34092C5.87818 6.13228 6.76249 5.92363 7.67891 5.73425C19.7255 14.1457 29.5702 24.1508 36.8565 35.6999C44.0338 47.0788 48.8117 59.6134 52.6779 72.2281C51.6283 72.9519 50.5626 73.6806 49.513 74.4044C45.2295 61.7335 40.2157 49.1219 33.0962 37.5038C25.8949 25.7509 16.4338 15.3398 4.99707 6.34092Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34089C5.11584 6.31842 5.23781 6.2783 5.35497 6.2478C16.8719 15.1696 26.3924 25.5182 33.6017 37.2502C40.7292 48.8362 45.7157 61.4333 49.9431 74.0994L49.505 74.4044C45.2214 61.7334 40.2076 49.1219 33.0882 37.5038C25.8949 25.7509 16.4338 15.3397 4.99707 6.34089Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67307C3.76459 6.63937 3.88335 6.61851 4.00051 6.58801C15.2045 15.7794 24.5324 26.3367 31.6952 38.1586C38.7842 49.8585 43.8798 62.4925 48.3062 75.1747C48.1457 75.2726 48.0045 75.3609 47.8488 75.4507C43.3662 62.7718 38.2433 50.1346 31.1672 38.4009C24.0204 26.5485 14.76 15.9367 3.6394 6.67307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54326C8.85533 5.52079 8.98051 5.48869 9.1073 5.46301C21.4412 13.5872 31.4511 23.3548 38.776 34.7883C45.9709 46.0228 50.5963 58.5043 54.2427 71.079C54.1031 71.1881 53.9699 71.2876 53.8303 71.4C50.1245 58.8141 45.4574 46.3181 38.2625 35.0371C30.9504 23.573 20.9677 13.7364 8.7157 5.54165V5.54326Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46305C9.2389 5.4454 9.36088 5.43095 9.48927 5.40527C21.9002 13.4299 31.9695 23.1414 39.2896 34.5283C46.4909 45.7323 51.0891 58.1898 54.6536 70.7452C54.514 70.8543 54.3824 70.9618 54.2427 71.0662C50.5963 58.498 45.9709 46.0228 38.776 34.7883C31.4511 23.3548 21.4412 13.5872 9.1073 5.46305Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80808C7.43174 5.7824 7.55692 5.7487 7.6789 5.73425C19.7255 14.1457 29.5701 24.1508 36.8565 35.6999C44.0338 47.0789 48.8116 59.6134 52.6779 72.2281C52.5367 72.3308 52.3826 72.4287 52.2478 72.5282C48.3221 59.899 43.5138 47.3565 36.3365 35.9358C29.0598 24.3563 19.2537 14.3094 7.30334 5.80808Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06968C6.25536 6.04079 6.38055 6.00709 6.50092 5.98462C18.2827 14.6512 27.9733 24.8249 35.2292 36.4655C42.3871 47.9584 47.2661 60.5298 51.317 73.1702C51.1709 73.2649 51.0297 73.3676 50.8756 73.4655C46.7847 60.8154 41.8591 48.2408 34.7044 36.7126C27.4661 25.0528 17.8365 14.8101 6.1366 6.06647L6.13981 6.06968Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.5688 49.5359 -25.6731 48.4895 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C37.4777 12.4942 38.5562 13.3287 39.5673 14.1793C27.4613 18.4388 15.6924 22.7047 4.76599 28.208C-6.05445 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5759C-25.4885 50.4299 -25.5078 50.2951 -25.5206 50.149C-16.143 40.5531 -6.28073 33.1368 4.49156 27.7057C15.3457 22.2184 27.0842 18.0135 39.1548 13.8198C39.2816 13.9274 39.418 14.059 39.5593 14.1793C27.4613 18.4388 15.6924 22.7047 4.766 28.208C-6.05444 33.6648 -16.0082 41.0475 -25.4757 50.5759Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1521C-25.3008 52.022 -25.3297 51.8792 -25.3522 51.7364C-15.6488 42.3732 -5.47351 35.0579 5.51705 29.5546C16.6023 24.0032 28.4804 19.5816 40.6458 15.1616C40.779 15.2852 40.9202 15.4056 41.0534 15.5292C28.8672 20.0101 16.957 24.4831 5.81075 30.049C-5.24401 35.5699 -15.5091 42.8499 -25.3008 52.1521Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2218C-25.8192 46.0838 -25.8272 45.9265 -25.8256 45.774C-17.4174 35.6325 -8.49236 27.993 1.56895 22.8043C11.7201 17.5658 23.0108 13.9771 34.7332 10.4351C34.8937 10.5378 35.0542 10.6485 35.2147 10.756C23.4409 14.3752 12.1037 18.0184 1.86105 23.2922C-8.26767 28.5098 -17.297 36.1316 -25.8144 46.2266L-25.8079 46.2218Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.774C-25.8255 45.6264 -25.8367 45.4771 -25.8416 45.3278C-17.5392 35.1301 -8.71856 27.4842 1.25448 22.3276C11.3318 17.1148 22.5583 13.5936 34.247 10.1301L34.7285 10.4399C23.0125 13.9836 11.7154 17.5706 1.56423 22.8091C-8.48905 27.993 -17.4173 35.6325 -25.8255 45.774Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8748C-25.7453 47.7288 -25.7502 47.5795 -25.7694 47.4447C-16.9856 37.4765 -7.6979 29.9317 2.63785 24.6403C13.0699 19.2959 24.5371 15.4681 36.4104 11.666C36.5597 11.7832 36.7089 11.9003 36.855 12.0095C24.9448 15.8693 13.4342 19.7661 2.93476 25.1314C-7.46679 30.4469 -16.8476 37.9708 -25.7309 47.8748Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2326C-25.6105 49.085 -25.6298 48.9389 -25.6394 48.8009C-16.5571 39.0237 -6.98209 31.5335 3.57032 26.1714C14.2158 20.7467 25.8227 16.7312 37.7906 12.7301L38.2384 13.0816C26.2303 17.1437 14.5769 21.2234 3.86242 26.6593C-6.76061 32.047 -16.4303 39.5132 -25.6105 49.2326Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.4771C-1.12893 8.16895 -0.326466 7.86562 0.508097 7.61365C10.815 17.5113 19.5329 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C42.6038 78.5371 41.4595 79.1646 40.32 79.7825C35.0622 67.1453 29.5509 54.4696 22.7685 42.3877C15.8971 30.1523 7.60656 18.7702 -1.9314 8.4771Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47711C-1.82066 8.43057 -1.70992 8.38563 -1.61041 8.35193C8.01522 18.5652 16.3682 29.907 23.266 42.1293C30.0821 54.208 35.5806 66.8869 40.7951 79.5258C40.6346 79.6156 40.4741 79.7023 40.3136 79.7825C35.0558 67.1453 29.5445 54.4696 22.7621 42.3877C15.8927 30.1527 7.60431 18.7705 -1.9314 8.47711Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93927C-3.03721 8.89433 -2.93128 8.86063 -2.82696 8.81409C6.46005 19.2953 14.5676 30.765 21.3497 43.0167C28.0663 55.1532 33.6306 67.8097 39.0039 80.4212C38.8515 80.5015 38.6829 80.5817 38.5225 80.662C33.1138 68.0601 27.5191 55.418 20.8329 43.2671C14.0861 31.0022 6.04672 19.4942 -3.14795 8.93927Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32154C1.55612 7.29104 1.66847 7.25413 1.79044 7.2124C12.4375 16.8564 21.3931 27.7266 28.4564 39.7026C35.4506 51.563 40.7212 64.2356 45.4686 76.9225C45.3242 77.0172 45.1669 77.1087 45.0128 77.2066C40.2141 64.5164 34.9226 51.8487 27.9364 39.953C20.8891 27.9658 11.9946 17.0378 1.43896 7.32154Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21236C1.91455 7.18234 2.03148 7.14372 2.14516 7.0968C12.8709 16.6798 21.8954 27.5003 28.97 39.4426C35.9803 51.2837 41.2252 63.9514 45.9116 76.6352C45.7736 76.7267 45.6211 76.8246 45.4638 76.9161C40.7213 64.2371 35.4507 51.5582 28.4516 39.6977C21.3931 27.7266 12.4376 16.8564 1.79529 7.21236Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72118C0.277004 7.68105 0.394164 7.65056 0.506509 7.61365C10.815 17.5113 19.533 28.5628 26.5224 40.6094C33.4493 52.5517 38.8178 65.2371 43.7481 77.9096C43.5876 77.9914 43.4272 78.0829 43.2667 78.1664C38.2914 65.4874 32.9052 52.8165 25.9944 40.8405C19.037 28.7763 10.372 17.691 0.155029 7.72118Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07902C-0.788642 8.0373 -0.674692 8.00038 -0.570371 7.95544C9.33431 17.9334 17.894 29.1617 24.8918 41.3556C31.7722 53.3701 37.1888 66.0539 42.2604 78.712C42.1079 78.8115 41.9555 78.8901 41.7966 78.9736C36.6785 66.3155 31.2233 53.6382 24.3751 41.6028C17.4115 29.4001 8.92095 18.1347 -0.891357 8.07902Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.3346 57.8832 -24.5272 56.8929 -24.7278 55.9059C-14.6062 47.4875 -3.51314 40.3111 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C45.3964 19.8367 46.2856 20.774 47.0993 21.7498C34.661 27.1054 22.3031 32.4049 10.311 38.1762C-1.61037 43.9139 -13.0808 50.6401 -24.1372 58.8622Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8622C-24.1677 58.7226 -24.1966 58.583 -24.2223 58.4514C-13.2317 50.1587 -1.8271 43.3971 10.0494 37.6772C21.982 31.9106 34.3127 26.6577 46.7557 21.3406C46.8681 21.4802 46.9869 21.6102 47.0992 21.7514C34.661 27.1055 22.303 32.405 10.311 38.1763C-1.61043 43.9123 -13.0809 50.6402 -24.1372 58.8622Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3387C-23.8354 60.2023 -23.8707 60.0739 -23.9044 59.9439C-12.6523 51.9193 -0.997252 45.3069 11.0381 39.5452C23.1393 33.7515 35.539 28.3284 48.0029 22.8347C48.1152 22.976 48.2292 23.1156 48.3351 23.2424C35.8712 28.781 23.4587 34.2506 11.3158 40.046C-0.769354 45.8157 -12.4805 52.3815 -23.7985 60.3387Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7793C-24.9316 54.6365 -24.9524 54.4936 -24.9701 54.3588C-14.7403 45.3808 -4.05143 38.2806 7.30666 32.6714C18.753 27.0172 30.8269 22.257 43.1319 17.4775C43.2571 17.6043 43.3855 17.7391 43.4994 17.8611C31.1832 22.6984 19.0756 27.51 7.57628 33.1834C-3.83958 38.8006 -14.6103 45.8752 -24.9091 54.7793Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3587C-24.9965 54.2223 -25.0158 54.0847 -25.028 53.9463C-14.8832 44.8993 -4.27781 37.7589 7.03213 32.1593C18.4271 26.5212 30.4641 21.814 42.7627 17.0939C42.8879 17.2207 43.0098 17.3394 43.1318 17.4775C30.8268 22.2569 18.7529 27.0172 7.30657 32.6713C-4.05152 38.2806 -14.7404 45.3808 -24.9702 54.3587Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3023C-24.6732 56.1707 -24.6989 56.0391 -24.7278 55.8995C-14.6058 47.4833 -3.51282 40.3091 8.31451 34.5298C19.9567 28.8291 32.1461 23.8747 44.5057 18.8994C44.6245 19.0294 44.7496 19.1562 44.8748 19.283C32.4912 24.3177 20.2857 29.3122 8.6034 35.0257C-3.26448 40.8096 -14.422 47.9494 -24.6459 56.3023Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5783C-24.4181 57.4467 -24.455 57.3103 -24.4742 57.1755C-13.7212 48.6693 -2.52201 41.797 9.20521 36.0995C20.995 30.3683 33.2519 25.2566 45.6644 20.1112C45.7816 20.2412 45.91 20.376 46.0335 20.4948C33.6146 25.6948 21.3401 30.8546 9.49089 36.597C-2.28448 42.3058 -13.5479 49.138 -24.3924 57.5783Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061C-7.48127 10.9872 -6.75905 10.5828 -5.98708 10.2249C2.31446 21.3574 9.72289 33.1287 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C32.8843 83.2123 31.6694 83.7355 30.424 84.2089C24.4231 71.8943 18.6117 59.4465 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4061L-7.89855 11.2279C-0.236618 22.6695 6.65816 34.6406 12.919 46.944C19.1606 59.2105 24.964 71.6824 30.9327 84.0179C30.7722 84.0901 30.6021 84.1543 30.424 84.2169C24.4231 71.8942 18.6117 59.4464 12.399 47.1832C6.17668 34.8926 -0.637851 22.8861 -8.20349 11.4061Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0416C-9.19845 11.9726 -9.09894 11.9245 -9.00265 11.8667C-1.74355 23.4719 4.86876 35.5362 10.9916 47.8171C17.1144 60.098 22.9451 72.4817 29.0294 84.7177C28.8689 84.7851 28.6972 84.8525 28.5206 84.9167C22.401 72.6935 16.5575 60.3243 10.4716 48.0595C4.38568 35.7946 -2.14318 23.6886 -9.29956 12.0416Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81876C-5.03371 9.7658 -4.93099 9.72407 -4.83148 9.67432C3.85406 20.5774 11.53 32.2478 18.1014 44.5414C24.6527 56.7549 30.3277 69.3649 35.953 81.9058C35.7797 81.9764 35.624 82.0486 35.4507 82.1193C29.7933 69.6152 24.1102 57.0053 17.5814 44.7806C11.0387 32.4878 3.43563 20.7895 -5.14124 9.81876Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.6743C-4.73036 9.62134 -4.62123 9.5764 -4.5169 9.52344C4.25997 20.3672 12.0033 32.0082 18.6117 44.2942C25.192 56.4917 30.8477 69.1145 36.4265 81.6554C36.266 81.7469 36.1151 81.8159 35.945 81.9058C30.3277 69.3632 24.6527 56.7549 18.1014 44.5414C11.5279 32.2476 3.84988 20.5771 -4.83789 9.6743Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3725C-6.20163 10.3154 -6.09434 10.266 -5.98382 10.2249C2.31661 21.3575 9.72397 33.1288 16.169 45.429C22.6112 57.6666 28.3424 70.23 34.1009 82.6971C33.9276 82.7693 33.7575 82.8479 33.6002 82.9121C27.8016 70.4595 22.0623 57.9105 15.6474 45.6601C9.23325 33.3592 1.89477 21.5628 -6.30481 10.3725Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8652L-6.96765 10.687C1.01468 22.0069 8.20119 33.8675 14.54 46.1833C20.8779 58.4417 26.6572 70.9618 32.5233 83.3775C32.3628 83.4449 32.1878 83.5236 32.0209 83.591C26.118 71.1913 20.3274 58.7017 14.0152 46.424C7.68857 34.127 0.626882 22.1895 -7.27258 10.8652Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6814C-22.3027 65.757 -22.5948 64.8165 -22.8837 63.884C-10.9911 56.6153 1.2593 50.4203 13.6927 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.9301 27.9497 52.6668 28.9672 53.3232 30.0072C40.8626 36.276 28.2959 42.4133 15.6074 48.2745C2.93966 54.1229 -9.70238 59.9712 -22.0122 66.6766L-22.017 66.6814Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6814C-22.0556 66.5642 -22.0941 66.4278 -22.139 66.2946C-9.87898 59.5122 2.7117 53.6044 15.3521 47.7577C28.0182 41.8981 40.5864 35.8057 53.0535 29.5786C53.1402 29.7215 53.2365 29.8691 53.3232 30.0119C40.8625 36.2808 28.2959 42.4181 15.6073 48.2793C2.9396 54.1276 -9.70243 59.976 -22.0122 66.6814H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1066C-21.5901 67.9766 -21.6399 67.8546 -21.6848 67.7214C-9.23056 61.2343 3.54308 55.4999 16.2846 49.6563C29.055 43.7999 41.6233 37.5551 54.0695 31.1852C54.1562 31.3296 54.2508 31.4693 54.3359 31.6121C41.9121 38.0318 29.3391 44.3119 16.5478 50.1635C3.75654 56.015 -9.03636 61.6885 -21.5356 68.1066Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8088C-23.2079 62.6884 -23.2368 62.5488 -23.2737 62.4123C-11.6042 54.8692 0.437553 48.5072 12.7329 42.7006C25.0732 36.8747 37.5612 31.1804 50.0684 25.3866C50.1727 25.531 50.2674 25.6707 50.3685 25.8071C37.8709 31.6426 25.3621 37.377 12.9945 43.211C0.657429 49.0224 -11.4486 55.3314 -23.1758 62.8088Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4123C-23.3075 62.2919 -23.3444 62.1555 -23.3749 62.0159C-11.7728 54.3892 0.21759 47.9872 12.4632 42.1854C24.7843 36.3514 37.261 30.7165 49.7473 24.9773C49.8468 25.1073 49.9512 25.2501 50.0539 25.3866C37.5627 31.1803 25.0731 36.8746 12.7328 42.7005C0.437466 48.5072 -11.6043 54.8643 -23.277 62.4123Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2628C-22.8082 64.1376 -22.8451 64.0012 -22.8837 63.884C-10.9911 56.6153 1.25929 50.4203 13.6911 44.5976C26.1742 38.7508 38.6975 32.8993 51.1871 26.9434C51.285 27.0718 51.3909 27.2162 51.4952 27.359C39.0056 33.3508 26.4963 39.2591 13.9671 45.0839C1.49683 50.905 -10.813 57.0647 -22.7665 64.2564V64.2628Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4793C-22.4343 65.3477 -22.4792 65.2145 -22.5129 65.0861C-10.4374 58.0597 1.99113 52.0011 14.5369 46.1672C27.1131 40.3188 39.6481 34.3464 52.142 28.2498C52.2479 28.3942 52.349 28.5306 52.4517 28.667C39.975 34.8096 27.4277 40.8137 14.8097 46.6792C2.23026 52.5179 -10.2545 58.5252 -22.3894 65.4793Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.2028 14.8036 -12.5528 14.2531 -11.8659 13.7668C-5.71258 25.7797 0.0747986 38.0013 5.82687 50.226C11.5645 62.4235 17.4674 74.5584 23.8486 86.4975C22.5647 86.8976 21.2807 87.2689 19.9968 87.6113C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.8871 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3542C-13.7661 15.274 -13.6762 15.1937 -13.5815 15.1183C-8.15368 27.3382 -2.89432 39.6208 2.59454 51.7428C8.0834 63.8648 13.9895 75.7734 20.5232 87.4717L19.9904 87.6098C13.423 75.9612 7.51044 64.0767 2.08096 51.9819C-3.34852 39.8872 -8.53084 27.587 -13.8464 15.3542H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2032C-14.758 16.1166 -14.6713 16.0428 -14.5815 15.9641C-9.59173 28.2836 -4.65978 40.5661 0.681424 52.6319C6.01356 64.7211 11.9749 76.5228 18.5411 87.9886C18.3581 88.0367 18.1752 88.0849 18.0083 88.125C11.4042 76.717 5.44385 64.9484 0.155007 52.8743C-5.12842 40.8373 -9.97531 28.5371 -14.8591 16.2032H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2308L-10.8195 13.0366C-4.23929 24.9035 1.85945 37.0881 7.75115 49.3417C13.6428 61.5954 19.5185 73.8266 25.8018 85.8844C25.6204 85.9406 25.4407 85.9967 25.277 86.0449C18.9696 74.0159 13.0795 61.8104 7.23115 49.5793C1.38278 37.3481 -4.64533 25.1474 -11.0988 13.2308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0367C-10.7264 12.9709 -10.6317 12.9067 -10.5225 12.8361C-3.85727 24.6772 2.34257 36.833 8.27921 49.0995C14.2158 61.3659 20.069 73.6276 26.3299 85.7127C26.1533 85.7769 25.9736 85.8331 25.8018 85.8844C19.5185 73.8266 13.6365 61.5746 7.75119 49.3418C1.86591 37.109 -4.24566 24.9035 -10.8195 13.0367Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9643L-11.8659 13.7637C-5.71261 25.7766 0.0747623 37.9981 5.82683 50.2229C11.5645 62.4203 17.4674 74.5552 23.8486 86.4943L23.3158 86.6548C16.9025 74.751 11.0043 62.6466 5.30363 50.4572C-0.397086 38.2677 -6.11706 26.0077 -12.1532 13.9643Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6255C-12.9131 14.5591 -12.8262 14.4856 -12.7454 14.4056C-6.94355 26.5325 -1.40173 38.8006 4.21553 50.9724C9.83278 63.1442 15.7405 75.1843 22.2052 87.0079L21.6724 87.1684C15.1788 75.393 9.27266 63.3737 3.70195 51.2275C-1.8816 39.0558 -7.33194 26.7797 -13.0054 14.6255Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.4765 73.2585 -19.8729 72.379 -20.2677 71.4802C-7.33838 65.7955 5.85254 60.5779 18.8332 54.7424C31.8139 48.9069 44.3516 42.3218 56.6373 35.5522C57.2263 36.6292 57.8057 37.7109 58.3434 38.8006C46.2165 45.8254 33.7542 52.6624 20.6805 58.4514C7.58265 64.25 -5.86185 69.0937 -19.0817 74.1572Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1573C-19.1347 74.0289 -19.1956 73.9021 -19.2422 73.7865C-6.05769 68.6395 7.35471 63.7365 20.4413 57.933C33.5038 52.1409 45.9725 45.3344 58.1299 38.3529L58.3433 38.8087C46.2165 45.8255 33.7542 52.6625 20.6804 58.4498C7.58261 64.2501 -5.86189 69.0937 -19.0817 74.1573Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5054C-18.5199 75.3866 -18.5857 75.2711 -18.6467 75.1443C-5.32581 70.3215 8.20375 65.6238 21.3273 59.8413C34.4187 54.0732 46.8456 47.1623 58.9003 40.0413C58.9597 40.1857 59.0335 40.343 59.1073 40.5003C47.08 47.6502 34.6787 54.5948 21.5824 60.3517C8.43807 66.1294 -5.10433 70.7837 -18.4622 75.5054Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4611C-20.701 70.3311 -20.7395 70.2123 -20.7925 70.0823C-8.03008 64.1007 5.00838 58.6745 17.9168 52.8373C30.8253 47.0002 43.3887 40.5211 55.7434 33.8992C55.8221 34.0452 55.9039 34.1881 55.989 34.3421C43.6487 41.0138 31.0933 47.5154 18.164 53.3509C5.23467 59.1864 -7.83588 64.5565 -20.648 70.4611Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0823C-20.8455 69.9539 -20.8888 69.8288 -20.9386 69.7052C-8.22113 63.6353 4.78201 58.1657 17.6696 52.3238C30.5572 46.4818 43.127 40.0397 55.5026 33.4514L55.7433 33.904C43.3854 40.5211 30.8268 47.0002 17.9168 52.8422C5.0067 58.6841 -8.03015 64.1008 -20.7926 70.0823Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8445C-20.1569 71.729 -20.2179 71.6022 -20.2709 71.4802C-7.33835 65.7955 5.85257 60.5795 18.8332 54.7424C31.8139 48.9053 44.3516 42.3218 56.6374 35.5522C56.716 35.6983 56.7979 35.8524 56.8861 36.0032C44.6116 42.8001 32.0803 49.4285 19.0932 55.2512C6.10615 61.0738 -7.13613 66.2449 -20.1104 71.8445Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9953C-19.6434 72.8765 -19.6963 72.7465 -19.7461 72.6357C-6.68196 67.2159 6.60846 62.1507 19.6517 56.3297C32.6693 50.5198 45.1701 43.8112 57.4045 36.9453C57.4799 37.0833 57.565 37.2374 57.642 37.3915C45.4446 44.2927 32.9438 51.0334 19.9069 56.84C6.87007 62.6467 -6.47814 67.6669 -19.5744 72.9953H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.705C-18.3353 19.9844 -17.7431 19.2445 -17.1621 18.5255C-13.3552 31.044 -9.32526 43.28 -4.4559 55.0714C0.394197 66.8162 6.29713 78.1631 13.1309 89.1761C11.8053 89.4249 10.4587 89.6271 9.12983 89.8181C2.19011 79.2673 -3.68072 68.349 -8.17773 56.8978C-12.7004 45.3904 -16.0387 33.278 -18.9436 20.705Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7051C-18.8633 20.6027 -18.777 20.5051 -18.6852 20.413C-15.6615 32.9731 -12.2238 45.108 -7.66416 56.6491C-3.1222 68.1436 2.74703 79.1181 9.67711 89.7411C9.50218 89.7684 9.31761 89.7909 9.12823 89.823C2.19011 79.2722 -3.68072 68.3538 -8.17773 56.9027C-12.6972 45.3905 -16.0419 33.2781 -18.9436 20.7051Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9489C-19.7396 21.8398 -19.6546 21.7178 -19.5807 21.5958C-17.0209 34.1769 -13.9314 46.2187 -9.55154 57.6137C-5.20057 68.9364 0.651003 79.6943 7.63887 90.0477C7.46393 90.0734 7.27134 90.0991 7.08517 90.1135C0.074831 79.8419 -5.75427 69.1547 -10.0619 57.8769C-14.384 46.5364 -17.3788 34.5107 -19.8151 21.9489Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.821C-16.3757 17.7295 -16.2906 17.6428 -16.2072 17.5546C-11.9605 29.9992 -7.59029 42.2705 -2.55242 54.1662C2.51942 66.1027 8.43297 77.6638 15.1435 88.7621L14.5995 88.8744C7.87557 77.8359 1.97483 66.3166 -3.05476 54.4102C-8.04609 42.5465 -12.3329 30.2881 -16.4607 17.8178L-16.4672 17.821Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5546L-15.9553 17.2914C-11.5851 29.72 -7.12819 42.0073 -2.03253 53.9271C3.08934 65.8872 9.00992 77.4892 15.6891 88.6546C15.519 88.6883 15.3328 88.73 15.1434 88.7621C8.43285 77.6638 2.51931 66.1028 -2.55253 54.1663C-7.59041 42.2689 -11.9606 29.9992 -16.2073 17.5546Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.8192C-17.3243 18.7229 -17.244 18.6202 -17.1654 18.5255C-13.3585 31.044 -9.32853 43.28 -4.45918 55.0714C0.390923 66.8179 6.29386 78.1631 13.1276 89.1761C12.9415 89.2163 12.7633 89.2532 12.582 89.2837C5.73694 78.3284 -0.157963 67.0474 -4.96473 55.3217C-9.77952 43.5737 -13.7325 31.3457 -17.4045 18.8192Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.7612C-18.0817 19.6649 -18.0015 19.5622 -17.9229 19.4675C-14.506 32.0004 -10.7777 44.1995 -6.06081 55.8673C-1.35837 67.4951 4.53172 78.6573 11.4201 89.4826C11.2275 89.5067 11.0381 89.5404 10.8648 89.5661C3.96358 78.813 -1.91689 67.7053 -6.56958 56.1113C-11.2399 44.482 -14.8671 32.3086 -18.162 19.7612Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3863C-17.5153 77.2676 -17.5811 77.152 -17.6421 77.0252C-4.16068 72.6726 9.50049 68.2719 22.6689 62.5279C35.7844 56.8079 48.1039 49.7366 59.9868 42.439C60.0606 42.5995 60.1312 42.7455 60.1938 42.8996C48.3334 50.2309 36.0412 57.3263 22.9241 63.035C9.75246 68.771 -3.9408 73.1156 -17.4575 77.3831V77.3863Z"
          fill="#650028"
        />
      </g>
      <path
        d="M16.116 40.2467L21.7718 36.9598L21.7654 39.5775L16.1096 42.8644L16.116 40.2467Z"
        fill="#C4524C"
      />
      <mask
        id="mask3"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="16"
        y="36"
        width="6"
        height="7"
      >
        <path
          d="M16.116 40.2467L21.7718 36.9598L21.7654 39.5775L16.1096 42.8644L16.116 40.2467Z"
          fill="#C4524C"
        />
      </mask>
      <g mask="url(#mask3)">
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89631C13.614 4.76149 14.6058 4.64112 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C59.836 66.5513 58.8778 67.3618 57.9213 68.1803C54.8639 55.7389 50.5932 43.4291 43.3951 32.5509C36.0461 21.4431 25.6397 12.2453 12.627 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89633C12.7652 4.88643 12.9029 4.86929 13.0394 4.84497C26.1132 12.096 36.5645 21.2153 43.9135 32.2893C51.1051 43.1338 55.3357 55.4115 58.3225 67.8369C58.1749 67.9508 58.0497 68.0728 57.9181 68.1803C54.8607 55.7405 50.59 43.4291 43.3919 32.5509C36.0461 21.4432 25.6397 12.2453 12.627 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09698C11.2789 5.08735 11.4169 5.06648 11.5485 5.04883C24.3606 12.6401 34.6611 22.0113 42.0085 33.2202C49.2194 44.2187 53.5944 56.5863 56.838 69.0759C56.7064 69.1834 56.5636 69.2861 56.4239 69.3969C53.1178 56.8881 48.6898 44.5028 41.4804 33.4625C34.1347 22.2136 23.8808 12.7669 11.144 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.4132C16.8976 4.4132 17.0389 4.39394 17.1817 4.3811C30.9376 10.7142 41.7403 19.1256 49.054 29.7807C56.1671 40.1534 59.9082 52.1904 62.1695 64.4215C62.0491 64.5323 61.9239 64.6559 61.7988 64.7778C59.4636 52.5226 55.6647 40.4631 48.5324 30.0375C41.2123 19.339 30.44 10.8442 16.7516 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38116C17.3198 4.36029 17.4626 4.34585 17.6039 4.33301C31.4175 10.5778 42.2588 18.9186 49.5676 29.5304C56.6823 39.8565 60.356 51.8694 62.5371 64.0621C62.4199 64.1808 62.2947 64.3044 62.1696 64.4264C59.9082 52.1904 56.1671 40.1534 49.0541 29.7808C41.7404 19.1256 30.9376 10.7142 17.1818 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56569C15.3203 4.54042 15.4587 4.5254 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C60.6561 65.8515 60.5213 65.951 60.3961 66.073C57.772 53.7407 53.7966 41.5754 46.6274 30.9684C39.2864 20.1078 28.6377 11.3561 15.1836 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73427C14.0361 4.71341 14.1741 4.69254 14.317 4.6781C27.609 11.6531 38.1919 20.5573 45.5281 31.5061C52.7053 42.2062 56.7898 54.4165 59.5535 66.7905C59.4219 66.898 59.2935 67.0136 59.1539 67.1227C56.31 54.7295 52.1933 42.4919 45.0113 31.7517C37.6671 20.7643 27.1291 11.7879 13.8965 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8384 40.5338 -25.7822 39.3622 -25.6763 38.1778C-19.1747 27.3622 -12.1082 19.6119 -3.52824 15.0556C5.16246 10.435 15.3746 8.10299 26.2111 5.86572C27.6 6.41943 28.9596 7.04404 30.2844 7.73707C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3707 -18.4461 31.1113 -25.8608 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8608 41.5128 -25.8608 41.362 -25.8608 41.1918C-18.5616 30.5993 -10.7055 22.8539 -1.50282 18.0102C7.81541 13.1072 18.4994 10.2456 29.7355 7.453C29.9249 7.55411 30.0999 7.64399 30.2844 7.73708C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3675 -18.4461 31.1081 -25.8608 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4002C-25.901 43.2397 -25.8914 43.0792 -25.8898 42.9332C-18.154 32.4771 -9.8838 24.759 -0.342491 19.7724C9.31919 14.7265 20.2439 11.5825 31.6919 8.4978C31.8524 8.59249 32.0129 8.70323 32.1734 8.80595C20.6853 11.9628 9.69153 15.1839 -0.0407619 20.2635C-9.67195 25.2918 -18.0529 32.9971 -25.901 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6673C-25.7004 38.5068 -25.6908 38.3463 -25.6763 38.1858C-19.1747 27.3702 -12.1082 19.62 -3.52827 15.0636C5.16242 10.435 15.3746 8.10299 26.2111 5.86572L26.8274 6.0856C15.9139 8.42398 5.61983 10.8522 -3.18482 15.5162C-11.8819 20.1271 -19.0736 27.887 -25.7164 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1952C-25.8336 40.0347 -25.824 39.8838 -25.8095 39.7137C-18.8762 28.996 -11.4005 21.2281 -2.49312 16.5192C6.5298 11.7478 17.0052 9.13656 28.0567 6.61682L28.5928 6.88805C17.4722 9.48965 6.92622 12.1843 -2.18657 16.9991C-11.2015 21.7529 -18.7832 29.5111 -25.8448 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34079C5.87818 6.13215 6.76249 5.92351 7.67891 5.73413C19.7255 14.1456 29.5702 24.1507 36.8565 35.6998C44.0338 47.0787 48.8117 59.6132 52.6779 72.228C51.6283 72.9518 50.5626 73.6804 49.513 74.4043C45.2295 61.7333 40.2157 49.1218 33.0962 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34077C5.11584 6.3183 5.23781 6.27817 5.35497 6.24768C16.8719 15.1695 26.3924 25.5181 33.6017 37.2501C40.7292 48.8361 45.7157 61.4332 49.9431 74.0993L49.505 74.4042C45.2214 61.7333 40.2076 49.1218 33.0882 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67295C3.76459 6.63925 3.88335 6.61838 4.00051 6.58789C15.2045 15.7793 24.5324 26.3365 31.6952 38.1585C38.7842 49.8584 43.8798 62.4924 48.3062 75.1746C48.1457 75.2725 48.0045 75.3607 47.8488 75.4506C43.3662 62.7717 38.2433 50.1345 31.1672 38.4008C24.0204 26.5484 14.76 15.9366 3.6394 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54314C8.85533 5.52067 8.98051 5.48857 9.1073 5.46289C21.4412 13.587 31.4511 23.3547 38.776 34.7882C45.9709 46.0227 50.5963 58.5042 54.2427 71.0788C54.1031 71.188 53.9699 71.2875 53.8303 71.3998C50.1245 58.814 45.4574 46.318 38.2625 35.0369C30.9504 23.5729 20.9677 13.7363 8.7157 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46293C9.2389 5.44527 9.36088 5.43083 9.48927 5.40515C21.9002 13.4298 31.9695 23.1412 39.2896 34.5282C46.4909 45.7322 51.0891 58.1897 54.6536 70.7451C54.514 70.8542 54.3824 70.9617 54.2427 71.066C50.5963 58.4978 45.9709 46.0227 38.776 34.7882C31.4511 23.3547 21.4412 13.5871 9.1073 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80796C7.43174 5.78228 7.55692 5.74858 7.6789 5.73413C19.7255 14.1456 29.5701 24.1507 36.8565 35.6998C44.0338 47.0787 48.8116 59.6132 52.6779 72.228C52.5367 72.3307 52.3826 72.4286 52.2478 72.5281C48.3221 59.8989 43.5138 47.3564 36.3365 35.9357C29.0598 24.3561 19.2537 14.3093 7.30334 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06956C6.25536 6.04067 6.38055 6.00697 6.50092 5.9845C18.2827 14.6511 27.9733 24.8248 35.2292 36.4653C42.3871 47.9582 47.2661 60.5296 51.317 73.1701C51.1709 73.2648 51.0297 73.3675 50.8756 73.4654C46.7847 60.8153 41.8591 48.2407 34.7044 36.7125C27.4661 25.0527 17.8365 14.81 6.1366 6.06635L6.13981 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.5688 49.5358 -25.6731 48.4894 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C37.4777 12.494 38.5562 13.3286 39.5673 14.1792C27.4613 18.4387 15.6924 22.7046 4.76599 28.2079C-6.05445 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.4885 50.4298 -25.5078 50.2949 -25.5206 50.1489C-16.143 40.553 -6.28073 33.1366 4.49156 27.7056C15.3457 22.2183 27.0842 18.0134 39.1548 13.8197C39.2816 13.9272 39.418 14.0588 39.5593 14.1792C27.4613 18.4387 15.6924 22.7046 4.766 28.2079C-6.05444 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1519C-25.3008 52.0219 -25.3297 51.8791 -25.3522 51.7363C-15.6488 42.3731 -5.47351 35.0578 5.51705 29.5545C16.6023 24.0031 28.4804 19.5815 40.6458 15.1615C40.779 15.2851 40.9202 15.4054 41.0534 15.529C28.8672 20.01 16.957 24.4829 5.81075 30.0488C-5.24401 35.5698 -15.5091 42.8498 -25.3008 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2217C-25.8192 46.0836 -25.8272 45.9264 -25.8256 45.7739C-17.4174 35.6323 -8.49236 27.9929 1.56895 22.8041C11.7201 17.5656 23.0108 13.977 34.7332 10.4349C34.8937 10.5377 35.0542 10.6484 35.2147 10.7559C23.4409 14.375 12.1037 18.0182 1.86105 23.292C-8.26767 28.5097 -17.297 36.1315 -25.8144 46.2265L-25.8079 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7739C-25.8255 45.6262 -25.8367 45.477 -25.8416 45.3277C-17.5392 35.13 -8.71856 27.4841 1.25448 22.3275C11.3318 17.1147 22.5583 13.5934 34.247 10.13L34.7285 10.4398C23.0125 13.9834 11.7154 17.5705 1.56423 22.809C-8.48905 27.9929 -17.4173 35.6323 -25.8255 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8747C-25.7453 47.7287 -25.7502 47.5794 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C36.5597 11.7831 36.7089 11.9002 36.855 12.0093C24.9448 15.8692 13.4342 19.766 2.93476 25.1313C-7.46679 30.4468 -16.8476 37.9707 -25.7309 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2325C-25.6105 49.0849 -25.6298 48.9388 -25.6394 48.8008C-16.5571 39.0236 -6.98209 31.5333 3.57032 26.1713C14.2158 20.7466 25.8227 16.7311 37.7906 12.73L38.2384 13.0815C26.2303 17.1435 14.5769 21.2233 3.86242 26.6592C-6.76061 32.0469 -16.4303 39.5131 -25.6105 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47698C-1.12893 8.16883 -0.326466 7.8655 0.508097 7.61353C10.815 17.5111 19.5329 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C42.6038 78.537 41.4595 79.1645 40.32 79.7824C35.0622 67.1452 29.5509 54.4695 22.7685 42.3875C15.8971 30.1522 7.60656 18.77 -1.9314 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47699C-1.82066 8.43045 -1.70992 8.38551 -1.61041 8.35181C8.01522 18.5651 16.3682 29.9069 23.266 42.1292C30.0821 54.2079 35.5806 66.8868 40.7951 79.5256C40.6346 79.6155 40.4741 79.7022 40.3136 79.7824C35.0558 67.1452 29.5445 54.4695 22.7621 42.3876C15.8927 30.1525 7.60431 18.7704 -1.9314 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93915C-3.03721 8.89421 -2.93128 8.86051 -2.82696 8.81396C6.46005 19.2952 14.5676 30.7648 21.3497 43.0166C28.0663 55.1531 33.6306 67.8096 39.0039 80.4211C38.8515 80.5014 38.6829 80.5816 38.5225 80.6619C33.1138 68.06 27.5191 55.4179 20.8329 43.267C14.0861 31.0021 6.04672 19.494 -3.14795 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32142C1.55612 7.29092 1.66847 7.25401 1.79044 7.21228C12.4375 16.8563 21.3931 27.7265 28.4564 39.7025C35.4506 51.5629 40.7212 64.2354 45.4686 76.9224C45.3242 77.0171 45.1669 77.1086 45.0128 77.2065C40.2141 64.5163 34.9226 51.8486 27.9364 39.9529C20.8891 27.9656 11.9946 17.0377 1.43896 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21223C1.91455 7.18221 2.03148 7.14359 2.14516 7.09668C12.8709 16.6797 21.8954 27.5002 28.97 39.4424C35.9803 51.2836 41.2252 63.9513 45.9116 76.6351C45.7736 76.7266 45.6211 76.8245 45.4638 76.9159C40.7213 64.237 35.4507 51.5581 28.4516 39.6976C21.3931 27.7265 12.4376 16.8563 1.79529 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72106C0.277004 7.68093 0.394164 7.65044 0.506509 7.61353C10.815 17.5111 19.533 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C43.5876 77.9913 43.4272 78.0828 43.2667 78.1663C38.2914 65.4873 32.9052 52.8164 25.9944 40.8404C19.037 28.7761 10.372 17.6909 0.155029 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.0789C-0.788642 8.03717 -0.674692 8.00026 -0.570371 7.95532C9.33431 17.9333 17.894 29.1615 24.8918 41.3555C31.7722 53.37 37.1888 66.0538 42.2604 78.7119C42.1079 78.8114 41.9555 78.89 41.7966 78.9735C36.6785 66.3154 31.2233 53.6381 24.3751 41.6027C17.4115 29.3999 8.92095 18.1346 -0.891357 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.3346 57.883 -24.5272 56.8928 -24.7278 55.9058C-14.6062 47.4874 -3.51314 40.311 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C45.3964 19.8366 46.2856 20.7738 47.0993 21.7496C34.661 27.1053 22.3031 32.4048 10.311 38.1761C-1.61037 43.9137 -13.0808 50.64 -24.1372 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8621C-24.1677 58.7225 -24.1966 58.5829 -24.2223 58.4513C-13.2317 50.1586 -1.8271 43.397 10.0494 37.677C21.982 31.9105 34.3127 26.6576 46.7557 21.3405C46.8681 21.4801 46.9869 21.6101 47.0992 21.7513C34.661 27.1054 22.303 32.4048 10.311 38.1762C-1.61043 43.9122 -13.0809 50.6401 -24.1372 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3386C-23.8354 60.2022 -23.8707 60.0738 -23.9044 59.9438C-12.6523 51.9191 -0.997252 45.3068 11.0381 39.5451C23.1393 33.7513 35.539 28.3283 48.0029 22.8346C48.1152 22.9758 48.2292 23.1155 48.3351 23.2422C35.8712 28.7809 23.4587 34.2505 11.3158 40.0459C-0.769354 45.8156 -12.4805 52.3814 -23.7985 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7792C-24.9316 54.6364 -24.9524 54.4935 -24.9701 54.3587C-14.7403 45.3807 -4.05143 38.2805 7.30666 32.6713C18.753 27.0171 30.8269 22.2569 43.1319 17.4774C43.2571 17.6042 43.3855 17.739 43.4994 17.861C31.1832 22.6983 19.0756 27.5098 7.57628 33.1833C-3.83958 38.8005 -14.6103 45.875 -24.9091 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3586C-24.9965 54.2222 -25.0158 54.0845 -25.028 53.9462C-14.8832 44.8992 -4.27781 37.7588 7.03213 32.1592C18.4271 26.5211 30.4641 21.8138 42.7627 17.0938C42.8879 17.2205 43.0098 17.3393 43.1318 17.4773C30.8268 22.2568 18.7529 27.017 7.30657 32.6712C-4.05152 38.2804 -14.7404 45.3806 -24.9702 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3022C-24.6732 56.1706 -24.6989 56.039 -24.7278 55.8993C-14.6058 47.4832 -3.51282 40.309 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C44.6245 19.0293 44.7496 19.1561 44.8748 19.2829C32.4912 24.3175 20.2857 29.3121 8.6034 35.0256C-3.26448 40.8094 -14.422 47.9493 -24.6459 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5782C-24.4181 57.4466 -24.455 57.3102 -24.4742 57.1753C-13.7212 48.6692 -2.52201 41.7969 9.20521 36.0994C20.995 30.3682 33.2519 25.2565 45.6644 20.1111C45.7816 20.2411 45.91 20.3759 46.0335 20.4947C33.6146 25.6946 21.3401 30.8545 9.49089 36.5969C-2.28448 42.3057 -13.5479 49.1378 -24.3924 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.406C-7.48127 10.9871 -6.75905 10.5826 -5.98708 10.2247C2.31446 21.3572 9.72289 33.1286 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C32.8843 83.2121 31.6694 83.7353 30.424 84.2088C24.4231 71.8942 18.6117 59.4463 12.399 47.1831C6.17668 34.8925 -0.637851 22.886 -8.20349 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4059L-7.89855 11.2278C-0.236618 22.6693 6.65816 34.6405 12.919 46.9439C19.1606 59.2104 24.964 71.6823 30.9327 84.0178C30.7722 84.09 30.6021 84.1542 30.424 84.2168C24.4231 71.8941 18.6117 59.4463 12.399 47.183C6.17668 34.8925 -0.637851 22.886 -8.20349 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0415C-9.19845 11.9725 -9.09894 11.9244 -9.00265 11.8666C-1.74355 23.4718 4.86876 35.5361 10.9916 47.817C17.1144 60.0979 22.9451 72.4816 29.0294 84.7176C28.8689 84.785 28.6972 84.8524 28.5206 84.9166C22.401 72.6934 16.5575 60.3242 10.4716 48.0594C4.38568 35.7945 -2.14318 23.6885 -9.29956 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81864C-5.03371 9.76567 -4.93099 9.72395 -4.83148 9.67419C3.85406 20.5773 11.53 32.2477 18.1014 44.5413C24.6527 56.7548 30.3277 69.3647 35.953 81.9057C35.7797 81.9763 35.624 82.0485 35.4507 82.1191C29.7933 69.6151 24.1102 57.0052 17.5814 44.7804C11.0387 32.4877 3.43563 20.7893 -5.14124 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67418C-4.73036 9.62122 -4.62123 9.57628 -4.5169 9.52332C4.25997 20.3671 12.0033 32.008 18.6117 44.2941C25.192 56.4916 30.8477 69.1144 36.4265 81.6553C36.266 81.7468 36.1151 81.8158 35.945 81.9057C30.3277 69.3631 24.6527 56.7548 18.1014 44.5413C11.5279 32.2474 3.84988 20.577 -4.83789 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3724C-6.20163 10.3153 -6.09434 10.2659 -5.98382 10.2247C2.31661 21.3573 9.72397 33.1287 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C33.9276 82.7692 33.7575 82.8478 33.6002 82.912C27.8016 70.4594 22.0623 57.9104 15.6474 45.66C9.23325 33.359 1.89477 21.5626 -6.30481 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.865L-6.96765 10.6869C1.01468 22.0068 8.20119 33.8674 14.54 46.1831C20.8779 58.4416 26.6572 70.9616 32.5233 83.3774C32.3628 83.4448 32.1878 83.5234 32.0209 83.5908C26.118 71.1911 20.3274 58.7016 14.0152 46.4239C7.68857 34.1269 0.626882 22.1894 -7.27258 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6813C-22.3027 65.7569 -22.5948 64.8164 -22.8837 63.8839C-10.9911 56.6152 1.2593 50.4202 13.6927 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.9301 27.9495 52.6668 28.9671 53.3232 30.0071C40.8626 36.2759 28.2959 42.4132 15.6074 48.2744C2.93966 54.1227 -9.70238 59.9711 -22.0122 66.6765L-22.017 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6813C-22.0556 66.5641 -22.0941 66.4277 -22.139 66.2945C-9.87898 59.512 2.7117 53.6043 15.3521 47.7575C28.0182 41.8979 40.5864 35.8056 53.0535 29.5785C53.1402 29.7213 53.2365 29.869 53.3232 30.0118C40.8625 36.2807 28.2959 42.4179 15.6073 48.2791C2.9396 54.1275 -9.70243 59.9759 -22.0122 66.6813H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1065C-21.5901 67.9765 -21.6399 67.8545 -21.6848 67.7213C-9.23056 61.2342 3.54308 55.4997 16.2846 49.6562C29.055 43.7998 41.6233 37.555 54.0695 31.1851C54.1562 31.3295 54.2508 31.4691 54.3359 31.612C41.9121 38.0317 29.3391 44.3118 16.5478 50.1634C3.75654 56.0149 -9.03636 61.6884 -21.5356 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8086C-23.2079 62.6883 -23.2368 62.5486 -23.2737 62.4122C-11.6042 54.869 0.437553 48.5071 12.7329 42.7005C25.0732 36.8746 37.5612 31.1803 50.0684 25.3865C50.1727 25.5309 50.2674 25.6705 50.3685 25.807C37.8709 31.6425 25.3621 37.3769 12.9945 43.2108C0.657429 49.0223 -11.4486 55.3313 -23.1758 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4122C-23.3075 62.2918 -23.3444 62.1554 -23.3749 62.0157C-11.7728 54.3891 0.21759 47.9871 12.4632 42.1852C24.7843 36.3513 37.261 30.7164 49.7473 24.9772C49.8468 25.1072 49.9512 25.25 50.0539 25.3864C37.5627 31.1802 25.0731 36.8745 12.7328 42.7004C0.437466 48.5071 -11.6043 54.8642 -23.277 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2627C-22.8082 64.1375 -22.8451 64.0011 -22.8837 63.8839C-10.9911 56.6152 1.25929 50.4202 13.6911 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.285 27.0716 51.3909 27.2161 51.4952 27.3589C39.0056 33.3507 26.4963 39.2589 13.9671 45.0838C1.49683 50.9048 -10.813 57.0646 -22.7665 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4792C-22.4343 65.3476 -22.4792 65.2144 -22.5129 65.086C-10.4374 58.0596 1.99113 52.001 14.5369 46.1671C27.1131 40.3187 39.6481 34.3462 52.142 28.2496C52.2479 28.3941 52.349 28.5305 52.4517 28.6669C39.975 34.8095 27.4277 40.8136 14.8097 46.679C2.23026 52.5178 -10.2545 58.525 -22.3894 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.2028 14.8035 -12.5528 14.253 -11.8659 13.7667C-5.71258 25.7796 0.0747986 38.0012 5.82687 50.2259C11.5645 62.4234 17.4674 74.5583 23.8486 86.4973C22.5647 86.8975 21.2807 87.2688 19.9968 87.6112C13.423 75.961 7.51044 64.0765 2.08096 51.9817C-3.34852 39.887 -8.53084 27.5868 -13.8464 15.354H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.7661 15.2738 -13.6762 15.1936 -13.5815 15.1182C-8.15368 27.3381 -2.89432 39.6206 2.59454 51.7427C8.0834 63.8647 13.9895 75.7733 20.5232 87.4716L19.9904 87.6096C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.887 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2031C-14.758 16.1165 -14.6713 16.0426 -14.5815 15.964C-9.59173 28.2834 -4.65978 40.566 0.681424 52.6318C6.01356 64.721 11.9749 76.5226 18.5411 87.9884C18.3581 88.0366 18.1752 88.0847 18.0083 88.1249C11.4042 76.7169 5.44385 64.9482 0.155007 52.8742C-5.12842 40.8372 -9.97531 28.537 -14.8591 16.2031H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2307L-10.8195 13.0365C-4.23929 24.9034 1.85945 37.088 7.75115 49.3416C13.6428 61.5953 19.5185 73.8264 25.8018 85.8843C25.6204 85.9404 25.4407 85.9966 25.277 86.0448C18.9696 74.0158 13.0795 61.8103 7.23115 49.5792C1.38278 37.348 -4.64533 25.1473 -11.0988 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0366C-10.7264 12.9708 -10.6317 12.9066 -10.5225 12.8359C-3.85727 24.6771 2.34257 36.8329 8.27921 49.0993C14.2158 61.3658 20.069 73.6275 26.3299 85.7126C26.1533 85.7768 25.9736 85.833 25.8018 85.8843C19.5185 73.8265 13.6365 61.5745 7.75119 49.3417C1.86591 37.1089 -4.24566 24.9034 -10.8195 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9642L-11.8659 13.7635C-5.71261 25.7765 0.0747623 37.998 5.82683 50.2227C11.5645 62.4202 17.4674 74.5551 23.8486 86.4942L23.3158 86.6547C16.9025 74.7509 11.0043 62.6465 5.30363 50.4571C-0.397086 38.2676 -6.11706 26.0076 -12.1532 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6254C-12.9131 14.559 -12.8262 14.4855 -12.7454 14.4055C-6.94355 26.5324 -1.40173 38.8005 4.21553 50.9722C9.83278 63.144 15.7405 75.1842 22.2052 87.0077L21.6724 87.1682C15.1788 75.3929 9.27266 63.3735 3.70195 51.2274C-1.8816 39.0556 -7.33194 26.7795 -13.0054 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1571C-19.4765 73.2584 -19.8729 72.3788 -20.2677 71.4801C-7.33838 65.7954 5.85254 60.5778 18.8332 54.7423C31.8139 48.9067 44.3516 42.3217 56.6373 35.5521C57.2263 36.629 57.8057 37.7108 58.3434 38.8005C46.2165 45.8253 33.7542 52.6623 20.6805 58.4513C7.58265 64.2499 -5.86185 69.0936 -19.0817 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.1347 74.0288 -19.1956 73.902 -19.2422 73.7864C-6.05769 68.6394 7.35471 63.7364 20.4413 57.9329C33.5038 52.1407 45.9725 45.3342 58.1299 38.3528L58.3433 38.8086C46.2165 45.8253 33.7542 52.6623 20.6804 58.4497C7.58261 64.2499 -5.86189 69.0936 -19.0817 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5053C-18.5199 75.3865 -18.5857 75.271 -18.6467 75.1442C-5.32581 70.3214 8.20375 65.6237 21.3273 59.8412C34.4187 54.073 46.8456 47.1622 58.9003 40.0411C58.9597 40.1856 59.0335 40.3429 59.1073 40.5001C47.08 47.6501 34.6787 54.5946 21.5824 60.3515C8.43807 66.1293 -5.10433 70.7836 -18.4622 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.461C-20.701 70.331 -20.7395 70.2122 -20.7925 70.0822C-8.03008 64.1006 5.00838 58.6744 17.9168 52.8372C30.8253 47.0001 43.3887 40.521 55.7434 33.899C55.8221 34.0451 55.9039 34.1879 55.989 34.342C43.6487 41.0137 31.0933 47.5153 18.164 53.3508C5.23467 59.1863 -7.83588 64.5564 -20.648 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0822C-20.8455 69.9538 -20.8888 69.8286 -20.9386 69.7051C-8.22113 63.6352 4.78201 58.1656 17.6696 52.3237C30.5572 46.4817 43.127 40.0395 55.5026 33.4513L55.7433 33.9039C43.3854 40.521 30.8268 47.0001 17.9168 52.8421C5.0067 58.684 -8.03015 64.1006 -20.7926 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8444C-20.1569 71.7289 -20.2179 71.6021 -20.2709 71.4801C-7.33835 65.7954 5.85257 60.5794 18.8332 54.7423C31.8139 48.9051 44.3516 42.3217 56.6374 35.5521C56.716 35.6982 56.7979 35.8522 56.8861 36.0031C44.6116 42.8 32.0803 49.4284 19.0932 55.251C6.10615 61.0737 -7.13613 66.2448 -20.1104 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9951C-19.6434 72.8764 -19.6963 72.7464 -19.7461 72.6356C-6.68196 67.2158 6.60846 62.1506 19.6517 56.3295C32.6693 50.5197 45.1701 43.8111 57.4045 36.9452C57.4799 37.0832 57.565 37.2373 57.642 37.3914C45.4446 44.2926 32.9438 51.0333 19.9069 56.8399C6.87007 62.6465 -6.47814 67.6668 -19.5744 72.9951H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3862C-17.5153 77.2674 -17.5811 77.1519 -17.6421 77.0251C-4.16068 72.6725 9.50049 68.2718 22.6689 62.5277C35.7844 56.8078 48.1039 49.7365 59.9868 42.4388C60.0606 42.5993 60.1312 42.7454 60.1938 42.8995C48.3334 50.2308 36.0412 57.3262 22.9241 63.0349C9.75246 68.7709 -3.9408 73.1155 -17.4575 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89631C13.614 4.76149 14.6058 4.64112 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C59.836 66.5513 58.8778 67.3618 57.9213 68.1803C54.8639 55.7389 50.5932 43.4291 43.3951 32.5509C36.0461 21.4431 25.6397 12.2453 12.627 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89633C12.7652 4.88643 12.9029 4.86929 13.0394 4.84497C26.1132 12.096 36.5645 21.2153 43.9135 32.2893C51.1051 43.1338 55.3357 55.4115 58.3225 67.8369C58.1749 67.9508 58.0497 68.0728 57.9181 68.1803C54.8607 55.7405 50.59 43.4291 43.3919 32.5509C36.0461 21.4432 25.6397 12.2453 12.627 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09698C11.2789 5.08735 11.4169 5.06648 11.5485 5.04883C24.3606 12.6401 34.6611 22.0113 42.0085 33.2202C49.2194 44.2187 53.5944 56.5863 56.838 69.0759C56.7064 69.1834 56.5636 69.2861 56.4239 69.3969C53.1178 56.8881 48.6898 44.5028 41.4804 33.4625C34.1347 22.2136 23.8808 12.7669 11.144 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.4132C16.8976 4.4132 17.0389 4.39394 17.1817 4.3811C30.9376 10.7142 41.7403 19.1256 49.054 29.7807C56.1671 40.1534 59.9082 52.1904 62.1695 64.4215C62.0491 64.5323 61.9239 64.6559 61.7988 64.7778C59.4636 52.5226 55.6647 40.4631 48.5324 30.0375C41.2123 19.339 30.44 10.8442 16.7516 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38116C17.3198 4.36029 17.4626 4.34585 17.6039 4.33301C31.4175 10.5778 42.2588 18.9186 49.5676 29.5304C56.6823 39.8565 60.356 51.8694 62.5371 64.0621C62.4199 64.1808 62.2947 64.3044 62.1696 64.4264C59.9082 52.1904 56.1671 40.1534 49.0541 29.7808C41.7404 19.1256 30.9376 10.7142 17.1818 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56569C15.3203 4.54042 15.4587 4.5254 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C60.6561 65.8515 60.5213 65.951 60.3961 66.073C57.772 53.7407 53.7966 41.5754 46.6274 30.9684C39.2864 20.1078 28.6377 11.3561 15.1836 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73427C14.0361 4.71341 14.1741 4.69254 14.317 4.6781C27.609 11.6531 38.1919 20.5573 45.5281 31.5061C52.7053 42.2062 56.7898 54.4165 59.5535 66.7905C59.4219 66.898 59.2935 67.0136 59.1539 67.1227C56.31 54.7295 52.1933 42.4919 45.0113 31.7517C37.6671 20.7643 27.1291 11.7879 13.8965 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8384 40.5338 -25.7822 39.3622 -25.6763 38.1778C-19.1747 27.3622 -12.1082 19.6119 -3.52824 15.0556C5.16246 10.435 15.3746 8.10299 26.2111 5.86572C27.6 6.41943 28.9596 7.04404 30.2844 7.73707C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3707 -18.4461 31.1113 -25.8608 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8608 41.5128 -25.8608 41.362 -25.8608 41.1918C-18.5616 30.5993 -10.7055 22.8539 -1.50282 18.0102C7.81541 13.1072 18.4994 10.2456 29.7355 7.453C29.9249 7.55411 30.0999 7.64399 30.2844 7.73708C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3675 -18.4461 31.1081 -25.8608 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4002C-25.901 43.2397 -25.8914 43.0792 -25.8898 42.9332C-18.154 32.4771 -9.8838 24.759 -0.342491 19.7724C9.31919 14.7265 20.2439 11.5825 31.6919 8.4978C31.8524 8.59249 32.0129 8.70323 32.1734 8.80595C20.6853 11.9628 9.69153 15.1839 -0.0407619 20.2635C-9.67195 25.2918 -18.0529 32.9971 -25.901 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6673C-25.7004 38.5068 -25.6908 38.3463 -25.6763 38.1858C-19.1747 27.3702 -12.1082 19.62 -3.52827 15.0636C5.16242 10.435 15.3746 8.10299 26.2111 5.86572L26.8274 6.0856C15.9139 8.42398 5.61983 10.8522 -3.18482 15.5162C-11.8819 20.1271 -19.0736 27.887 -25.7164 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1952C-25.8336 40.0347 -25.824 39.8838 -25.8095 39.7137C-18.8762 28.996 -11.4005 21.2281 -2.49312 16.5192C6.5298 11.7478 17.0052 9.13656 28.0567 6.61682L28.5928 6.88805C17.4722 9.48965 6.92622 12.1843 -2.18657 16.9991C-11.2015 21.7529 -18.7832 29.5111 -25.8448 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34079C5.87818 6.13215 6.76249 5.92351 7.67891 5.73413C19.7255 14.1456 29.5702 24.1507 36.8565 35.6998C44.0338 47.0787 48.8117 59.6132 52.6779 72.228C51.6283 72.9518 50.5626 73.6804 49.513 74.4043C45.2295 61.7333 40.2157 49.1218 33.0962 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34077C5.11584 6.3183 5.23781 6.27817 5.35497 6.24768C16.8719 15.1695 26.3924 25.5181 33.6017 37.2501C40.7292 48.8361 45.7157 61.4332 49.9431 74.0993L49.505 74.4042C45.2214 61.7333 40.2076 49.1218 33.0882 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67295C3.76459 6.63925 3.88335 6.61838 4.00051 6.58789C15.2045 15.7793 24.5324 26.3365 31.6952 38.1585C38.7842 49.8584 43.8798 62.4924 48.3062 75.1746C48.1457 75.2725 48.0045 75.3607 47.8488 75.4506C43.3662 62.7717 38.2433 50.1345 31.1672 38.4008C24.0204 26.5484 14.76 15.9366 3.6394 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54314C8.85533 5.52067 8.98051 5.48857 9.1073 5.46289C21.4412 13.587 31.4511 23.3547 38.776 34.7882C45.9709 46.0227 50.5963 58.5042 54.2427 71.0788C54.1031 71.188 53.9699 71.2875 53.8303 71.3998C50.1245 58.814 45.4574 46.318 38.2625 35.0369C30.9504 23.5729 20.9677 13.7363 8.7157 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46293C9.2389 5.44527 9.36088 5.43083 9.48927 5.40515C21.9002 13.4298 31.9695 23.1412 39.2896 34.5282C46.4909 45.7322 51.0891 58.1897 54.6536 70.7451C54.514 70.8542 54.3824 70.9617 54.2427 71.066C50.5963 58.4978 45.9709 46.0227 38.776 34.7882C31.4511 23.3547 21.4412 13.5871 9.1073 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80796C7.43174 5.78228 7.55692 5.74858 7.6789 5.73413C19.7255 14.1456 29.5701 24.1507 36.8565 35.6998C44.0338 47.0787 48.8116 59.6132 52.6779 72.228C52.5367 72.3307 52.3826 72.4286 52.2478 72.5281C48.3221 59.8989 43.5138 47.3564 36.3365 35.9357C29.0598 24.3561 19.2537 14.3093 7.30334 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06956C6.25536 6.04067 6.38055 6.00697 6.50092 5.9845C18.2827 14.6511 27.9733 24.8248 35.2292 36.4653C42.3871 47.9582 47.2661 60.5296 51.317 73.1701C51.1709 73.2648 51.0297 73.3675 50.8756 73.4654C46.7847 60.8153 41.8591 48.2407 34.7044 36.7125C27.4661 25.0527 17.8365 14.81 6.1366 6.06635L6.13981 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.5688 49.5358 -25.6731 48.4894 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C37.4777 12.494 38.5562 13.3286 39.5673 14.1792C27.4613 18.4387 15.6924 22.7046 4.76599 28.2079C-6.05445 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.4885 50.4298 -25.5078 50.2949 -25.5206 50.1489C-16.143 40.553 -6.28073 33.1366 4.49156 27.7056C15.3457 22.2183 27.0842 18.0134 39.1548 13.8197C39.2816 13.9272 39.418 14.0588 39.5593 14.1792C27.4613 18.4387 15.6924 22.7046 4.766 28.2079C-6.05444 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1519C-25.3008 52.0219 -25.3297 51.8791 -25.3522 51.7363C-15.6488 42.3731 -5.47351 35.0578 5.51705 29.5545C16.6023 24.0031 28.4804 19.5815 40.6458 15.1615C40.779 15.2851 40.9202 15.4054 41.0534 15.529C28.8672 20.01 16.957 24.4829 5.81075 30.0488C-5.24401 35.5698 -15.5091 42.8498 -25.3008 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2217C-25.8192 46.0836 -25.8272 45.9264 -25.8256 45.7739C-17.4174 35.6323 -8.49236 27.9929 1.56895 22.8041C11.7201 17.5656 23.0108 13.977 34.7332 10.4349C34.8937 10.5377 35.0542 10.6484 35.2147 10.7559C23.4409 14.375 12.1037 18.0182 1.86105 23.292C-8.26767 28.5097 -17.297 36.1315 -25.8144 46.2265L-25.8079 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7739C-25.8255 45.6262 -25.8367 45.477 -25.8416 45.3277C-17.5392 35.13 -8.71856 27.4841 1.25448 22.3275C11.3318 17.1147 22.5583 13.5934 34.247 10.13L34.7285 10.4398C23.0125 13.9834 11.7154 17.5705 1.56423 22.809C-8.48905 27.9929 -17.4173 35.6323 -25.8255 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8747C-25.7453 47.7287 -25.7502 47.5794 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C36.5597 11.7831 36.7089 11.9002 36.855 12.0093C24.9448 15.8692 13.4342 19.766 2.93476 25.1313C-7.46679 30.4468 -16.8476 37.9707 -25.7309 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2325C-25.6105 49.0849 -25.6298 48.9388 -25.6394 48.8008C-16.5571 39.0236 -6.98209 31.5333 3.57032 26.1713C14.2158 20.7466 25.8227 16.7311 37.7906 12.73L38.2384 13.0815C26.2303 17.1435 14.5769 21.2233 3.86242 26.6592C-6.76061 32.0469 -16.4303 39.5131 -25.6105 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47698C-1.12893 8.16883 -0.326466 7.8655 0.508097 7.61353C10.815 17.5111 19.5329 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C42.6038 78.537 41.4595 79.1645 40.32 79.7824C35.0622 67.1452 29.5509 54.4695 22.7685 42.3875C15.8971 30.1522 7.60656 18.77 -1.9314 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47699C-1.82066 8.43045 -1.70992 8.38551 -1.61041 8.35181C8.01522 18.5651 16.3682 29.9069 23.266 42.1292C30.0821 54.2079 35.5806 66.8868 40.7951 79.5256C40.6346 79.6155 40.4741 79.7022 40.3136 79.7824C35.0558 67.1452 29.5445 54.4695 22.7621 42.3876C15.8927 30.1525 7.60431 18.7704 -1.9314 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93915C-3.03721 8.89421 -2.93128 8.86051 -2.82696 8.81396C6.46005 19.2952 14.5676 30.7648 21.3497 43.0166C28.0663 55.1531 33.6306 67.8096 39.0039 80.4211C38.8515 80.5014 38.6829 80.5816 38.5225 80.6619C33.1138 68.06 27.5191 55.4179 20.8329 43.267C14.0861 31.0021 6.04672 19.494 -3.14795 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32142C1.55612 7.29092 1.66847 7.25401 1.79044 7.21228C12.4375 16.8563 21.3931 27.7265 28.4564 39.7025C35.4506 51.5629 40.7212 64.2354 45.4686 76.9224C45.3242 77.0171 45.1669 77.1086 45.0128 77.2065C40.2141 64.5163 34.9226 51.8486 27.9364 39.9529C20.8891 27.9656 11.9946 17.0377 1.43896 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21223C1.91455 7.18221 2.03148 7.14359 2.14516 7.09668C12.8709 16.6797 21.8954 27.5002 28.97 39.4424C35.9803 51.2836 41.2252 63.9513 45.9116 76.6351C45.7736 76.7266 45.6211 76.8245 45.4638 76.9159C40.7213 64.237 35.4507 51.5581 28.4516 39.6976C21.3931 27.7265 12.4376 16.8563 1.79529 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72106C0.277004 7.68093 0.394164 7.65044 0.506509 7.61353C10.815 17.5111 19.533 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C43.5876 77.9913 43.4272 78.0828 43.2667 78.1663C38.2914 65.4873 32.9052 52.8164 25.9944 40.8404C19.037 28.7761 10.372 17.6909 0.155029 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.0789C-0.788642 8.03717 -0.674692 8.00026 -0.570371 7.95532C9.33431 17.9333 17.894 29.1615 24.8918 41.3555C31.7722 53.37 37.1888 66.0538 42.2604 78.7119C42.1079 78.8114 41.9555 78.89 41.7966 78.9735C36.6785 66.3154 31.2233 53.6381 24.3751 41.6027C17.4115 29.3999 8.92095 18.1346 -0.891357 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.3346 57.883 -24.5272 56.8928 -24.7278 55.9058C-14.6062 47.4874 -3.51314 40.311 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C45.3964 19.8366 46.2856 20.7738 47.0993 21.7496C34.661 27.1053 22.3031 32.4048 10.311 38.1761C-1.61037 43.9137 -13.0808 50.64 -24.1372 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8621C-24.1677 58.7225 -24.1966 58.5829 -24.2223 58.4513C-13.2317 50.1586 -1.8271 43.397 10.0494 37.677C21.982 31.9105 34.3127 26.6576 46.7557 21.3405C46.8681 21.4801 46.9869 21.6101 47.0992 21.7513C34.661 27.1054 22.303 32.4048 10.311 38.1762C-1.61043 43.9122 -13.0809 50.6401 -24.1372 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3386C-23.8354 60.2022 -23.8707 60.0738 -23.9044 59.9438C-12.6523 51.9191 -0.997252 45.3068 11.0381 39.5451C23.1393 33.7513 35.539 28.3283 48.0029 22.8346C48.1152 22.9758 48.2292 23.1155 48.3351 23.2422C35.8712 28.7809 23.4587 34.2505 11.3158 40.0459C-0.769354 45.8156 -12.4805 52.3814 -23.7985 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7792C-24.9316 54.6364 -24.9524 54.4935 -24.9701 54.3587C-14.7403 45.3807 -4.05143 38.2805 7.30666 32.6713C18.753 27.0171 30.8269 22.2569 43.1319 17.4774C43.2571 17.6042 43.3855 17.739 43.4994 17.861C31.1832 22.6983 19.0756 27.5098 7.57628 33.1833C-3.83958 38.8005 -14.6103 45.875 -24.9091 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3586C-24.9965 54.2222 -25.0158 54.0845 -25.028 53.9462C-14.8832 44.8992 -4.27781 37.7588 7.03213 32.1592C18.4271 26.5211 30.4641 21.8138 42.7627 17.0938C42.8879 17.2205 43.0098 17.3393 43.1318 17.4773C30.8268 22.2568 18.7529 27.017 7.30657 32.6712C-4.05152 38.2804 -14.7404 45.3806 -24.9702 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3022C-24.6732 56.1706 -24.6989 56.039 -24.7278 55.8993C-14.6058 47.4832 -3.51282 40.309 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C44.6245 19.0293 44.7496 19.1561 44.8748 19.2829C32.4912 24.3175 20.2857 29.3121 8.6034 35.0256C-3.26448 40.8094 -14.422 47.9493 -24.6459 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5782C-24.4181 57.4466 -24.455 57.3102 -24.4742 57.1753C-13.7212 48.6692 -2.52201 41.7969 9.20521 36.0994C20.995 30.3682 33.2519 25.2565 45.6644 20.1111C45.7816 20.2411 45.91 20.3759 46.0335 20.4947C33.6146 25.6946 21.3401 30.8545 9.49089 36.5969C-2.28448 42.3057 -13.5479 49.1378 -24.3924 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.406C-7.48127 10.9871 -6.75905 10.5826 -5.98708 10.2247C2.31446 21.3572 9.72289 33.1286 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C32.8843 83.2121 31.6694 83.7353 30.424 84.2088C24.4231 71.8942 18.6117 59.4463 12.399 47.1831C6.17668 34.8925 -0.637851 22.886 -8.20349 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4059L-7.89855 11.2278C-0.236618 22.6693 6.65816 34.6405 12.919 46.9439C19.1606 59.2104 24.964 71.6823 30.9327 84.0178C30.7722 84.09 30.6021 84.1542 30.424 84.2168C24.4231 71.8941 18.6117 59.4463 12.399 47.183C6.17668 34.8925 -0.637851 22.886 -8.20349 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0415C-9.19845 11.9725 -9.09894 11.9244 -9.00265 11.8666C-1.74355 23.4718 4.86876 35.5361 10.9916 47.817C17.1144 60.0979 22.9451 72.4816 29.0294 84.7176C28.8689 84.785 28.6972 84.8524 28.5206 84.9166C22.401 72.6934 16.5575 60.3242 10.4716 48.0594C4.38568 35.7945 -2.14318 23.6885 -9.29956 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81864C-5.03371 9.76567 -4.93099 9.72395 -4.83148 9.67419C3.85406 20.5773 11.53 32.2477 18.1014 44.5413C24.6527 56.7548 30.3277 69.3647 35.953 81.9057C35.7797 81.9763 35.624 82.0485 35.4507 82.1191C29.7933 69.6151 24.1102 57.0052 17.5814 44.7804C11.0387 32.4877 3.43563 20.7893 -5.14124 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67418C-4.73036 9.62122 -4.62123 9.57628 -4.5169 9.52332C4.25997 20.3671 12.0033 32.008 18.6117 44.2941C25.192 56.4916 30.8477 69.1144 36.4265 81.6553C36.266 81.7468 36.1151 81.8158 35.945 81.9057C30.3277 69.3631 24.6527 56.7548 18.1014 44.5413C11.5279 32.2474 3.84988 20.577 -4.83789 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3724C-6.20163 10.3153 -6.09434 10.2659 -5.98382 10.2247C2.31661 21.3573 9.72397 33.1287 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C33.9276 82.7692 33.7575 82.8478 33.6002 82.912C27.8016 70.4594 22.0623 57.9104 15.6474 45.66C9.23325 33.359 1.89477 21.5626 -6.30481 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.865L-6.96765 10.6869C1.01468 22.0068 8.20119 33.8674 14.54 46.1831C20.8779 58.4416 26.6572 70.9616 32.5233 83.3774C32.3628 83.4448 32.1878 83.5234 32.0209 83.5908C26.118 71.1911 20.3274 58.7016 14.0152 46.4239C7.68857 34.1269 0.626882 22.1894 -7.27258 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6813C-22.3027 65.7569 -22.5948 64.8164 -22.8837 63.8839C-10.9911 56.6152 1.2593 50.4202 13.6927 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.9301 27.9495 52.6668 28.9671 53.3232 30.0071C40.8626 36.2759 28.2959 42.4132 15.6074 48.2744C2.93966 54.1227 -9.70238 59.9711 -22.0122 66.6765L-22.017 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6813C-22.0556 66.5641 -22.0941 66.4277 -22.139 66.2945C-9.87898 59.512 2.7117 53.6043 15.3521 47.7575C28.0182 41.8979 40.5864 35.8056 53.0535 29.5785C53.1402 29.7213 53.2365 29.869 53.3232 30.0118C40.8625 36.2807 28.2959 42.4179 15.6073 48.2791C2.9396 54.1275 -9.70243 59.9759 -22.0122 66.6813H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1065C-21.5901 67.9765 -21.6399 67.8545 -21.6848 67.7213C-9.23056 61.2342 3.54308 55.4997 16.2846 49.6562C29.055 43.7998 41.6233 37.555 54.0695 31.1851C54.1562 31.3295 54.2508 31.4691 54.3359 31.612C41.9121 38.0317 29.3391 44.3118 16.5478 50.1634C3.75654 56.0149 -9.03636 61.6884 -21.5356 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8086C-23.2079 62.6883 -23.2368 62.5486 -23.2737 62.4122C-11.6042 54.869 0.437553 48.5071 12.7329 42.7005C25.0732 36.8746 37.5612 31.1803 50.0684 25.3865C50.1727 25.5309 50.2674 25.6705 50.3685 25.807C37.8709 31.6425 25.3621 37.3769 12.9945 43.2108C0.657429 49.0223 -11.4486 55.3313 -23.1758 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4122C-23.3075 62.2918 -23.3444 62.1554 -23.3749 62.0157C-11.7728 54.3891 0.21759 47.9871 12.4632 42.1852C24.7843 36.3513 37.261 30.7164 49.7473 24.9772C49.8468 25.1072 49.9512 25.25 50.0539 25.3864C37.5627 31.1802 25.0731 36.8745 12.7328 42.7004C0.437466 48.5071 -11.6043 54.8642 -23.277 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2627C-22.8082 64.1375 -22.8451 64.0011 -22.8837 63.8839C-10.9911 56.6152 1.25929 50.4202 13.6911 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.285 27.0716 51.3909 27.2161 51.4952 27.3589C39.0056 33.3507 26.4963 39.2589 13.9671 45.0838C1.49683 50.9048 -10.813 57.0646 -22.7665 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4792C-22.4343 65.3476 -22.4792 65.2144 -22.5129 65.086C-10.4374 58.0596 1.99113 52.001 14.5369 46.1671C27.1131 40.3187 39.6481 34.3462 52.142 28.2496C52.2479 28.3941 52.349 28.5305 52.4517 28.6669C39.975 34.8095 27.4277 40.8136 14.8097 46.679C2.23026 52.5178 -10.2545 58.525 -22.3894 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.2028 14.8035 -12.5528 14.253 -11.8659 13.7667C-5.71258 25.7796 0.0747986 38.0012 5.82687 50.2259C11.5645 62.4234 17.4674 74.5583 23.8486 86.4973C22.5647 86.8975 21.2807 87.2688 19.9968 87.6112C13.423 75.961 7.51044 64.0765 2.08096 51.9817C-3.34852 39.887 -8.53084 27.5868 -13.8464 15.354H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.7661 15.2738 -13.6762 15.1936 -13.5815 15.1182C-8.15368 27.3381 -2.89432 39.6206 2.59454 51.7427C8.0834 63.8647 13.9895 75.7733 20.5232 87.4716L19.9904 87.6096C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.887 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2031C-14.758 16.1165 -14.6713 16.0426 -14.5815 15.964C-9.59173 28.2834 -4.65978 40.566 0.681424 52.6318C6.01356 64.721 11.9749 76.5226 18.5411 87.9884C18.3581 88.0366 18.1752 88.0847 18.0083 88.1249C11.4042 76.7169 5.44385 64.9482 0.155007 52.8742C-5.12842 40.8372 -9.97531 28.537 -14.8591 16.2031H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2307L-10.8195 13.0365C-4.23929 24.9034 1.85945 37.088 7.75115 49.3416C13.6428 61.5953 19.5185 73.8264 25.8018 85.8843C25.6204 85.9404 25.4407 85.9966 25.277 86.0448C18.9696 74.0158 13.0795 61.8103 7.23115 49.5792C1.38278 37.348 -4.64533 25.1473 -11.0988 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0366C-10.7264 12.9708 -10.6317 12.9066 -10.5225 12.8359C-3.85727 24.6771 2.34257 36.8329 8.27921 49.0993C14.2158 61.3658 20.069 73.6275 26.3299 85.7126C26.1533 85.7768 25.9736 85.833 25.8018 85.8843C19.5185 73.8265 13.6365 61.5745 7.75119 49.3417C1.86591 37.1089 -4.24566 24.9034 -10.8195 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9642L-11.8659 13.7635C-5.71261 25.7765 0.0747623 37.998 5.82683 50.2227C11.5645 62.4202 17.4674 74.5551 23.8486 86.4942L23.3158 86.6547C16.9025 74.7509 11.0043 62.6465 5.30363 50.4571C-0.397086 38.2676 -6.11706 26.0076 -12.1532 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6254C-12.9131 14.559 -12.8262 14.4855 -12.7454 14.4055C-6.94355 26.5324 -1.40173 38.8005 4.21553 50.9722C9.83278 63.144 15.7405 75.1842 22.2052 87.0077L21.6724 87.1682C15.1788 75.3929 9.27266 63.3735 3.70195 51.2274C-1.8816 39.0556 -7.33194 26.7795 -13.0054 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1571C-19.4765 73.2584 -19.8729 72.3788 -20.2677 71.4801C-7.33838 65.7954 5.85254 60.5778 18.8332 54.7423C31.8139 48.9067 44.3516 42.3217 56.6373 35.5521C57.2263 36.629 57.8057 37.7108 58.3434 38.8005C46.2165 45.8253 33.7542 52.6623 20.6805 58.4513C7.58265 64.2499 -5.86185 69.0936 -19.0817 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.1347 74.0288 -19.1956 73.902 -19.2422 73.7864C-6.05769 68.6394 7.35471 63.7364 20.4413 57.9329C33.5038 52.1407 45.9725 45.3342 58.1299 38.3528L58.3433 38.8086C46.2165 45.8253 33.7542 52.6623 20.6804 58.4497C7.58261 64.2499 -5.86189 69.0936 -19.0817 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5053C-18.5199 75.3865 -18.5857 75.271 -18.6467 75.1442C-5.32581 70.3214 8.20375 65.6237 21.3273 59.8412C34.4187 54.073 46.8456 47.1622 58.9003 40.0411C58.9597 40.1856 59.0335 40.3429 59.1073 40.5001C47.08 47.6501 34.6787 54.5946 21.5824 60.3515C8.43807 66.1293 -5.10433 70.7836 -18.4622 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.461C-20.701 70.331 -20.7395 70.2122 -20.7925 70.0822C-8.03008 64.1006 5.00838 58.6744 17.9168 52.8372C30.8253 47.0001 43.3887 40.521 55.7434 33.899C55.8221 34.0451 55.9039 34.1879 55.989 34.342C43.6487 41.0137 31.0933 47.5153 18.164 53.3508C5.23467 59.1863 -7.83588 64.5564 -20.648 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0822C-20.8455 69.9538 -20.8888 69.8286 -20.9386 69.7051C-8.22113 63.6352 4.78201 58.1656 17.6696 52.3237C30.5572 46.4817 43.127 40.0395 55.5026 33.4513L55.7433 33.9039C43.3854 40.521 30.8268 47.0001 17.9168 52.8421C5.0067 58.684 -8.03015 64.1006 -20.7926 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8444C-20.1569 71.7289 -20.2179 71.6021 -20.2709 71.4801C-7.33835 65.7954 5.85257 60.5794 18.8332 54.7423C31.8139 48.9051 44.3516 42.3217 56.6374 35.5521C56.716 35.6982 56.7979 35.8522 56.8861 36.0031C44.6116 42.8 32.0803 49.4284 19.0932 55.251C6.10615 61.0737 -7.13613 66.2448 -20.1104 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9951C-19.6434 72.8764 -19.6963 72.7464 -19.7461 72.6356C-6.68196 67.2158 6.60846 62.1506 19.6517 56.3295C32.6693 50.5197 45.1701 43.8111 57.4045 36.9452C57.4799 37.0832 57.565 37.2373 57.642 37.3914C45.4446 44.2926 32.9438 51.0333 19.9069 56.8399C6.87007 62.6465 -6.47814 67.6668 -19.5744 72.9951H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3862C-17.5153 77.2674 -17.5811 77.1519 -17.6421 77.0251C-4.16068 72.6725 9.50049 68.2718 22.6689 62.5277C35.7844 56.8078 48.1039 49.7365 59.9868 42.4388C60.0606 42.5993 60.1312 42.7454 60.1938 42.8995C48.3334 50.2308 36.0412 57.3262 22.9241 63.0349C9.75246 68.7709 -3.9408 73.1155 -17.4575 77.383V77.3862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89631C13.614 4.76149 14.6058 4.64112 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C59.836 66.5513 58.8778 67.3618 57.9213 68.1803C54.8639 55.7389 50.5932 43.4291 43.3951 32.5509C36.0461 21.4431 25.6397 12.2453 12.627 4.89631Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89633C12.7652 4.88643 12.9029 4.86929 13.0394 4.84497C26.1132 12.096 36.5645 21.2153 43.9135 32.2893C51.1051 43.1338 55.3357 55.4115 58.3225 67.8369C58.1749 67.9508 58.0497 68.0728 57.9181 68.1803C54.8607 55.7405 50.59 43.4291 43.3919 32.5509C36.0461 21.4432 25.6397 12.2453 12.627 4.89633Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09698C11.2789 5.08735 11.4169 5.06648 11.5485 5.04883C24.3606 12.6401 34.6611 22.0113 42.0085 33.2202C49.2194 44.2187 53.5944 56.5863 56.838 69.0759C56.7064 69.1834 56.5636 69.2861 56.4239 69.3969C53.1178 56.8881 48.6898 44.5028 41.4804 33.4625C34.1347 22.2136 23.8808 12.7669 11.144 5.09698Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.4132C16.8976 4.4132 17.0389 4.39394 17.1817 4.3811C30.9376 10.7142 41.7403 19.1256 49.054 29.7807C56.1671 40.1534 59.9082 52.1904 62.1695 64.4215C62.0491 64.5323 61.9239 64.6559 61.7988 64.7778C59.4636 52.5226 55.6647 40.4631 48.5324 30.0375C41.2123 19.339 30.44 10.8442 16.7516 4.4132Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38116C17.3198 4.36029 17.4626 4.34585 17.6039 4.33301C31.4175 10.5778 42.2588 18.9186 49.5676 29.5304C56.6823 39.8565 60.356 51.8694 62.5371 64.0621C62.4199 64.1808 62.2947 64.3044 62.1696 64.4264C59.9082 52.1904 56.1671 40.1534 49.0541 29.7808C41.7404 19.1256 30.9376 10.7142 17.1818 4.38116Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56569C15.3203 4.54042 15.4587 4.5254 15.5977 4.52075C29.1208 11.2053 39.7968 19.9024 47.1426 30.7212C54.307 41.2737 58.2455 53.4214 60.7845 65.736C60.6561 65.8515 60.5213 65.951 60.3961 66.073C57.772 53.7407 53.7966 41.5754 46.6274 30.9684C39.2864 20.1078 28.6377 11.3561 15.1836 4.56569Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73427C14.0361 4.71341 14.1741 4.69254 14.317 4.6781C27.609 11.6531 38.1919 20.5573 45.5281 31.5061C52.7053 42.2062 56.7898 54.4165 59.5535 66.7905C59.4219 66.898 59.2935 67.0136 59.1539 67.1227C56.31 54.7295 52.1933 42.4919 45.0113 31.7517C37.6671 20.7643 27.1291 11.7879 13.8965 4.73427Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8384 40.5338 -25.7822 39.3622 -25.6763 38.1778C-19.1747 27.3622 -12.1082 19.6119 -3.52824 15.0556C5.16246 10.435 15.3746 8.10299 26.2111 5.86572C27.6 6.41943 28.9596 7.04404 30.2844 7.73707C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3707 -18.4461 31.1113 -25.8608 41.6781V41.6733Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6733C-25.8608 41.5128 -25.8608 41.362 -25.8608 41.1918C-18.5616 30.5993 -10.7055 22.8539 -1.50282 18.0102C7.81541 13.1072 18.4994 10.2456 29.7355 7.453C29.9249 7.55411 30.0999 7.64399 30.2844 7.73708C18.9857 10.6003 8.23429 13.5421 -1.18183 18.4901C-10.4776 23.3675 -18.4461 31.1081 -25.8608 41.6733Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4002C-25.901 43.2397 -25.8914 43.0792 -25.8898 42.9332C-18.154 32.4771 -9.8838 24.759 -0.342491 19.7724C9.31919 14.7265 20.2439 11.5825 31.6919 8.4978C31.8524 8.59249 32.0129 8.70323 32.1734 8.80595C20.6853 11.9628 9.69153 15.1839 -0.0407619 20.2635C-9.67195 25.2918 -18.0529 32.9971 -25.901 43.4002Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6673C-25.7004 38.5068 -25.6908 38.3463 -25.6763 38.1858C-19.1747 27.3702 -12.1082 19.62 -3.52827 15.0636C5.16242 10.435 15.3746 8.10299 26.2111 5.86572L26.8274 6.0856C15.9139 8.42398 5.61983 10.8522 -3.18482 15.5162C-11.8819 20.1271 -19.0736 27.887 -25.7164 38.6673Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.1952C-25.8336 40.0347 -25.824 39.8838 -25.8095 39.7137C-18.8762 28.996 -11.4005 21.2281 -2.49312 16.5192C6.5298 11.7478 17.0052 9.13656 28.0567 6.61682L28.5928 6.88805C17.4722 9.48965 6.92622 12.1843 -2.18657 16.9991C-11.2015 21.7529 -18.7832 29.5111 -25.8448 40.1952Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34079C5.87818 6.13215 6.76249 5.92351 7.67891 5.73413C19.7255 14.1456 29.5702 24.1507 36.8565 35.6998C44.0338 47.0787 48.8117 59.6132 52.6779 72.228C51.6283 72.9518 50.5626 73.6804 49.513 74.4043C45.2295 61.7333 40.2157 49.1218 33.0962 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34079Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34077C5.11584 6.3183 5.23781 6.27817 5.35497 6.24768C16.8719 15.1695 26.3924 25.5181 33.6017 37.2501C40.7292 48.8361 45.7157 61.4332 49.9431 74.0993L49.505 74.4042C45.2214 61.7333 40.2076 49.1218 33.0882 37.5037C25.8949 25.7508 16.4338 15.3396 4.99707 6.34077Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67295C3.76459 6.63925 3.88335 6.61838 4.00051 6.58789C15.2045 15.7793 24.5324 26.3365 31.6952 38.1585C38.7842 49.8584 43.8798 62.4924 48.3062 75.1746C48.1457 75.2725 48.0045 75.3607 47.8488 75.4506C43.3662 62.7717 38.2433 50.1345 31.1672 38.4008C24.0204 26.5484 14.76 15.9366 3.6394 6.67295Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54314C8.85533 5.52067 8.98051 5.48857 9.1073 5.46289C21.4412 13.587 31.4511 23.3547 38.776 34.7882C45.9709 46.0227 50.5963 58.5042 54.2427 71.0788C54.1031 71.188 53.9699 71.2875 53.8303 71.3998C50.1245 58.814 45.4574 46.318 38.2625 35.0369C30.9504 23.5729 20.9677 13.7363 8.7157 5.54153V5.54314Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46293C9.2389 5.44527 9.36088 5.43083 9.48927 5.40515C21.9002 13.4298 31.9695 23.1412 39.2896 34.5282C46.4909 45.7322 51.0891 58.1897 54.6536 70.7451C54.514 70.8542 54.3824 70.9617 54.2427 71.066C50.5963 58.4978 45.9709 46.0227 38.776 34.7882C31.4511 23.3547 21.4412 13.5871 9.1073 5.46293Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80796C7.43174 5.78228 7.55692 5.74858 7.6789 5.73413C19.7255 14.1456 29.5701 24.1507 36.8565 35.6998C44.0338 47.0787 48.8116 59.6132 52.6779 72.228C52.5367 72.3307 52.3826 72.4286 52.2478 72.5281C48.3221 59.8989 43.5138 47.3564 36.3365 35.9357C29.0598 24.3561 19.2537 14.3093 7.30334 5.80796Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06956C6.25536 6.04067 6.38055 6.00697 6.50092 5.9845C18.2827 14.6511 27.9733 24.8248 35.2292 36.4653C42.3871 47.9582 47.2661 60.5296 51.317 73.1701C51.1709 73.2648 51.0297 73.3675 50.8756 73.4654C46.7847 60.8153 41.8591 48.2407 34.7044 36.7125C27.4661 25.0527 17.8365 14.81 6.1366 6.06635L6.13981 6.06956Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.5688 49.5358 -25.6731 48.4894 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C37.4777 12.494 38.5562 13.3286 39.5673 14.1792C27.4613 18.4387 15.6924 22.7046 4.76599 28.2079C-6.05445 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5758C-25.4885 50.4298 -25.5078 50.2949 -25.5206 50.1489C-16.143 40.553 -6.28073 33.1366 4.49156 27.7056C15.3457 22.2183 27.0842 18.0134 39.1548 13.8197C39.2816 13.9272 39.418 14.0588 39.5593 14.1792C27.4613 18.4387 15.6924 22.7046 4.766 28.2079C-6.05444 33.6647 -16.0082 41.0473 -25.4757 50.5758Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1519C-25.3008 52.0219 -25.3297 51.8791 -25.3522 51.7363C-15.6488 42.3731 -5.47351 35.0578 5.51705 29.5545C16.6023 24.0031 28.4804 19.5815 40.6458 15.1615C40.779 15.2851 40.9202 15.4054 41.0534 15.529C28.8672 20.01 16.957 24.4829 5.81075 30.0488C-5.24401 35.5698 -15.5091 42.8498 -25.3008 52.1519Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2217C-25.8192 46.0836 -25.8272 45.9264 -25.8256 45.7739C-17.4174 35.6323 -8.49236 27.9929 1.56895 22.8041C11.7201 17.5656 23.0108 13.977 34.7332 10.4349C34.8937 10.5377 35.0542 10.6484 35.2147 10.7559C23.4409 14.375 12.1037 18.0182 1.86105 23.292C-8.26767 28.5097 -17.297 36.1315 -25.8144 46.2265L-25.8079 46.2217Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7739C-25.8255 45.6262 -25.8367 45.477 -25.8416 45.3277C-17.5392 35.13 -8.71856 27.4841 1.25448 22.3275C11.3318 17.1147 22.5583 13.5934 34.247 10.13L34.7285 10.4398C23.0125 13.9834 11.7154 17.5705 1.56423 22.809C-8.48905 27.9929 -17.4173 35.6323 -25.8255 45.7739Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8747C-25.7453 47.7287 -25.7502 47.5794 -25.7694 47.4446C-16.9856 37.4764 -7.6979 29.9316 2.63785 24.6401C13.0699 19.2957 24.5371 15.468 36.4104 11.6659C36.5597 11.7831 36.7089 11.9002 36.855 12.0093C24.9448 15.8692 13.4342 19.766 2.93476 25.1313C-7.46679 30.4468 -16.8476 37.9707 -25.7309 47.8747Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2325C-25.6105 49.0849 -25.6298 48.9388 -25.6394 48.8008C-16.5571 39.0236 -6.98209 31.5333 3.57032 26.1713C14.2158 20.7466 25.8227 16.7311 37.7906 12.73L38.2384 13.0815C26.2303 17.1435 14.5769 21.2233 3.86242 26.6592C-6.76061 32.0469 -16.4303 39.5131 -25.6105 49.2325Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47698C-1.12893 8.16883 -0.326466 7.8655 0.508097 7.61353C10.815 17.5111 19.5329 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C42.6038 78.537 41.4595 79.1645 40.32 79.7824C35.0622 67.1452 29.5509 54.4695 22.7685 42.3875C15.8971 30.1522 7.60656 18.77 -1.9314 8.47698Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47699C-1.82066 8.43045 -1.70992 8.38551 -1.61041 8.35181C8.01522 18.5651 16.3682 29.9069 23.266 42.1292C30.0821 54.2079 35.5806 66.8868 40.7951 79.5256C40.6346 79.6155 40.4741 79.7022 40.3136 79.7824C35.0558 67.1452 29.5445 54.4695 22.7621 42.3876C15.8927 30.1525 7.60431 18.7704 -1.9314 8.47699Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93915C-3.03721 8.89421 -2.93128 8.86051 -2.82696 8.81396C6.46005 19.2952 14.5676 30.7648 21.3497 43.0166C28.0663 55.1531 33.6306 67.8096 39.0039 80.4211C38.8515 80.5014 38.6829 80.5816 38.5225 80.6619C33.1138 68.06 27.5191 55.4179 20.8329 43.267C14.0861 31.0021 6.04672 19.494 -3.14795 8.93915Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32142C1.55612 7.29092 1.66847 7.25401 1.79044 7.21228C12.4375 16.8563 21.3931 27.7265 28.4564 39.7025C35.4506 51.5629 40.7212 64.2354 45.4686 76.9224C45.3242 77.0171 45.1669 77.1086 45.0128 77.2065C40.2141 64.5163 34.9226 51.8486 27.9364 39.9529C20.8891 27.9656 11.9946 17.0377 1.43896 7.32142Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21223C1.91455 7.18221 2.03148 7.14359 2.14516 7.09668C12.8709 16.6797 21.8954 27.5002 28.97 39.4424C35.9803 51.2836 41.2252 63.9513 45.9116 76.6351C45.7736 76.7266 45.6211 76.8245 45.4638 76.9159C40.7213 64.237 35.4507 51.5581 28.4516 39.6976C21.3931 27.7265 12.4376 16.8563 1.79529 7.21223Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72106C0.277004 7.68093 0.394164 7.65044 0.506509 7.61353C10.815 17.5111 19.533 28.5627 26.5224 40.6093C33.4493 52.5516 38.8178 65.2369 43.7481 77.9095C43.5876 77.9913 43.4272 78.0828 43.2667 78.1663C38.2914 65.4873 32.9052 52.8164 25.9944 40.8404C19.037 28.7761 10.372 17.6909 0.155029 7.72106Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.0789C-0.788642 8.03717 -0.674692 8.00026 -0.570371 7.95532C9.33431 17.9333 17.894 29.1615 24.8918 41.3555C31.7722 53.37 37.1888 66.0538 42.2604 78.7119C42.1079 78.8114 41.9555 78.89 41.7966 78.9735C36.6785 66.3154 31.2233 53.6381 24.3751 41.6027C17.4115 29.3999 8.92095 18.1346 -0.891357 8.0789Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.3346 57.883 -24.5272 56.8928 -24.7278 55.9058C-14.6062 47.4874 -3.51314 40.311 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C45.3964 19.8366 46.2856 20.7738 47.0993 21.7496C34.661 27.1053 22.3031 32.4048 10.311 38.1761C-1.61037 43.9137 -13.0808 50.64 -24.1372 58.862Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8621C-24.1677 58.7225 -24.1966 58.5829 -24.2223 58.4513C-13.2317 50.1586 -1.8271 43.397 10.0494 37.677C21.982 31.9105 34.3127 26.6576 46.7557 21.3405C46.8681 21.4801 46.9869 21.6101 47.0992 21.7513C34.661 27.1054 22.303 32.4048 10.311 38.1762C-1.61043 43.9122 -13.0809 50.6401 -24.1372 58.8621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3386C-23.8354 60.2022 -23.8707 60.0738 -23.9044 59.9438C-12.6523 51.9191 -0.997252 45.3068 11.0381 39.5451C23.1393 33.7513 35.539 28.3283 48.0029 22.8346C48.1152 22.9758 48.2292 23.1155 48.3351 23.2422C35.8712 28.7809 23.4587 34.2505 11.3158 40.0459C-0.769354 45.8156 -12.4805 52.3814 -23.7985 60.3386Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7792C-24.9316 54.6364 -24.9524 54.4935 -24.9701 54.3587C-14.7403 45.3807 -4.05143 38.2805 7.30666 32.6713C18.753 27.0171 30.8269 22.2569 43.1319 17.4774C43.2571 17.6042 43.3855 17.739 43.4994 17.861C31.1832 22.6983 19.0756 27.5098 7.57628 33.1833C-3.83958 38.8005 -14.6103 45.875 -24.9091 54.7792Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3586C-24.9965 54.2222 -25.0158 54.0845 -25.028 53.9462C-14.8832 44.8992 -4.27781 37.7588 7.03213 32.1592C18.4271 26.5211 30.4641 21.8138 42.7627 17.0938C42.8879 17.2205 43.0098 17.3393 43.1318 17.4773C30.8268 22.2568 18.7529 27.017 7.30657 32.6712C-4.05152 38.2804 -14.7404 45.3806 -24.9702 54.3586Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3022C-24.6732 56.1706 -24.6989 56.039 -24.7278 55.8993C-14.6058 47.4832 -3.51282 40.309 8.31451 34.5297C19.9567 28.829 32.1461 23.8746 44.5057 18.8993C44.6245 19.0293 44.7496 19.1561 44.8748 19.2829C32.4912 24.3175 20.2857 29.3121 8.6034 35.0256C-3.26448 40.8094 -14.422 47.9493 -24.6459 56.3022Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5782C-24.4181 57.4466 -24.455 57.3102 -24.4742 57.1753C-13.7212 48.6692 -2.52201 41.7969 9.20521 36.0994C20.995 30.3682 33.2519 25.2565 45.6644 20.1111C45.7816 20.2411 45.91 20.3759 46.0335 20.4947C33.6146 25.6946 21.3401 30.8545 9.49089 36.5969C-2.28448 42.3057 -13.5479 49.1378 -24.3924 57.5782Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.406C-7.48127 10.9871 -6.75905 10.5826 -5.98708 10.2247C2.31446 21.3572 9.72289 33.1286 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C32.8843 83.2121 31.6694 83.7353 30.424 84.2088C24.4231 71.8942 18.6117 59.4463 12.399 47.1831C6.17668 34.8925 -0.637851 22.886 -8.20349 11.406Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4059L-7.89855 11.2278C-0.236618 22.6693 6.65816 34.6405 12.919 46.9439C19.1606 59.2104 24.964 71.6823 30.9327 84.0178C30.7722 84.09 30.6021 84.1542 30.424 84.2168C24.4231 71.8941 18.6117 59.4463 12.399 47.183C6.17668 34.8925 -0.637851 22.886 -8.20349 11.4059Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0415C-9.19845 11.9725 -9.09894 11.9244 -9.00265 11.8666C-1.74355 23.4718 4.86876 35.5361 10.9916 47.817C17.1144 60.0979 22.9451 72.4816 29.0294 84.7176C28.8689 84.785 28.6972 84.8524 28.5206 84.9166C22.401 72.6934 16.5575 60.3242 10.4716 48.0594C4.38568 35.7945 -2.14318 23.6885 -9.29956 12.0415Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81864C-5.03371 9.76567 -4.93099 9.72395 -4.83148 9.67419C3.85406 20.5773 11.53 32.2477 18.1014 44.5413C24.6527 56.7548 30.3277 69.3647 35.953 81.9057C35.7797 81.9763 35.624 82.0485 35.4507 82.1191C29.7933 69.6151 24.1102 57.0052 17.5814 44.7804C11.0387 32.4877 3.43563 20.7893 -5.14124 9.81864Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67418C-4.73036 9.62122 -4.62123 9.57628 -4.5169 9.52332C4.25997 20.3671 12.0033 32.008 18.6117 44.2941C25.192 56.4916 30.8477 69.1144 36.4265 81.6553C36.266 81.7468 36.1151 81.8158 35.945 81.9057C30.3277 69.3631 24.6527 56.7548 18.1014 44.5413C11.5279 32.2474 3.84988 20.577 -4.83789 9.67418Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3724C-6.20163 10.3153 -6.09434 10.2659 -5.98382 10.2247C2.31661 21.3573 9.72397 33.1287 16.169 45.4289C22.6112 57.6665 28.3424 70.2299 34.1009 82.697C33.9276 82.7692 33.7575 82.8478 33.6002 82.912C27.8016 70.4594 22.0623 57.9104 15.6474 45.66C9.23325 33.359 1.89477 21.5626 -6.30481 10.3724Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.865L-6.96765 10.6869C1.01468 22.0068 8.20119 33.8674 14.54 46.1831C20.8779 58.4416 26.6572 70.9616 32.5233 83.3774C32.3628 83.4448 32.1878 83.5234 32.0209 83.5908C26.118 71.1911 20.3274 58.7016 14.0152 46.4239C7.68857 34.1269 0.626882 22.1894 -7.27258 10.865Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6813C-22.3027 65.7569 -22.5948 64.8164 -22.8837 63.8839C-10.9911 56.6152 1.2593 50.4202 13.6927 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.9301 27.9495 52.6668 28.9671 53.3232 30.0071C40.8626 36.2759 28.2959 42.4132 15.6074 48.2744C2.93966 54.1227 -9.70238 59.9711 -22.0122 66.6765L-22.017 66.6813Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6813C-22.0556 66.5641 -22.0941 66.4277 -22.139 66.2945C-9.87898 59.512 2.7117 53.6043 15.3521 47.7575C28.0182 41.8979 40.5864 35.8056 53.0535 29.5785C53.1402 29.7213 53.2365 29.869 53.3232 30.0118C40.8625 36.2807 28.2959 42.4179 15.6073 48.2791C2.9396 54.1275 -9.70243 59.9759 -22.0122 66.6813H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1065C-21.5901 67.9765 -21.6399 67.8545 -21.6848 67.7213C-9.23056 61.2342 3.54308 55.4997 16.2846 49.6562C29.055 43.7998 41.6233 37.555 54.0695 31.1851C54.1562 31.3295 54.2508 31.4691 54.3359 31.612C41.9121 38.0317 29.3391 44.3118 16.5478 50.1634C3.75654 56.0149 -9.03636 61.6884 -21.5356 68.1065Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8086C-23.2079 62.6883 -23.2368 62.5486 -23.2737 62.4122C-11.6042 54.869 0.437553 48.5071 12.7329 42.7005C25.0732 36.8746 37.5612 31.1803 50.0684 25.3865C50.1727 25.5309 50.2674 25.6705 50.3685 25.807C37.8709 31.6425 25.3621 37.3769 12.9945 43.2108C0.657429 49.0223 -11.4486 55.3313 -23.1758 62.8086Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.4122C-23.3075 62.2918 -23.3444 62.1554 -23.3749 62.0157C-11.7728 54.3891 0.21759 47.9871 12.4632 42.1852C24.7843 36.3513 37.261 30.7164 49.7473 24.9772C49.8468 25.1072 49.9512 25.25 50.0539 25.3864C37.5627 31.1802 25.0731 36.8745 12.7328 42.7004C0.437466 48.5071 -11.6043 54.8642 -23.277 62.4122Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2627C-22.8082 64.1375 -22.8451 64.0011 -22.8837 63.8839C-10.9911 56.6152 1.25929 50.4202 13.6911 44.5975C26.1742 38.7507 38.6975 32.8991 51.1871 26.9432C51.285 27.0716 51.3909 27.2161 51.4952 27.3589C39.0056 33.3507 26.4963 39.2589 13.9671 45.0838C1.49683 50.9048 -10.813 57.0646 -22.7665 64.2562V64.2627Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4792C-22.4343 65.3476 -22.4792 65.2144 -22.5129 65.086C-10.4374 58.0596 1.99113 52.001 14.5369 46.1671C27.1131 40.3187 39.6481 34.3462 52.142 28.2496C52.2479 28.3941 52.349 28.5305 52.4517 28.6669C39.975 34.8095 27.4277 40.8136 14.8097 46.679C2.23026 52.5178 -10.2545 58.525 -22.3894 65.4792Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.2028 14.8035 -12.5528 14.253 -11.8659 13.7667C-5.71258 25.7796 0.0747986 38.0012 5.82687 50.2259C11.5645 62.4234 17.4674 74.5583 23.8486 86.4973C22.5647 86.8975 21.2807 87.2688 19.9968 87.6112C13.423 75.961 7.51044 64.0765 2.08096 51.9817C-3.34852 39.887 -8.53084 27.5868 -13.8464 15.354H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3541C-13.7661 15.2738 -13.6762 15.1936 -13.5815 15.1182C-8.15368 27.3381 -2.89432 39.6206 2.59454 51.7427C8.0834 63.8647 13.9895 75.7733 20.5232 87.4716L19.9904 87.6096C13.423 75.9611 7.51044 64.0766 2.08096 51.9818C-3.34852 39.887 -8.53084 27.5869 -13.8464 15.3541H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.2031C-14.758 16.1165 -14.6713 16.0426 -14.5815 15.964C-9.59173 28.2834 -4.65978 40.566 0.681424 52.6318C6.01356 64.721 11.9749 76.5226 18.5411 87.9884C18.3581 88.0366 18.1752 88.0847 18.0083 88.1249C11.4042 76.7169 5.44385 64.9482 0.155007 52.8742C-5.12842 40.8372 -9.97531 28.537 -14.8591 16.2031H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2307L-10.8195 13.0365C-4.23929 24.9034 1.85945 37.088 7.75115 49.3416C13.6428 61.5953 19.5185 73.8264 25.8018 85.8843C25.6204 85.9404 25.4407 85.9966 25.277 86.0448C18.9696 74.0158 13.0795 61.8103 7.23115 49.5792C1.38278 37.348 -4.64533 25.1473 -11.0988 13.2307Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0366C-10.7264 12.9708 -10.6317 12.9066 -10.5225 12.8359C-3.85727 24.6771 2.34257 36.8329 8.27921 49.0993C14.2158 61.3658 20.069 73.6275 26.3299 85.7126C26.1533 85.7768 25.9736 85.833 25.8018 85.8843C19.5185 73.8265 13.6365 61.5745 7.75119 49.3417C1.86591 37.1089 -4.24566 24.9034 -10.8195 13.0366Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.9642L-11.8659 13.7635C-5.71261 25.7765 0.0747623 37.998 5.82683 50.2227C11.5645 62.4202 17.4674 74.5551 23.8486 86.4942L23.3158 86.6547C16.9025 74.7509 11.0043 62.6465 5.30363 50.4571C-0.397086 38.2676 -6.11706 26.0076 -12.1532 13.9642Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6254C-12.9131 14.559 -12.8262 14.4855 -12.7454 14.4055C-6.94355 26.5324 -1.40173 38.8005 4.21553 50.9722C9.83278 63.144 15.7405 75.1842 22.2052 87.0077L21.6724 87.1682C15.1788 75.3929 9.27266 63.3735 3.70195 51.2274C-1.8816 39.0556 -7.33194 26.7795 -13.0054 14.6254Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1571C-19.4765 73.2584 -19.8729 72.3788 -20.2677 71.4801C-7.33838 65.7954 5.85254 60.5778 18.8332 54.7423C31.8139 48.9067 44.3516 42.3217 56.6373 35.5521C57.2263 36.629 57.8057 37.7108 58.3434 38.8005C46.2165 45.8253 33.7542 52.6623 20.6805 58.4513C7.58265 64.2499 -5.86185 69.0936 -19.0817 74.1571Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.1572C-19.1347 74.0288 -19.1956 73.902 -19.2422 73.7864C-6.05769 68.6394 7.35471 63.7364 20.4413 57.9329C33.5038 52.1407 45.9725 45.3342 58.1299 38.3528L58.3433 38.8086C46.2165 45.8253 33.7542 52.6623 20.6804 58.4497C7.58261 64.2499 -5.86189 69.0936 -19.0817 74.1572Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5053C-18.5199 75.3865 -18.5857 75.271 -18.6467 75.1442C-5.32581 70.3214 8.20375 65.6237 21.3273 59.8412C34.4187 54.073 46.8456 47.1622 58.9003 40.0411C58.9597 40.1856 59.0335 40.3429 59.1073 40.5001C47.08 47.6501 34.6787 54.5946 21.5824 60.3515C8.43807 66.1293 -5.10433 70.7836 -18.4622 75.5053Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.461C-20.701 70.331 -20.7395 70.2122 -20.7925 70.0822C-8.03008 64.1006 5.00838 58.6744 17.9168 52.8372C30.8253 47.0001 43.3887 40.521 55.7434 33.899C55.8221 34.0451 55.9039 34.1879 55.989 34.342C43.6487 41.0137 31.0933 47.5153 18.164 53.3508C5.23467 59.1863 -7.83588 64.5564 -20.648 70.461Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0822C-20.8455 69.9538 -20.8888 69.8286 -20.9386 69.7051C-8.22113 63.6352 4.78201 58.1656 17.6696 52.3237C30.5572 46.4817 43.127 40.0395 55.5026 33.4513L55.7433 33.9039C43.3854 40.521 30.8268 47.0001 17.9168 52.8421C5.0067 58.684 -8.03015 64.1006 -20.7926 70.0822Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8444C-20.1569 71.7289 -20.2179 71.6021 -20.2709 71.4801C-7.33835 65.7954 5.85257 60.5794 18.8332 54.7423C31.8139 48.9051 44.3516 42.3217 56.6374 35.5521C56.716 35.6982 56.7979 35.8522 56.8861 36.0031C44.6116 42.8 32.0803 49.4284 19.0932 55.251C6.10615 61.0737 -7.13613 66.2448 -20.1104 71.8444Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.9951C-19.6434 72.8764 -19.6963 72.7464 -19.7461 72.6356C-6.68196 67.2158 6.60846 62.1506 19.6517 56.3295C32.6693 50.5197 45.1701 43.8111 57.4045 36.9452C57.4799 37.0832 57.565 37.2373 57.642 37.3914C45.4446 44.2926 32.9438 51.0333 19.9069 56.8399C6.87007 62.6465 -6.47814 67.6668 -19.5744 72.9951H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4575 77.3862C-17.5153 77.2674 -17.5811 77.1519 -17.6421 77.0251C-4.16068 72.6725 9.50049 68.2718 22.6689 62.5277C35.7844 56.8078 48.1039 49.7365 59.9868 42.4388C60.0606 42.5993 60.1312 42.7454 60.1938 42.8995C48.3334 50.2308 36.0412 57.3262 22.9241 63.0349C9.75246 68.7709 -3.9408 73.1155 -17.4575 77.383V77.3862Z"
          fill="#650028"
        />
      </g>
      <path
        d="M7.00806 34.9873L12.6638 31.7004L21.7718 36.9598L16.116 40.2467L7.00806 34.9873Z"
        fill="#DB5B54"
      />
      <mask
        id="mask4"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="7"
        y="31"
        width="15"
        height="10"
      >
        <path
          d="M7.00806 34.9873L12.6638 31.7004L21.7718 36.9598L16.116 40.2467L7.00806 34.9873Z"
          fill="#DB5B54"
        />
      </mask>
      <g mask="url(#mask4)">
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89618C13.614 4.76137 14.6058 4.641 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C59.836 66.5512 58.8778 67.3617 57.9213 68.1802C54.8639 55.7388 50.5932 43.4289 43.3951 32.5507C36.0461 21.443 25.6397 12.2452 12.627 4.89618Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89621C12.7652 4.88631 12.9029 4.86917 13.0394 4.84485C26.1132 12.0959 36.5645 21.2151 43.9135 32.2892C51.1051 43.1337 55.3357 55.4114 58.3225 67.8368C58.1749 67.9507 58.0497 68.0727 57.9181 68.1802C54.8607 55.7404 50.59 43.429 43.3919 32.5508C36.0461 21.443 25.6397 12.2452 12.627 4.89621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09685C11.2789 5.08722 11.4169 5.06636 11.5485 5.04871C24.3606 12.64 34.6611 22.0112 42.0085 33.22C49.2194 44.2186 53.5944 56.5862 56.838 69.0758C56.7064 69.1833 56.5636 69.286 56.4239 69.3968C53.1178 56.8879 48.6898 44.5027 41.4804 33.4624C34.1347 22.2134 23.8808 12.7668 11.144 5.09685Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.41308C16.8976 4.41308 17.0389 4.39382 17.1817 4.38098C30.9376 10.714 41.7403 19.1255 49.054 29.7806C56.1671 40.1533 59.9082 52.1902 62.1695 64.4214C62.0491 64.5322 61.9239 64.6557 61.7988 64.7777C59.4636 52.5225 55.6647 40.463 48.5324 30.0374C41.2123 19.3389 30.44 10.844 16.7516 4.41308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38103C17.3198 4.36017 17.4626 4.34573 17.6039 4.33289C31.4175 10.5777 42.2588 18.9185 49.5676 29.5303C56.6823 39.8564 60.356 51.8693 62.5371 64.062C62.4199 64.1807 62.2947 64.3043 62.1696 64.4263C59.9082 52.1903 56.1671 40.1533 49.0541 29.7807C41.7404 19.1255 30.9376 10.7141 17.1818 4.38103Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56557C15.3203 4.5403 15.4587 4.52528 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C60.6561 65.8514 60.5213 65.9509 60.3961 66.0729C57.772 53.7406 53.7966 41.5753 46.6274 30.9683C39.2864 20.1077 28.6377 11.356 15.1836 4.56557Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73415C14.0361 4.71329 14.1741 4.69242 14.317 4.67798C27.609 11.653 38.1919 20.5572 45.5281 31.506C52.7053 42.2061 56.7898 54.4164 59.5535 66.7904C59.4219 66.8979 59.2935 67.0135 59.1539 67.1226C56.31 54.7293 52.1933 42.4917 45.0113 31.7515C37.6671 20.7642 27.1291 11.7878 13.8965 4.73415Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8384 40.5337 -25.7822 39.3621 -25.6763 38.1777C-19.1747 27.362 -12.1082 19.6118 -3.52824 15.0554C5.16246 10.4348 15.3746 8.10287 26.2111 5.8656C27.6 6.41931 28.9596 7.04391 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3706 -18.4461 31.1111 -25.8608 41.678V41.6732Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8608 41.5127 -25.8608 41.3618 -25.8608 41.1917C-18.5616 30.5992 -10.7055 22.8538 -1.50282 18.0101C7.81541 13.107 18.4994 10.2455 29.7355 7.45288C29.9249 7.55399 30.0999 7.64387 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3674 -18.4461 31.1079 -25.8608 41.6732Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4001C-25.901 43.2396 -25.8914 43.0791 -25.8898 42.9331C-18.154 32.4769 -9.8838 24.7588 -0.342491 19.7723C9.31919 14.7264 20.2439 11.5824 31.6919 8.49768C31.8524 8.59237 32.0129 8.70311 32.1734 8.80583C20.6853 11.9627 9.69153 15.1838 -0.0407619 20.2634C-9.67195 25.2917 -18.0529 32.9969 -25.901 43.4001Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8391C-25.5592 36.669 -25.5335 36.4988 -25.5159 36.34C-19.5182 25.4264 -12.9605 17.6987 -4.77857 13.3381C3.50287 8.92294 13.3957 6.93443 23.9641 5.05505C24.1583 5.12728 24.3654 5.20271 24.5676 5.27172C13.9333 7.25702 3.95707 9.33219 -4.44795 13.8083C-12.7246 18.2155 -19.422 25.948 -25.5592 36.8391Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.34C-25.487 36.1586 -25.4677 35.9821 -25.4292 35.8055C-19.5857 24.8824 -13.166 17.1787 -5.11883 12.8887C3.04384 8.53294 12.8339 6.63591 23.3109 4.85925C23.5332 4.90881 23.7516 4.97479 23.9641 5.05666C13.3956 6.93443 3.50285 8.92294 -4.77859 13.3381C-12.9637 17.6987 -19.5183 25.4184 -25.5143 36.34Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6672C-25.7004 38.5067 -25.6908 38.3462 -25.6763 38.1857C-19.1747 27.3701 -12.1082 19.6199 -3.52827 15.0635C5.16242 10.4348 15.3746 8.10287 26.2111 5.8656L26.8274 6.08548C15.9139 8.42386 5.61983 10.8521 -3.18482 15.516C-11.8819 20.127 -19.0736 27.8868 -25.7164 38.6672Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.195C-25.8336 40.0346 -25.824 39.8837 -25.8095 39.7136C-18.8762 28.9958 -11.4005 21.228 -2.49312 16.5191C6.5298 11.7477 17.0052 9.13644 28.0567 6.6167L28.5928 6.88793C17.4722 9.48952 6.92622 12.1842 -2.18657 16.999C-11.2015 21.7528 -18.7832 29.511 -25.8448 40.195Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34067C5.87818 6.13203 6.76249 5.92339 7.67891 5.73401C19.7255 14.1454 29.5702 24.1506 36.8565 35.6997C44.0338 47.0786 48.8117 59.6131 52.6779 72.2279C51.6283 72.9517 50.5626 73.6803 49.513 74.4041C45.2295 61.7332 40.2157 49.1217 33.0962 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34067Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34064C5.11584 6.31818 5.23781 6.27805 5.35497 6.24756C16.8719 15.1694 26.3924 25.518 33.6017 37.25C40.7292 48.836 45.7157 61.4331 49.9431 74.0992L49.505 74.4041C45.2214 61.7332 40.2076 49.1217 33.0882 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34064Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67283C3.76459 6.63913 3.88335 6.61826 4.00051 6.58777C15.2045 15.7792 24.5324 26.3364 31.6952 38.1583C38.7842 49.8583 43.8798 62.4923 48.3062 75.1744C48.1457 75.2723 48.0045 75.3606 47.8488 75.4505C43.3662 62.7715 38.2433 50.1343 31.1672 38.4007C24.0204 26.5483 14.76 15.9365 3.6394 6.67283Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54302C8.85533 5.52055 8.98051 5.48845 9.1073 5.46277C21.4412 13.5869 31.4511 23.3545 38.776 34.788C45.9709 46.0226 50.5963 58.5041 54.2427 71.0787C54.1031 71.1879 53.9699 71.2874 53.8303 71.3997C50.1245 58.8139 45.4574 46.3179 38.2625 35.0368C30.9504 23.5728 20.9677 13.7362 8.7157 5.54141V5.54302Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46281C9.2389 5.44515 9.36088 5.43071 9.48927 5.40503C21.9002 13.4297 31.9695 23.1411 39.2896 34.5281C46.4909 45.7321 51.0891 58.1896 54.6536 70.7449C54.514 70.8541 54.3824 70.9616 54.2427 71.0659C50.5963 58.4977 45.9709 46.0226 38.776 34.7881C31.4511 23.3546 21.4412 13.587 9.1073 5.46281Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80784C7.43174 5.78216 7.55692 5.74845 7.6789 5.73401C19.7255 14.1454 29.5701 24.1506 36.8565 35.6997C44.0338 47.0786 48.8116 59.6131 52.6779 72.2279C52.5367 72.3306 52.3826 72.4285 52.2478 72.528C48.3221 59.8988 43.5138 47.3563 36.3365 35.9356C29.0598 24.356 19.2537 14.3091 7.30334 5.80784Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06944C6.25536 6.04055 6.38055 6.00684 6.50092 5.98438C18.2827 14.651 27.9733 24.8246 35.2292 36.4652C42.3871 47.9581 47.2661 60.5295 51.317 73.17C51.1709 73.2646 51.0297 73.3674 50.8756 73.4653C46.7847 60.8152 41.8591 48.2406 34.7044 36.7124C27.4661 25.0525 17.8365 14.8099 6.1366 6.06623L6.13981 6.06944Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.5688 49.5357 -25.6731 48.4893 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C37.4777 12.4939 38.5562 13.3285 39.5673 14.1791C27.4613 18.4386 15.6924 22.7045 4.76599 28.2078C-6.05445 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.4885 50.4296 -25.5078 50.2948 -25.5206 50.1488C-16.143 40.5529 -6.28073 33.1365 4.49156 27.7054C15.3457 22.2182 27.0842 18.0133 39.1548 13.8196C39.2816 13.9271 39.418 14.0587 39.5593 14.1791C27.4613 18.4386 15.6924 22.7045 4.766 28.2078C-6.05444 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1518C-25.3008 52.0218 -25.3297 51.879 -25.3522 51.7361C-15.6488 42.373 -5.47351 35.0577 5.51705 29.5544C16.6023 24.0029 28.4804 19.5814 40.6458 15.1614C40.779 15.285 40.9202 15.4053 41.0534 15.5289C28.8672 20.0099 16.957 24.4828 5.81075 30.0487C-5.24401 35.5697 -15.5091 42.8496 -25.3008 52.1518Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2215C-25.8192 46.0835 -25.8272 45.9262 -25.8256 45.7738C-17.4174 35.6322 -8.49236 27.9928 1.56895 22.804C11.7201 17.5655 23.0108 13.9769 34.7332 10.4348C34.8937 10.5375 35.0542 10.6483 35.2147 10.7558C23.4409 14.3749 12.1037 18.0181 1.86105 23.2919C-8.26767 28.5095 -17.297 36.1313 -25.8144 46.2264L-25.8079 46.2215Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7738C-25.8255 45.6261 -25.8367 45.4769 -25.8416 45.3276C-17.5392 35.1299 -8.71856 27.484 1.25448 22.3273C11.3318 17.1145 22.5583 13.5933 34.247 10.1299L34.7285 10.4396C23.0125 13.9833 11.7154 17.5703 1.56423 22.8088C-8.48905 27.9928 -17.4173 35.6322 -25.8255 45.7738Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8746C-25.7453 47.7285 -25.7502 47.5793 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C36.5597 11.7829 36.7089 11.9001 36.855 12.0092C24.9448 15.8691 13.4342 19.7659 2.93476 25.1311C-7.46679 30.4467 -16.8476 37.9706 -25.7309 47.8746Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2324C-25.6105 49.0847 -25.6298 48.9387 -25.6394 48.8007C-16.5571 39.0234 -6.98209 31.5332 3.57032 26.1711C14.2158 20.7465 25.8227 16.7309 37.7906 12.7299L38.2384 13.0813C26.2303 17.1434 14.5769 21.2231 3.86242 26.659C-6.76061 32.0468 -16.4303 39.5129 -25.6105 49.2324Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47686C-1.12893 8.16871 -0.326466 7.86538 0.508097 7.6134C10.815 17.511 19.5329 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C42.6038 78.5369 41.4595 79.1644 40.32 79.7823C35.0622 67.1451 29.5509 54.4693 22.7685 42.3874C15.8971 30.1521 7.60656 18.7699 -1.9314 8.47686Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47687C-1.82066 8.43033 -1.70992 8.38539 -1.61041 8.35168C8.01522 18.565 16.3682 29.9067 23.266 42.129C30.0821 54.2077 35.5806 66.8867 40.7951 79.5255C40.6346 79.6154 40.4741 79.7021 40.3136 79.7823C35.0558 67.1451 29.5445 54.4693 22.7621 42.3874C15.8927 30.1524 7.60431 18.7703 -1.9314 8.47687Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93903C-3.03721 8.89409 -2.93128 8.86039 -2.82696 8.81384C6.46005 19.295 14.5676 30.7647 21.3497 43.0165C28.0663 55.153 33.6306 67.8095 39.0039 80.421C38.8515 80.5013 38.6829 80.5815 38.5225 80.6617C33.1138 68.0598 27.5191 55.4178 20.8329 43.2669C14.0861 31.002 6.04672 19.4939 -3.14795 8.93903Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32129C1.55612 7.2908 1.66847 7.25389 1.79044 7.21216C12.4375 16.8562 21.3931 27.7264 28.4564 39.7024C35.4506 51.5628 40.7212 64.2353 45.4686 76.9223C45.3242 77.017 45.1669 77.1085 45.0128 77.2064C40.2141 64.5162 34.9226 51.8485 27.9364 39.9527C20.8891 27.9655 11.9946 17.0375 1.43896 7.32129Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21211C1.91455 7.18209 2.03148 7.14347 2.14516 7.09656C12.8709 16.6796 21.8954 27.5 28.97 39.4423C35.9803 51.2835 41.2252 63.9512 45.9116 76.635C45.7736 76.7264 45.6211 76.8243 45.4638 76.9158C40.7213 64.2369 35.4507 51.5579 28.4516 39.6975C21.3931 27.7263 12.4376 16.8561 1.79529 7.21211Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72093C0.277004 7.68081 0.394164 7.65032 0.506509 7.6134C10.815 17.511 19.533 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C43.5876 77.9912 43.4272 78.0827 43.2667 78.1661C38.2914 65.4872 32.9052 52.8163 25.9944 40.8403C19.037 28.776 10.372 17.6908 0.155029 7.72093Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07878C-0.788642 8.03705 -0.674692 8.00014 -0.570371 7.9552C9.33431 17.9331 17.894 29.1614 24.8918 41.3554C31.7722 53.3699 37.1888 66.0537 42.2604 78.7117C42.1079 78.8113 41.9555 78.8899 41.7966 78.9734C36.6785 66.3153 31.2233 53.6379 24.3751 41.6026C17.4115 29.3998 8.92095 18.1344 -0.891357 8.07878Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8619C-24.3346 57.8829 -24.5272 56.8927 -24.7278 55.9056C-14.6062 47.4873 -3.51314 40.3108 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C45.3964 19.8364 46.2856 20.7737 47.0993 21.7495C34.661 27.1052 22.3031 32.4047 10.311 38.176C-1.61037 43.9136 -13.0808 50.6399 -24.1372 58.8619Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.1677 58.7224 -24.1966 58.5827 -24.2223 58.4511C-13.2317 50.1585 -1.8271 43.3969 10.0494 37.6769C21.982 31.9104 34.3127 26.6575 46.7557 21.3403C46.8681 21.48 46.9869 21.61 47.0992 21.7512C34.661 27.1052 22.303 32.4047 10.311 38.176C-1.61043 43.9121 -13.0809 50.6399 -24.1372 58.862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3385C-23.8354 60.2021 -23.8707 60.0737 -23.9044 59.9437C-12.6523 51.919 -0.997252 45.3067 11.0381 39.545C23.1393 33.7512 35.539 28.3281 48.0029 22.8345C48.1152 22.9757 48.2292 23.1153 48.3351 23.2421C35.8712 28.7807 23.4587 34.2503 11.3158 40.0457C-0.769354 45.8155 -12.4805 52.3812 -23.7985 60.3385Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7791C-24.9316 54.6362 -24.9524 54.4934 -24.9701 54.3586C-14.7403 45.3806 -4.05143 38.2804 7.30666 32.6712C18.753 27.017 30.8269 22.2568 43.1319 17.4773C43.2571 17.6041 43.3855 17.7389 43.4994 17.8609C31.1832 22.6981 19.0756 27.5097 7.57628 33.1831C-3.83958 38.8004 -14.6103 45.8749 -24.9091 54.7791Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3585C-24.9965 54.2221 -25.0158 54.0844 -25.028 53.946C-14.8832 44.899 -4.27781 37.7587 7.03213 32.1591C18.4271 26.521 30.4641 21.8137 42.7627 17.0936C42.8879 17.2204 43.0098 17.3392 43.1318 17.4772C30.8268 22.2567 18.7529 27.0169 7.30657 32.6711C-4.05152 38.2803 -14.7404 45.3805 -24.9702 54.3585Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3021C-24.6732 56.1705 -24.6989 56.0389 -24.7278 55.8992C-14.6058 47.4831 -3.51282 40.3088 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C44.6245 19.0292 44.7496 19.156 44.8748 19.2827C32.4912 24.3174 20.2857 29.312 8.6034 35.0255C-3.26448 40.8093 -14.422 47.9491 -24.6459 56.3021Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5781C-24.4181 57.4464 -24.455 57.31 -24.4742 57.1752C-13.7212 48.6691 -2.52201 41.7968 9.20521 36.0993C20.995 30.3681 33.2519 25.2564 45.6644 20.111C45.7816 20.241 45.91 20.3758 46.0335 20.4945C33.6146 25.6945 21.3401 30.8544 9.49089 36.5968C-2.28448 42.3055 -13.5479 49.1377 -24.3924 57.5781Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058C-7.48127 10.987 -6.75905 10.5825 -5.98708 10.2246C2.31446 21.3571 9.72289 33.1285 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C32.8843 83.212 31.6694 83.7352 30.424 84.2087C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058L-7.89855 11.2277C-0.236618 22.6692 6.65816 34.6404 12.919 46.9438C19.1606 59.2102 24.964 71.6822 30.9327 84.0176C30.7722 84.0899 30.6021 84.1541 30.424 84.2167C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0414C-9.19845 11.9724 -9.09894 11.9242 -9.00265 11.8665C-1.74355 23.4717 4.86876 35.536 10.9916 47.8169C17.1144 60.0978 22.9451 72.4814 29.0294 84.7174C28.8689 84.7848 28.6972 84.8522 28.5206 84.9165C22.401 72.6933 16.5575 60.3241 10.4716 48.0592C4.38568 35.7944 -2.14318 23.6884 -9.29956 12.0414Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81852C-5.03371 9.76555 -4.93099 9.72383 -4.83148 9.67407C3.85406 20.5771 11.53 32.2476 18.1014 44.5412C24.6527 56.7547 30.3277 69.3646 35.953 81.9056C35.7797 81.9762 35.624 82.0484 35.4507 82.119C29.7933 69.615 24.1102 57.0051 17.5814 44.7803C11.0387 32.4875 3.43563 20.7892 -5.14124 9.81852Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67406C-4.73036 9.62109 -4.62123 9.57616 -4.5169 9.52319C4.25997 20.3669 12.0033 32.0079 18.6117 44.294C25.192 56.4915 30.8477 69.1142 36.4265 81.6552C36.266 81.7466 36.1151 81.8157 35.945 81.9055C30.3277 69.363 24.6527 56.7547 18.1014 44.5412C11.5279 32.2473 3.84988 20.5769 -4.83789 9.67406Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3723C-6.20163 10.3151 -6.09434 10.2658 -5.98382 10.2246C2.31661 21.3572 9.72397 33.1286 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C33.9276 82.7691 33.7575 82.8477 33.6002 82.9119C27.8016 70.4592 22.0623 57.9103 15.6474 45.6599C9.23325 33.3589 1.89477 21.5625 -6.30481 10.3723Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8649L-6.96765 10.6868C1.01468 22.0067 8.20119 33.8673 14.54 46.183C20.8779 58.4415 26.6572 70.9615 32.5233 83.3773C32.3628 83.4447 32.1878 83.5233 32.0209 83.5907C26.118 71.191 20.3274 58.7015 14.0152 46.4237C7.68857 34.1268 0.626882 22.1893 -7.27258 10.8649Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6812C-22.3027 65.7567 -22.5948 64.8163 -22.8837 63.8838C-10.9911 56.6151 1.2593 50.42 13.6927 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.9301 27.9494 52.6668 28.9669 53.3232 30.0069C40.8626 36.2758 28.2959 42.413 15.6074 48.2742C2.93966 54.1226 -9.70238 59.971 -22.0122 66.6764L-22.017 66.6812Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6811C-22.0556 66.564 -22.0941 66.4276 -22.139 66.2944C-9.87898 59.5119 2.7117 53.6042 15.3521 47.7574C28.0182 41.8978 40.5864 35.8055 53.0535 29.5784C53.1402 29.7212 53.2365 29.8689 53.3232 30.0117C40.8625 36.2806 28.2959 42.4178 15.6073 48.279C2.9396 54.1274 -9.70243 59.9757 -22.0122 66.6811H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1063C-21.5901 67.9763 -21.6399 67.8544 -21.6848 67.7212C-9.23056 61.234 3.54308 55.4996 16.2846 49.6561C29.055 43.7997 41.6233 37.5549 54.0695 31.1849C54.1562 31.3294 54.2508 31.469 54.3359 31.6118C41.9121 38.0316 29.3391 44.3117 16.5478 50.1632C3.75654 56.0148 -9.03636 61.6882 -21.5356 68.1063Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8085C-23.2079 62.6881 -23.2368 62.5485 -23.2737 62.4121C-11.6042 54.8689 0.437553 48.507 12.7329 42.7003C25.0732 36.8744 37.5612 31.1801 50.0684 25.3864C50.1727 25.5308 50.2674 25.6704 50.3685 25.8068C37.8709 31.6424 25.3621 37.3768 12.9945 43.2107C0.657429 49.0222 -11.4486 55.3311 -23.1758 62.8085Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.412C-23.3075 62.2917 -23.3444 62.1553 -23.3749 62.0156C-11.7728 54.389 0.21759 47.9869 12.4632 42.1851C24.7843 36.3512 37.261 30.7163 49.7473 24.9771C49.8468 25.1071 49.9512 25.2499 50.0539 25.3863C37.5627 31.1801 25.0731 36.8744 12.7328 42.7003C0.437466 48.5069 -11.6043 54.8641 -23.277 62.412Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2626C-22.8082 64.1374 -22.8451 64.0009 -22.8837 63.8838C-10.9911 56.6151 1.25929 50.42 13.6911 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.285 27.0715 51.3909 27.216 51.4952 27.3588C39.0056 33.3505 26.4963 39.2588 13.9671 45.0836C1.49683 50.9047 -10.813 57.0644 -22.7665 64.2561V64.2626Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4791C-22.4343 65.3475 -22.4792 65.2143 -22.5129 65.0859C-10.4374 58.0595 1.99113 52.0009 14.5369 46.167C27.1131 40.3186 39.6481 34.3461 52.142 28.2495C52.2479 28.394 52.349 28.5304 52.4517 28.6668C39.975 34.8094 27.4277 40.8134 14.8097 46.6789C2.23026 52.5177 -10.2545 58.5249 -22.3894 65.4791Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3539C-13.2028 14.8034 -12.5528 14.2529 -11.8659 13.7666C-5.71258 25.7795 0.0747986 38.001 5.82687 50.2258C11.5645 62.4233 17.4674 74.5581 23.8486 86.4972C22.5647 86.8974 21.2807 87.2687 19.9968 87.611C13.423 75.9609 7.51044 64.0763 2.08096 51.9816C-3.34852 39.8868 -8.53084 27.5867 -13.8464 15.3539H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.7661 15.2737 -13.6762 15.1935 -13.5815 15.118C-8.15368 27.338 -2.89432 39.6205 2.59454 51.7425C8.0834 63.8646 13.9895 75.7732 20.5232 87.4715L19.9904 87.6095C13.423 75.9609 7.51044 64.0764 2.08096 51.9817C-3.34852 39.8869 -8.53084 27.5867 -13.8464 15.354H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.203C-14.758 16.1163 -14.6713 16.0425 -14.5815 15.9639C-9.59173 28.2833 -4.65978 40.5658 0.681424 52.6317C6.01356 64.7208 11.9749 76.5225 18.5411 87.9883C18.3581 88.0365 18.1752 88.0846 18.0083 88.1247C11.4042 76.7167 5.44385 64.9481 0.155007 52.8741C-5.12842 40.8371 -9.97531 28.5369 -14.8591 16.203H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2306L-10.8195 13.0364C-4.23929 24.9032 1.85945 37.0879 7.75115 49.3415C13.6428 61.5951 19.5185 73.8263 25.8018 85.8841C25.6204 85.9403 25.4407 85.9965 25.277 86.0446C18.9696 74.0157 13.0795 61.8102 7.23115 49.579C1.38278 37.3479 -4.64533 25.1472 -11.0988 13.2306Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0364C-10.7264 12.9706 -10.6317 12.9064 -10.5225 12.8358C-3.85727 24.677 2.34257 36.8327 8.27921 49.0992C14.2158 61.3657 20.069 73.6274 26.3299 85.7125C26.1533 85.7767 25.9736 85.8328 25.8018 85.8842C19.5185 73.8264 13.6365 61.5743 7.75119 49.3416C1.86591 37.1088 -4.24566 24.9033 -10.8195 13.0364Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.964L-11.8659 13.7634C-5.71261 25.7763 0.0747623 37.9979 5.82683 50.2226C11.5645 62.4201 17.4674 74.555 23.8486 86.494L23.3158 86.6545C16.9025 74.7508 11.0043 62.6464 5.30363 50.4569C-0.397086 38.2675 -6.11706 26.0074 -12.1532 13.964Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6253C-12.9131 14.5588 -12.8262 14.4853 -12.7454 14.4054C-6.94355 26.5322 -1.40173 38.8003 4.21553 50.9721C9.83278 63.1439 15.7405 75.1841 22.2052 87.0076L21.6724 87.1681C15.1788 75.3927 9.27266 63.3734 3.70195 51.2273C-1.8816 39.0555 -7.33194 26.7794 -13.0054 14.6253Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.4765 73.2582 -19.8729 72.3787 -20.2677 71.48C-7.33838 65.7953 5.85254 60.5777 18.8332 54.7421C31.8139 48.9066 44.3516 42.3216 56.6373 35.552C57.2263 36.6289 57.8057 37.7106 58.3434 38.8004C46.2165 45.8252 33.7542 52.6622 20.6805 58.4511C7.58265 64.2498 -5.86185 69.0934 -19.0817 74.157Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.1347 74.0286 -19.1956 73.9019 -19.2422 73.7863C-6.05769 68.6393 7.35471 63.7362 20.4413 57.9328C33.5038 52.1406 45.9725 45.3341 58.1299 38.3527L58.3433 38.8085C46.2165 45.8252 33.7542 52.6622 20.6804 58.4496C7.58261 64.2498 -5.86189 69.0935 -19.0817 74.157Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5052C-18.5199 75.3864 -18.5857 75.2708 -18.6467 75.144C-5.32581 70.3212 8.20375 65.6236 21.3273 59.841C34.4187 54.0729 46.8456 47.1621 58.9003 40.041C58.9597 40.1855 59.0335 40.3427 59.1073 40.5C47.08 47.65 34.6787 54.5945 21.5824 60.3514C8.43807 66.1292 -5.10433 70.7835 -18.4622 75.5052Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4608C-20.701 70.3308 -20.7395 70.2121 -20.7925 70.0821C-8.03008 64.1005 5.00838 58.6742 17.9168 52.8371C30.8253 47 43.3887 40.5209 55.7434 33.8989C55.8221 34.045 55.9039 34.1878 55.989 34.3419C43.6487 41.0136 31.0933 47.5152 18.164 53.3507C5.23467 59.1862 -7.83588 64.5563 -20.648 70.4608Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0821C-20.8455 69.9537 -20.8888 69.8285 -20.9386 69.7049C-8.22113 63.6351 4.78201 58.1655 17.6696 52.3235C30.5572 46.4816 43.127 40.0394 55.5026 33.4512L55.7433 33.9038C43.3854 40.5209 30.8268 47 17.9168 52.8419C5.0067 58.6839 -8.03015 64.1005 -20.7926 70.0821Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8443C-20.1569 71.7287 -20.2179 71.6019 -20.2709 71.48C-7.33835 65.7953 5.85257 60.5793 18.8332 54.7421C31.8139 48.905 44.3516 42.3216 56.6374 35.552C56.716 35.6981 56.7979 35.8521 56.8861 36.003C44.6116 42.7999 32.0803 49.4282 19.0932 55.2509C6.10615 61.0736 -7.13613 66.2447 -20.1104 71.8443Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.995C-19.6434 72.8762 -19.6963 72.7462 -19.7461 72.6355C-6.68196 67.2157 6.60846 62.1505 19.6517 56.3294C32.6693 50.5196 45.1701 43.811 57.4045 36.9451C57.4799 37.0831 57.565 37.2372 57.642 37.3912C45.4446 44.2924 32.9438 51.0331 19.9069 56.8398C6.87007 62.6464 -6.47814 67.6666 -19.5744 72.995H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.3353 19.9842 -17.7431 19.2443 -17.1621 18.5253C-13.3552 31.0437 -9.32526 43.2797 -4.4559 55.0711C0.394197 66.816 6.29713 78.1629 13.1309 89.1759C11.8053 89.4247 10.4587 89.6269 9.12983 89.8179C2.19011 79.2671 -3.68072 68.3487 -8.17773 56.8975C-12.7004 45.3902 -16.0387 33.2778 -18.9436 20.7048Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.8633 20.6024 -18.777 20.5049 -18.6852 20.4127C-15.6615 32.9729 -12.2238 45.1078 -7.66416 56.6488C-3.1222 68.1433 2.74703 79.1179 9.67711 89.7409C9.50218 89.7682 9.31761 89.7906 9.12823 89.8227C2.19011 79.2719 -3.68072 68.3536 -8.17773 56.9024C-12.6972 45.3902 -16.0419 33.2778 -18.9436 20.7048Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9487C-19.7396 21.8395 -19.6546 21.7176 -19.5807 21.5956C-17.0209 34.1766 -13.9314 46.2184 -9.55154 57.6134C-5.20057 68.9362 0.651003 79.694 7.63887 90.0475C7.46393 90.0731 7.27134 90.0988 7.08517 90.1133C0.074831 79.8417 -5.75427 69.1545 -10.0619 57.8766C-14.384 46.5362 -17.3788 34.5105 -19.8151 21.9487Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.8207C-16.3757 17.7293 -16.2906 17.6426 -16.2072 17.5543C-11.9605 29.9989 -7.59029 42.2702 -2.55242 54.166C2.51942 66.1025 8.43297 77.6636 15.1435 88.7619L14.5995 88.8742C7.87557 77.8357 1.97483 66.3164 -3.05476 54.4099C-8.04609 42.5463 -12.3329 30.2878 -16.4607 17.8175L-16.4672 17.8207Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5543L-15.9553 17.2911C-11.5851 29.7197 -7.12819 42.0071 -2.03253 53.9269C3.08934 65.887 9.00992 77.489 15.6891 88.6544C15.519 88.6881 15.3328 88.7298 15.1434 88.7619C8.43285 77.6636 2.51931 66.1025 -2.55253 54.166C-7.59041 42.2687 -11.9606 29.999 -16.2073 17.5543Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.819C-17.3243 18.7227 -17.244 18.62 -17.1654 18.5253C-13.3585 31.0437 -9.32853 43.2797 -4.45918 55.0711C0.390923 66.8176 6.29386 78.1629 13.1276 89.1759C12.9415 89.216 12.7633 89.2529 12.582 89.2834C5.73694 78.3282 -0.157963 67.0471 -4.96473 55.3215C-9.77952 43.5734 -13.7325 31.3455 -17.4045 18.819Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.761C-18.0817 19.6647 -18.0015 19.562 -17.9229 19.4673C-14.506 32.0002 -10.7777 44.1993 -6.06081 55.8671C-1.35837 67.4948 4.53172 78.6571 11.4201 89.4824C11.2275 89.5064 11.0381 89.5401 10.8648 89.5658C3.96358 78.8128 -1.91689 67.7051 -6.56958 56.111C-11.2399 44.4817 -14.8671 32.3083 -18.162 19.761Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89618C13.614 4.76137 14.6058 4.641 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C59.836 66.5512 58.8778 67.3617 57.9213 68.1802C54.8639 55.7388 50.5932 43.4289 43.3951 32.5507C36.0461 21.443 25.6397 12.2452 12.627 4.89618Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89621C12.7652 4.88631 12.9029 4.86917 13.0394 4.84485C26.1132 12.0959 36.5645 21.2151 43.9135 32.2892C51.1051 43.1337 55.3357 55.4114 58.3225 67.8368C58.1749 67.9507 58.0497 68.0727 57.9181 68.1802C54.8607 55.7404 50.59 43.429 43.3919 32.5508C36.0461 21.443 25.6397 12.2452 12.627 4.89621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09685C11.2789 5.08722 11.4169 5.06636 11.5485 5.04871C24.3606 12.64 34.6611 22.0112 42.0085 33.22C49.2194 44.2186 53.5944 56.5862 56.838 69.0758C56.7064 69.1833 56.5636 69.286 56.4239 69.3968C53.1178 56.8879 48.6898 44.5027 41.4804 33.4624C34.1347 22.2134 23.8808 12.7668 11.144 5.09685Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.41308C16.8976 4.41308 17.0389 4.39382 17.1817 4.38098C30.9376 10.714 41.7403 19.1255 49.054 29.7806C56.1671 40.1533 59.9082 52.1902 62.1695 64.4214C62.0491 64.5322 61.9239 64.6557 61.7988 64.7777C59.4636 52.5225 55.6647 40.463 48.5324 30.0374C41.2123 19.3389 30.44 10.844 16.7516 4.41308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38103C17.3198 4.36017 17.4626 4.34573 17.6039 4.33289C31.4175 10.5777 42.2588 18.9185 49.5676 29.5303C56.6823 39.8564 60.356 51.8693 62.5371 64.062C62.4199 64.1807 62.2947 64.3043 62.1696 64.4263C59.9082 52.1903 56.1671 40.1533 49.0541 29.7807C41.7404 19.1255 30.9376 10.7141 17.1818 4.38103Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56557C15.3203 4.5403 15.4587 4.52528 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C60.6561 65.8514 60.5213 65.9509 60.3961 66.0729C57.772 53.7406 53.7966 41.5753 46.6274 30.9683C39.2864 20.1077 28.6377 11.356 15.1836 4.56557Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73415C14.0361 4.71329 14.1741 4.69242 14.317 4.67798C27.609 11.653 38.1919 20.5572 45.5281 31.506C52.7053 42.2061 56.7898 54.4164 59.5535 66.7904C59.4219 66.8979 59.2935 67.0135 59.1539 67.1226C56.31 54.7293 52.1933 42.4917 45.0113 31.7515C37.6671 20.7642 27.1291 11.7878 13.8965 4.73415Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8384 40.5337 -25.7822 39.3621 -25.6763 38.1777C-19.1747 27.362 -12.1082 19.6118 -3.52824 15.0554C5.16246 10.4348 15.3746 8.10287 26.2111 5.8656C27.6 6.41931 28.9596 7.04391 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3706 -18.4461 31.1111 -25.8608 41.678V41.6732Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8608 41.5127 -25.8608 41.3618 -25.8608 41.1917C-18.5616 30.5992 -10.7055 22.8538 -1.50282 18.0101C7.81541 13.107 18.4994 10.2455 29.7355 7.45288C29.9249 7.55399 30.0999 7.64387 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3674 -18.4461 31.1079 -25.8608 41.6732Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4001C-25.901 43.2396 -25.8914 43.0791 -25.8898 42.9331C-18.154 32.4769 -9.8838 24.7588 -0.342491 19.7723C9.31919 14.7264 20.2439 11.5824 31.6919 8.49768C31.8524 8.59237 32.0129 8.70311 32.1734 8.80583C20.6853 11.9627 9.69153 15.1838 -0.0407619 20.2634C-9.67195 25.2917 -18.0529 32.9969 -25.901 43.4001Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8391C-25.5592 36.669 -25.5335 36.4988 -25.5159 36.34C-19.5182 25.4264 -12.9605 17.6987 -4.77857 13.3381C3.50287 8.92294 13.3957 6.93443 23.9641 5.05505C24.1583 5.12728 24.3654 5.20271 24.5676 5.27172C13.9333 7.25702 3.95707 9.33219 -4.44795 13.8083C-12.7246 18.2155 -19.422 25.948 -25.5592 36.8391Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.34C-25.487 36.1586 -25.4677 35.9821 -25.4292 35.8055C-19.5857 24.8824 -13.166 17.1787 -5.11883 12.8887C3.04384 8.53294 12.8339 6.63591 23.3109 4.85925C23.5332 4.90881 23.7516 4.97479 23.9641 5.05666C13.3956 6.93443 3.50285 8.92294 -4.77859 13.3381C-12.9637 17.6987 -19.5183 25.4184 -25.5143 36.34Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6672C-25.7004 38.5067 -25.6908 38.3462 -25.6763 38.1857C-19.1747 27.3701 -12.1082 19.6199 -3.52827 15.0635C5.16242 10.4348 15.3746 8.10287 26.2111 5.8656L26.8274 6.08548C15.9139 8.42386 5.61983 10.8521 -3.18482 15.516C-11.8819 20.127 -19.0736 27.8868 -25.7164 38.6672Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.195C-25.8336 40.0346 -25.824 39.8837 -25.8095 39.7136C-18.8762 28.9958 -11.4005 21.228 -2.49312 16.5191C6.5298 11.7477 17.0052 9.13644 28.0567 6.6167L28.5928 6.88793C17.4722 9.48952 6.92622 12.1842 -2.18657 16.999C-11.2015 21.7528 -18.7832 29.511 -25.8448 40.195Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34067C5.87818 6.13203 6.76249 5.92339 7.67891 5.73401C19.7255 14.1454 29.5702 24.1506 36.8565 35.6997C44.0338 47.0786 48.8117 59.6131 52.6779 72.2279C51.6283 72.9517 50.5626 73.6803 49.513 74.4041C45.2295 61.7332 40.2157 49.1217 33.0962 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34067Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34064C5.11584 6.31818 5.23781 6.27805 5.35497 6.24756C16.8719 15.1694 26.3924 25.518 33.6017 37.25C40.7292 48.836 45.7157 61.4331 49.9431 74.0992L49.505 74.4041C45.2214 61.7332 40.2076 49.1217 33.0882 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34064Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67283C3.76459 6.63913 3.88335 6.61826 4.00051 6.58777C15.2045 15.7792 24.5324 26.3364 31.6952 38.1583C38.7842 49.8583 43.8798 62.4923 48.3062 75.1744C48.1457 75.2723 48.0045 75.3606 47.8488 75.4505C43.3662 62.7715 38.2433 50.1343 31.1672 38.4007C24.0204 26.5483 14.76 15.9365 3.6394 6.67283Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54302C8.85533 5.52055 8.98051 5.48845 9.1073 5.46277C21.4412 13.5869 31.4511 23.3545 38.776 34.788C45.9709 46.0226 50.5963 58.5041 54.2427 71.0787C54.1031 71.1879 53.9699 71.2874 53.8303 71.3997C50.1245 58.8139 45.4574 46.3179 38.2625 35.0368C30.9504 23.5728 20.9677 13.7362 8.7157 5.54141V5.54302Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46281C9.2389 5.44515 9.36088 5.43071 9.48927 5.40503C21.9002 13.4297 31.9695 23.1411 39.2896 34.5281C46.4909 45.7321 51.0891 58.1896 54.6536 70.7449C54.514 70.8541 54.3824 70.9616 54.2427 71.0659C50.5963 58.4977 45.9709 46.0226 38.776 34.7881C31.4511 23.3546 21.4412 13.587 9.1073 5.46281Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80784C7.43174 5.78216 7.55692 5.74845 7.6789 5.73401C19.7255 14.1454 29.5701 24.1506 36.8565 35.6997C44.0338 47.0786 48.8116 59.6131 52.6779 72.2279C52.5367 72.3306 52.3826 72.4285 52.2478 72.528C48.3221 59.8988 43.5138 47.3563 36.3365 35.9356C29.0598 24.356 19.2537 14.3091 7.30334 5.80784Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06944C6.25536 6.04055 6.38055 6.00684 6.50092 5.98438C18.2827 14.651 27.9733 24.8246 35.2292 36.4652C42.3871 47.9581 47.2661 60.5295 51.317 73.17C51.1709 73.2646 51.0297 73.3674 50.8756 73.4653C46.7847 60.8152 41.8591 48.2406 34.7044 36.7124C27.4661 25.0525 17.8365 14.8099 6.1366 6.06623L6.13981 6.06944Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.5688 49.5357 -25.6731 48.4893 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C37.4777 12.4939 38.5562 13.3285 39.5673 14.1791C27.4613 18.4386 15.6924 22.7045 4.76599 28.2078C-6.05445 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.4885 50.4296 -25.5078 50.2948 -25.5206 50.1488C-16.143 40.5529 -6.28073 33.1365 4.49156 27.7054C15.3457 22.2182 27.0842 18.0133 39.1548 13.8196C39.2816 13.9271 39.418 14.0587 39.5593 14.1791C27.4613 18.4386 15.6924 22.7045 4.766 28.2078C-6.05444 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1518C-25.3008 52.0218 -25.3297 51.879 -25.3522 51.7361C-15.6488 42.373 -5.47351 35.0577 5.51705 29.5544C16.6023 24.0029 28.4804 19.5814 40.6458 15.1614C40.779 15.285 40.9202 15.4053 41.0534 15.5289C28.8672 20.0099 16.957 24.4828 5.81075 30.0487C-5.24401 35.5697 -15.5091 42.8496 -25.3008 52.1518Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2215C-25.8192 46.0835 -25.8272 45.9262 -25.8256 45.7738C-17.4174 35.6322 -8.49236 27.9928 1.56895 22.804C11.7201 17.5655 23.0108 13.9769 34.7332 10.4348C34.8937 10.5375 35.0542 10.6483 35.2147 10.7558C23.4409 14.3749 12.1037 18.0181 1.86105 23.2919C-8.26767 28.5095 -17.297 36.1313 -25.8144 46.2264L-25.8079 46.2215Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7738C-25.8255 45.6261 -25.8367 45.4769 -25.8416 45.3276C-17.5392 35.1299 -8.71856 27.484 1.25448 22.3273C11.3318 17.1145 22.5583 13.5933 34.247 10.1299L34.7285 10.4396C23.0125 13.9833 11.7154 17.5703 1.56423 22.8088C-8.48905 27.9928 -17.4173 35.6322 -25.8255 45.7738Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8746C-25.7453 47.7285 -25.7502 47.5793 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C36.5597 11.7829 36.7089 11.9001 36.855 12.0092C24.9448 15.8691 13.4342 19.7659 2.93476 25.1311C-7.46679 30.4467 -16.8476 37.9706 -25.7309 47.8746Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2324C-25.6105 49.0847 -25.6298 48.9387 -25.6394 48.8007C-16.5571 39.0234 -6.98209 31.5332 3.57032 26.1711C14.2158 20.7465 25.8227 16.7309 37.7906 12.7299L38.2384 13.0813C26.2303 17.1434 14.5769 21.2231 3.86242 26.659C-6.76061 32.0468 -16.4303 39.5129 -25.6105 49.2324Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47686C-1.12893 8.16871 -0.326466 7.86538 0.508097 7.6134C10.815 17.511 19.5329 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C42.6038 78.5369 41.4595 79.1644 40.32 79.7823C35.0622 67.1451 29.5509 54.4693 22.7685 42.3874C15.8971 30.1521 7.60656 18.7699 -1.9314 8.47686Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47687C-1.82066 8.43033 -1.70992 8.38539 -1.61041 8.35168C8.01522 18.565 16.3682 29.9067 23.266 42.129C30.0821 54.2077 35.5806 66.8867 40.7951 79.5255C40.6346 79.6154 40.4741 79.7021 40.3136 79.7823C35.0558 67.1451 29.5445 54.4693 22.7621 42.3874C15.8927 30.1524 7.60431 18.7703 -1.9314 8.47687Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93903C-3.03721 8.89409 -2.93128 8.86039 -2.82696 8.81384C6.46005 19.295 14.5676 30.7647 21.3497 43.0165C28.0663 55.153 33.6306 67.8095 39.0039 80.421C38.8515 80.5013 38.6829 80.5815 38.5225 80.6617C33.1138 68.0598 27.5191 55.4178 20.8329 43.2669C14.0861 31.002 6.04672 19.4939 -3.14795 8.93903Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32129C1.55612 7.2908 1.66847 7.25389 1.79044 7.21216C12.4375 16.8562 21.3931 27.7264 28.4564 39.7024C35.4506 51.5628 40.7212 64.2353 45.4686 76.9223C45.3242 77.017 45.1669 77.1085 45.0128 77.2064C40.2141 64.5162 34.9226 51.8485 27.9364 39.9527C20.8891 27.9655 11.9946 17.0375 1.43896 7.32129Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21211C1.91455 7.18209 2.03148 7.14347 2.14516 7.09656C12.8709 16.6796 21.8954 27.5 28.97 39.4423C35.9803 51.2835 41.2252 63.9512 45.9116 76.635C45.7736 76.7264 45.6211 76.8243 45.4638 76.9158C40.7213 64.2369 35.4507 51.5579 28.4516 39.6975C21.3931 27.7263 12.4376 16.8561 1.79529 7.21211Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72093C0.277004 7.68081 0.394164 7.65032 0.506509 7.6134C10.815 17.511 19.533 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C43.5876 77.9912 43.4272 78.0827 43.2667 78.1661C38.2914 65.4872 32.9052 52.8163 25.9944 40.8403C19.037 28.776 10.372 17.6908 0.155029 7.72093Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07878C-0.788642 8.03705 -0.674692 8.00014 -0.570371 7.9552C9.33431 17.9331 17.894 29.1614 24.8918 41.3554C31.7722 53.3699 37.1888 66.0537 42.2604 78.7117C42.1079 78.8113 41.9555 78.8899 41.7966 78.9734C36.6785 66.3153 31.2233 53.6379 24.3751 41.6026C17.4115 29.3998 8.92095 18.1344 -0.891357 8.07878Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8619C-24.3346 57.8829 -24.5272 56.8927 -24.7278 55.9056C-14.6062 47.4873 -3.51314 40.3108 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C45.3964 19.8364 46.2856 20.7737 47.0993 21.7495C34.661 27.1052 22.3031 32.4047 10.311 38.176C-1.61037 43.9136 -13.0808 50.6399 -24.1372 58.8619Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.1677 58.7224 -24.1966 58.5827 -24.2223 58.4511C-13.2317 50.1585 -1.8271 43.3969 10.0494 37.6769C21.982 31.9104 34.3127 26.6575 46.7557 21.3403C46.8681 21.48 46.9869 21.61 47.0992 21.7512C34.661 27.1052 22.303 32.4047 10.311 38.176C-1.61043 43.9121 -13.0809 50.6399 -24.1372 58.862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3385C-23.8354 60.2021 -23.8707 60.0737 -23.9044 59.9437C-12.6523 51.919 -0.997252 45.3067 11.0381 39.545C23.1393 33.7512 35.539 28.3281 48.0029 22.8345C48.1152 22.9757 48.2292 23.1153 48.3351 23.2421C35.8712 28.7807 23.4587 34.2503 11.3158 40.0457C-0.769354 45.8155 -12.4805 52.3812 -23.7985 60.3385Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7791C-24.9316 54.6362 -24.9524 54.4934 -24.9701 54.3586C-14.7403 45.3806 -4.05143 38.2804 7.30666 32.6712C18.753 27.017 30.8269 22.2568 43.1319 17.4773C43.2571 17.6041 43.3855 17.7389 43.4994 17.8609C31.1832 22.6981 19.0756 27.5097 7.57628 33.1831C-3.83958 38.8004 -14.6103 45.8749 -24.9091 54.7791Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3585C-24.9965 54.2221 -25.0158 54.0844 -25.028 53.946C-14.8832 44.899 -4.27781 37.7587 7.03213 32.1591C18.4271 26.521 30.4641 21.8137 42.7627 17.0936C42.8879 17.2204 43.0098 17.3392 43.1318 17.4772C30.8268 22.2567 18.7529 27.0169 7.30657 32.6711C-4.05152 38.2803 -14.7404 45.3805 -24.9702 54.3585Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3021C-24.6732 56.1705 -24.6989 56.0389 -24.7278 55.8992C-14.6058 47.4831 -3.51282 40.3088 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C44.6245 19.0292 44.7496 19.156 44.8748 19.2827C32.4912 24.3174 20.2857 29.312 8.6034 35.0255C-3.26448 40.8093 -14.422 47.9491 -24.6459 56.3021Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5781C-24.4181 57.4464 -24.455 57.31 -24.4742 57.1752C-13.7212 48.6691 -2.52201 41.7968 9.20521 36.0993C20.995 30.3681 33.2519 25.2564 45.6644 20.111C45.7816 20.241 45.91 20.3758 46.0335 20.4945C33.6146 25.6945 21.3401 30.8544 9.49089 36.5968C-2.28448 42.3055 -13.5479 49.1377 -24.3924 57.5781Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058C-7.48127 10.987 -6.75905 10.5825 -5.98708 10.2246C2.31446 21.3571 9.72289 33.1285 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C32.8843 83.212 31.6694 83.7352 30.424 84.2087C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058L-7.89855 11.2277C-0.236618 22.6692 6.65816 34.6404 12.919 46.9438C19.1606 59.2102 24.964 71.6822 30.9327 84.0176C30.7722 84.0899 30.6021 84.1541 30.424 84.2167C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0414C-9.19845 11.9724 -9.09894 11.9242 -9.00265 11.8665C-1.74355 23.4717 4.86876 35.536 10.9916 47.8169C17.1144 60.0978 22.9451 72.4814 29.0294 84.7174C28.8689 84.7848 28.6972 84.8522 28.5206 84.9165C22.401 72.6933 16.5575 60.3241 10.4716 48.0592C4.38568 35.7944 -2.14318 23.6884 -9.29956 12.0414Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81852C-5.03371 9.76555 -4.93099 9.72383 -4.83148 9.67407C3.85406 20.5771 11.53 32.2476 18.1014 44.5412C24.6527 56.7547 30.3277 69.3646 35.953 81.9056C35.7797 81.9762 35.624 82.0484 35.4507 82.119C29.7933 69.615 24.1102 57.0051 17.5814 44.7803C11.0387 32.4875 3.43563 20.7892 -5.14124 9.81852Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67406C-4.73036 9.62109 -4.62123 9.57616 -4.5169 9.52319C4.25997 20.3669 12.0033 32.0079 18.6117 44.294C25.192 56.4915 30.8477 69.1142 36.4265 81.6552C36.266 81.7466 36.1151 81.8157 35.945 81.9055C30.3277 69.363 24.6527 56.7547 18.1014 44.5412C11.5279 32.2473 3.84988 20.5769 -4.83789 9.67406Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3723C-6.20163 10.3151 -6.09434 10.2658 -5.98382 10.2246C2.31661 21.3572 9.72397 33.1286 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C33.9276 82.7691 33.7575 82.8477 33.6002 82.9119C27.8016 70.4592 22.0623 57.9103 15.6474 45.6599C9.23325 33.3589 1.89477 21.5625 -6.30481 10.3723Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8649L-6.96765 10.6868C1.01468 22.0067 8.20119 33.8673 14.54 46.183C20.8779 58.4415 26.6572 70.9615 32.5233 83.3773C32.3628 83.4447 32.1878 83.5233 32.0209 83.5907C26.118 71.191 20.3274 58.7015 14.0152 46.4237C7.68857 34.1268 0.626882 22.1893 -7.27258 10.8649Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6812C-22.3027 65.7567 -22.5948 64.8163 -22.8837 63.8838C-10.9911 56.6151 1.2593 50.42 13.6927 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.9301 27.9494 52.6668 28.9669 53.3232 30.0069C40.8626 36.2758 28.2959 42.413 15.6074 48.2742C2.93966 54.1226 -9.70238 59.971 -22.0122 66.6764L-22.017 66.6812Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6811C-22.0556 66.564 -22.0941 66.4276 -22.139 66.2944C-9.87898 59.5119 2.7117 53.6042 15.3521 47.7574C28.0182 41.8978 40.5864 35.8055 53.0535 29.5784C53.1402 29.7212 53.2365 29.8689 53.3232 30.0117C40.8625 36.2806 28.2959 42.4178 15.6073 48.279C2.9396 54.1274 -9.70243 59.9757 -22.0122 66.6811H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1063C-21.5901 67.9763 -21.6399 67.8544 -21.6848 67.7212C-9.23056 61.234 3.54308 55.4996 16.2846 49.6561C29.055 43.7997 41.6233 37.5549 54.0695 31.1849C54.1562 31.3294 54.2508 31.469 54.3359 31.6118C41.9121 38.0316 29.3391 44.3117 16.5478 50.1632C3.75654 56.0148 -9.03636 61.6882 -21.5356 68.1063Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8085C-23.2079 62.6881 -23.2368 62.5485 -23.2737 62.4121C-11.6042 54.8689 0.437553 48.507 12.7329 42.7003C25.0732 36.8744 37.5612 31.1801 50.0684 25.3864C50.1727 25.5308 50.2674 25.6704 50.3685 25.8068C37.8709 31.6424 25.3621 37.3768 12.9945 43.2107C0.657429 49.0222 -11.4486 55.3311 -23.1758 62.8085Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.412C-23.3075 62.2917 -23.3444 62.1553 -23.3749 62.0156C-11.7728 54.389 0.21759 47.9869 12.4632 42.1851C24.7843 36.3512 37.261 30.7163 49.7473 24.9771C49.8468 25.1071 49.9512 25.2499 50.0539 25.3863C37.5627 31.1801 25.0731 36.8744 12.7328 42.7003C0.437466 48.5069 -11.6043 54.8641 -23.277 62.412Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2626C-22.8082 64.1374 -22.8451 64.0009 -22.8837 63.8838C-10.9911 56.6151 1.25929 50.42 13.6911 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.285 27.0715 51.3909 27.216 51.4952 27.3588C39.0056 33.3505 26.4963 39.2588 13.9671 45.0836C1.49683 50.9047 -10.813 57.0644 -22.7665 64.2561V64.2626Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4791C-22.4343 65.3475 -22.4792 65.2143 -22.5129 65.0859C-10.4374 58.0595 1.99113 52.0009 14.5369 46.167C27.1131 40.3186 39.6481 34.3461 52.142 28.2495C52.2479 28.394 52.349 28.5304 52.4517 28.6668C39.975 34.8094 27.4277 40.8134 14.8097 46.6789C2.23026 52.5177 -10.2545 58.5249 -22.3894 65.4791Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3539C-13.2028 14.8034 -12.5528 14.2529 -11.8659 13.7666C-5.71258 25.7795 0.0747986 38.001 5.82687 50.2258C11.5645 62.4233 17.4674 74.5581 23.8486 86.4972C22.5647 86.8974 21.2807 87.2687 19.9968 87.611C13.423 75.9609 7.51044 64.0763 2.08096 51.9816C-3.34852 39.8868 -8.53084 27.5867 -13.8464 15.3539H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.7661 15.2737 -13.6762 15.1935 -13.5815 15.118C-8.15368 27.338 -2.89432 39.6205 2.59454 51.7425C8.0834 63.8646 13.9895 75.7732 20.5232 87.4715L19.9904 87.6095C13.423 75.9609 7.51044 64.0764 2.08096 51.9817C-3.34852 39.8869 -8.53084 27.5867 -13.8464 15.354H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.203C-14.758 16.1163 -14.6713 16.0425 -14.5815 15.9639C-9.59173 28.2833 -4.65978 40.5658 0.681424 52.6317C6.01356 64.7208 11.9749 76.5225 18.5411 87.9883C18.3581 88.0365 18.1752 88.0846 18.0083 88.1247C11.4042 76.7167 5.44385 64.9481 0.155007 52.8741C-5.12842 40.8371 -9.97531 28.5369 -14.8591 16.203H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2306L-10.8195 13.0364C-4.23929 24.9032 1.85945 37.0879 7.75115 49.3415C13.6428 61.5951 19.5185 73.8263 25.8018 85.8841C25.6204 85.9403 25.4407 85.9965 25.277 86.0446C18.9696 74.0157 13.0795 61.8102 7.23115 49.579C1.38278 37.3479 -4.64533 25.1472 -11.0988 13.2306Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0364C-10.7264 12.9706 -10.6317 12.9064 -10.5225 12.8358C-3.85727 24.677 2.34257 36.8327 8.27921 49.0992C14.2158 61.3657 20.069 73.6274 26.3299 85.7125C26.1533 85.7767 25.9736 85.8328 25.8018 85.8842C19.5185 73.8264 13.6365 61.5743 7.75119 49.3416C1.86591 37.1088 -4.24566 24.9033 -10.8195 13.0364Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.964L-11.8659 13.7634C-5.71261 25.7763 0.0747623 37.9979 5.82683 50.2226C11.5645 62.4201 17.4674 74.555 23.8486 86.494L23.3158 86.6545C16.9025 74.7508 11.0043 62.6464 5.30363 50.4569C-0.397086 38.2675 -6.11706 26.0074 -12.1532 13.964Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6253C-12.9131 14.5588 -12.8262 14.4853 -12.7454 14.4054C-6.94355 26.5322 -1.40173 38.8003 4.21553 50.9721C9.83278 63.1439 15.7405 75.1841 22.2052 87.0076L21.6724 87.1681C15.1788 75.3927 9.27266 63.3734 3.70195 51.2273C-1.8816 39.0555 -7.33194 26.7794 -13.0054 14.6253Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.4765 73.2582 -19.8729 72.3787 -20.2677 71.48C-7.33838 65.7953 5.85254 60.5777 18.8332 54.7421C31.8139 48.9066 44.3516 42.3216 56.6373 35.552C57.2263 36.6289 57.8057 37.7106 58.3434 38.8004C46.2165 45.8252 33.7542 52.6622 20.6805 58.4511C7.58265 64.2498 -5.86185 69.0934 -19.0817 74.157Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.1347 74.0286 -19.1956 73.9019 -19.2422 73.7863C-6.05769 68.6393 7.35471 63.7362 20.4413 57.9328C33.5038 52.1406 45.9725 45.3341 58.1299 38.3527L58.3433 38.8085C46.2165 45.8252 33.7542 52.6622 20.6804 58.4496C7.58261 64.2498 -5.86189 69.0935 -19.0817 74.157Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5052C-18.5199 75.3864 -18.5857 75.2708 -18.6467 75.144C-5.32581 70.3212 8.20375 65.6236 21.3273 59.841C34.4187 54.0729 46.8456 47.1621 58.9003 40.041C58.9597 40.1855 59.0335 40.3427 59.1073 40.5C47.08 47.65 34.6787 54.5945 21.5824 60.3514C8.43807 66.1292 -5.10433 70.7835 -18.4622 75.5052Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4608C-20.701 70.3308 -20.7395 70.2121 -20.7925 70.0821C-8.03008 64.1005 5.00838 58.6742 17.9168 52.8371C30.8253 47 43.3887 40.5209 55.7434 33.8989C55.8221 34.045 55.9039 34.1878 55.989 34.3419C43.6487 41.0136 31.0933 47.5152 18.164 53.3507C5.23467 59.1862 -7.83588 64.5563 -20.648 70.4608Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0821C-20.8455 69.9537 -20.8888 69.8285 -20.9386 69.7049C-8.22113 63.6351 4.78201 58.1655 17.6696 52.3235C30.5572 46.4816 43.127 40.0394 55.5026 33.4512L55.7433 33.9038C43.3854 40.5209 30.8268 47 17.9168 52.8419C5.0067 58.6839 -8.03015 64.1005 -20.7926 70.0821Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8443C-20.1569 71.7287 -20.2179 71.6019 -20.2709 71.48C-7.33835 65.7953 5.85257 60.5793 18.8332 54.7421C31.8139 48.905 44.3516 42.3216 56.6374 35.552C56.716 35.6981 56.7979 35.8521 56.8861 36.003C44.6116 42.7999 32.0803 49.4282 19.0932 55.2509C6.10615 61.0736 -7.13613 66.2447 -20.1104 71.8443Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.995C-19.6434 72.8762 -19.6963 72.7462 -19.7461 72.6355C-6.68196 67.2157 6.60846 62.1505 19.6517 56.3294C32.6693 50.5196 45.1701 43.811 57.4045 36.9451C57.4799 37.0831 57.565 37.2372 57.642 37.3912C45.4446 44.2924 32.9438 51.0331 19.9069 56.8398C6.87007 62.6464 -6.47814 67.6666 -19.5744 72.995H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.3353 19.9842 -17.7431 19.2443 -17.1621 18.5253C-13.3552 31.0437 -9.32526 43.2797 -4.4559 55.0711C0.394197 66.816 6.29713 78.1629 13.1309 89.1759C11.8053 89.4247 10.4587 89.6269 9.12983 89.8179C2.19011 79.2671 -3.68072 68.3487 -8.17773 56.8975C-12.7004 45.3902 -16.0387 33.2778 -18.9436 20.7048Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.8633 20.6024 -18.777 20.5049 -18.6852 20.4127C-15.6615 32.9729 -12.2238 45.1078 -7.66416 56.6488C-3.1222 68.1433 2.74703 79.1179 9.67711 89.7409C9.50218 89.7682 9.31761 89.7906 9.12823 89.8227C2.19011 79.2719 -3.68072 68.3536 -8.17773 56.9024C-12.6972 45.3902 -16.0419 33.2778 -18.9436 20.7048Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9487C-19.7396 21.8395 -19.6546 21.7176 -19.5807 21.5956C-17.0209 34.1766 -13.9314 46.2184 -9.55154 57.6134C-5.20057 68.9362 0.651003 79.694 7.63887 90.0475C7.46393 90.0731 7.27134 90.0988 7.08517 90.1133C0.074831 79.8417 -5.75427 69.1545 -10.0619 57.8766C-14.384 46.5362 -17.3788 34.5105 -19.8151 21.9487Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.8207C-16.3757 17.7293 -16.2906 17.6426 -16.2072 17.5543C-11.9605 29.9989 -7.59029 42.2702 -2.55242 54.166C2.51942 66.1025 8.43297 77.6636 15.1435 88.7619L14.5995 88.8742C7.87557 77.8357 1.97483 66.3164 -3.05476 54.4099C-8.04609 42.5463 -12.3329 30.2878 -16.4607 17.8175L-16.4672 17.8207Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5543L-15.9553 17.2911C-11.5851 29.7197 -7.12819 42.0071 -2.03253 53.9269C3.08934 65.887 9.00992 77.489 15.6891 88.6544C15.519 88.6881 15.3328 88.7298 15.1434 88.7619C8.43285 77.6636 2.51931 66.1025 -2.55253 54.166C-7.59041 42.2687 -11.9606 29.999 -16.2073 17.5543Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.819C-17.3243 18.7227 -17.244 18.62 -17.1654 18.5253C-13.3585 31.0437 -9.32853 43.2797 -4.45918 55.0711C0.390923 66.8176 6.29386 78.1629 13.1276 89.1759C12.9415 89.216 12.7633 89.2529 12.582 89.2834C5.73694 78.3282 -0.157963 67.0471 -4.96473 55.3215C-9.77952 43.5734 -13.7325 31.3455 -17.4045 18.819Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.761C-18.0817 19.6647 -18.0015 19.562 -17.9229 19.4673C-14.506 32.0002 -10.7777 44.1993 -6.06081 55.8671C-1.35837 67.4948 4.53172 78.6571 11.4201 89.4824C11.2275 89.5064 11.0381 89.5401 10.8648 89.5658C3.96358 78.8128 -1.91689 67.7051 -6.56958 56.111C-11.2399 44.4817 -14.8671 32.3083 -18.162 19.761Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89618C13.614 4.76137 14.6058 4.641 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C59.836 66.5512 58.8778 67.3617 57.9213 68.1802C54.8639 55.7388 50.5932 43.4289 43.3951 32.5507C36.0461 21.443 25.6397 12.2452 12.627 4.89618Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.627 4.89621C12.7652 4.88631 12.9029 4.86917 13.0394 4.84485C26.1132 12.0959 36.5645 21.2151 43.9135 32.2892C51.1051 43.1337 55.3357 55.4114 58.3225 67.8368C58.1749 67.9507 58.0497 68.0727 57.9181 68.1802C54.8607 55.7404 50.59 43.429 43.3919 32.5508C36.0461 21.443 25.6397 12.2452 12.627 4.89621Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.144 5.09685C11.2789 5.08722 11.4169 5.06636 11.5485 5.04871C24.3606 12.64 34.6611 22.0112 42.0085 33.22C49.2194 44.2186 53.5944 56.5862 56.838 69.0758C56.7064 69.1833 56.5636 69.286 56.4239 69.3968C53.1178 56.8879 48.6898 44.5027 41.4804 33.4624C34.1347 22.2134 23.8808 12.7668 11.144 5.09685Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7516 4.41308C16.8976 4.41308 17.0389 4.39382 17.1817 4.38098C30.9376 10.714 41.7403 19.1255 49.054 29.7806C56.1671 40.1533 59.9082 52.1902 62.1695 64.4214C62.0491 64.5322 61.9239 64.6557 61.7988 64.7777C59.4636 52.5225 55.6647 40.463 48.5324 30.0374C41.2123 19.3389 30.44 10.844 16.7516 4.41308Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1818 4.38103C17.3198 4.36017 17.4626 4.34573 17.6039 4.33289C31.4175 10.5777 42.2588 18.9185 49.5676 29.5303C56.6823 39.8564 60.356 51.8693 62.5371 64.062C62.4199 64.1807 62.2947 64.3043 62.1696 64.4263C59.9082 52.1903 56.1671 40.1533 49.0541 29.7807C41.7404 19.1255 30.9376 10.7141 17.1818 4.38103Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1836 4.56557C15.3203 4.5403 15.4587 4.52528 15.5977 4.52063C29.1208 11.2052 39.7968 19.9023 47.1426 30.7211C54.307 41.2735 58.2455 53.4212 60.7845 65.7359C60.6561 65.8514 60.5213 65.9509 60.3961 66.0729C57.772 53.7406 53.7966 41.5753 46.6274 30.9683C39.2864 20.1077 28.6377 11.356 15.1836 4.56557Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8965 4.73415C14.0361 4.71329 14.1741 4.69242 14.317 4.67798C27.609 11.653 38.1919 20.5572 45.5281 31.506C52.7053 42.2061 56.7898 54.4164 59.5535 66.7904C59.4219 66.8979 59.2935 67.0135 59.1539 67.1226C56.31 54.7293 52.1933 42.4917 45.0113 31.7515C37.6671 20.7642 27.1291 11.7878 13.8965 4.73415Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8384 40.5337 -25.7822 39.3621 -25.6763 38.1777C-19.1747 27.362 -12.1082 19.6118 -3.52824 15.0554C5.16246 10.4348 15.3746 8.10287 26.2111 5.8656C27.6 6.41931 28.9596 7.04391 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3706 -18.4461 31.1111 -25.8608 41.678V41.6732Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8608 41.6732C-25.8608 41.5127 -25.8608 41.3618 -25.8608 41.1917C-18.5616 30.5992 -10.7055 22.8538 -1.50282 18.0101C7.81541 13.107 18.4994 10.2455 29.7355 7.45288C29.9249 7.55399 30.0999 7.64387 30.2844 7.73695C18.9857 10.6001 8.23429 13.542 -1.18183 18.49C-10.4776 23.3674 -18.4461 31.1079 -25.8608 41.6732Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.901 43.4001C-25.901 43.2396 -25.8914 43.0791 -25.8898 42.9331C-18.154 32.4769 -9.8838 24.7588 -0.342491 19.7723C9.31919 14.7264 20.2439 11.5824 31.6919 8.49768C31.8524 8.59237 32.0129 8.70311 32.1734 8.80583C20.6853 11.9627 9.69153 15.1838 -0.0407619 20.2634C-9.67195 25.2917 -18.0529 32.9969 -25.901 43.4001Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5592 36.8391C-25.5592 36.669 -25.5335 36.4988 -25.5159 36.34C-19.5182 25.4264 -12.9605 17.6987 -4.77857 13.3381C3.50287 8.92294 13.3957 6.93443 23.9641 5.05505C24.1583 5.12728 24.3654 5.20271 24.5676 5.27172C13.9333 7.25702 3.95707 9.33219 -4.44795 13.8083C-12.7246 18.2155 -19.422 25.948 -25.5592 36.8391Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.5143 36.34C-25.487 36.1586 -25.4677 35.9821 -25.4292 35.8055C-19.5857 24.8824 -13.166 17.1787 -5.11883 12.8887C3.04384 8.53294 12.8339 6.63591 23.3109 4.85925C23.5332 4.90881 23.7516 4.97479 23.9641 5.05666C13.3956 6.93443 3.50285 8.92294 -4.77859 13.3381C-12.9637 17.6987 -19.5183 25.4184 -25.5143 36.34Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7164 38.6672C-25.7004 38.5067 -25.6908 38.3462 -25.6763 38.1857C-19.1747 27.3701 -12.1082 19.6199 -3.52827 15.0635C5.16242 10.4348 15.3746 8.10287 26.2111 5.8656L26.8274 6.08548C15.9139 8.42386 5.61983 10.8521 -3.18482 15.516C-11.8819 20.127 -19.0736 27.8868 -25.7164 38.6672Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8448 40.195C-25.8336 40.0346 -25.824 39.8837 -25.8095 39.7136C-18.8762 28.9958 -11.4005 21.228 -2.49312 16.5191C6.5298 11.7477 17.0052 9.13644 28.0567 6.6167L28.5928 6.88793C17.4722 9.48952 6.92622 12.1842 -2.18657 16.999C-11.2015 21.7528 -18.7832 29.511 -25.8448 40.195Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34067C5.87818 6.13203 6.76249 5.92339 7.67891 5.73401C19.7255 14.1454 29.5702 24.1506 36.8565 35.6997C44.0338 47.0786 48.8117 59.6131 52.6779 72.2279C51.6283 72.9517 50.5626 73.6803 49.513 74.4041C45.2295 61.7332 40.2157 49.1217 33.0962 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34067Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99707 6.34064C5.11584 6.31818 5.23781 6.27805 5.35497 6.24756C16.8719 15.1694 26.3924 25.518 33.6017 37.25C40.7292 48.836 45.7157 61.4331 49.9431 74.0992L49.505 74.4041C45.2214 61.7332 40.2076 49.1217 33.0882 37.5036C25.8949 25.7507 16.4338 15.3395 4.99707 6.34064Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6394 6.67283C3.76459 6.63913 3.88335 6.61826 4.00051 6.58777C15.2045 15.7792 24.5324 26.3364 31.6952 38.1583C38.7842 49.8583 43.8798 62.4923 48.3062 75.1744C48.1457 75.2723 48.0045 75.3606 47.8488 75.4505C43.3662 62.7715 38.2433 50.1343 31.1672 38.4007C24.0204 26.5483 14.76 15.9365 3.6394 6.67283Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7157 5.54302C8.85533 5.52055 8.98051 5.48845 9.1073 5.46277C21.4412 13.5869 31.4511 23.3545 38.776 34.788C45.9709 46.0226 50.5963 58.5041 54.2427 71.0787C54.1031 71.1879 53.9699 71.2874 53.8303 71.3997C50.1245 58.8139 45.4574 46.3179 38.2625 35.0368C30.9504 23.5728 20.9677 13.7362 8.7157 5.54141V5.54302Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1073 5.46281C9.2389 5.44515 9.36088 5.43071 9.48927 5.40503C21.9002 13.4297 31.9695 23.1411 39.2896 34.5281C46.4909 45.7321 51.0891 58.1896 54.6536 70.7449C54.514 70.8541 54.3824 70.9616 54.2427 71.0659C50.5963 58.4977 45.9709 46.0226 38.776 34.7881C31.4511 23.3546 21.4412 13.587 9.1073 5.46281Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.30334 5.80784C7.43174 5.78216 7.55692 5.74845 7.6789 5.73401C19.7255 14.1454 29.5701 24.1506 36.8565 35.6997C44.0338 47.0786 48.8116 59.6131 52.6779 72.2279C52.5367 72.3306 52.3826 72.4285 52.2478 72.528C48.3221 59.8988 43.5138 47.3563 36.3365 35.9356C29.0598 24.356 19.2537 14.3091 7.30334 5.80784Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13981 6.06944C6.25536 6.04055 6.38055 6.00684 6.50092 5.98438C18.2827 14.651 27.9733 24.8246 35.2292 36.4652C42.3871 47.9581 47.2661 60.5295 51.317 73.17C51.1709 73.2646 51.0297 73.3674 50.8756 73.4653C46.7847 60.8152 41.8591 48.2406 34.7044 36.7124C27.4661 25.0525 17.8365 14.8099 6.1366 6.06623L6.13981 6.06944Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.5688 49.5357 -25.6731 48.4893 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C37.4777 12.4939 38.5562 13.3285 39.5673 14.1791C27.4613 18.4386 15.6924 22.7045 4.76599 28.2078C-6.05445 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.4757 50.5757C-25.4885 50.4296 -25.5078 50.2948 -25.5206 50.1488C-16.143 40.5529 -6.28073 33.1365 4.49156 27.7054C15.3457 22.2182 27.0842 18.0133 39.1548 13.8196C39.2816 13.9271 39.418 14.0587 39.5593 14.1791C27.4613 18.4386 15.6924 22.7045 4.766 28.2078C-6.05444 33.6645 -16.0082 41.0472 -25.4757 50.5757Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.3008 52.1518C-25.3008 52.0218 -25.3297 51.879 -25.3522 51.7361C-15.6488 42.373 -5.47351 35.0577 5.51705 29.5544C16.6023 24.0029 28.4804 19.5814 40.6458 15.1614C40.779 15.285 40.9202 15.4053 41.0534 15.5289C28.8672 20.0099 16.957 24.4828 5.81075 30.0487C-5.24401 35.5697 -15.5091 42.8496 -25.3008 52.1518Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8079 46.2215C-25.8192 46.0835 -25.8272 45.9262 -25.8256 45.7738C-17.4174 35.6322 -8.49236 27.9928 1.56895 22.804C11.7201 17.5655 23.0108 13.9769 34.7332 10.4348C34.8937 10.5375 35.0542 10.6483 35.2147 10.7558C23.4409 14.3749 12.1037 18.0181 1.86105 23.2919C-8.26767 28.5095 -17.297 36.1313 -25.8144 46.2264L-25.8079 46.2215Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.8255 45.7738C-25.8255 45.6261 -25.8367 45.4769 -25.8416 45.3276C-17.5392 35.1299 -8.71856 27.484 1.25448 22.3273C11.3318 17.1145 22.5583 13.5933 34.247 10.1299L34.7285 10.4396C23.0125 13.9833 11.7154 17.5703 1.56423 22.8088C-8.48905 27.9928 -17.4173 35.6322 -25.8255 45.7738Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.7309 47.8746C-25.7453 47.7285 -25.7502 47.5793 -25.7694 47.4445C-16.9856 37.4763 -7.6979 29.9315 2.63785 24.64C13.0699 19.2956 24.5371 15.4679 36.4104 11.6658C36.5597 11.7829 36.7089 11.9001 36.855 12.0092C24.9448 15.8691 13.4342 19.7659 2.93476 25.1311C-7.46679 30.4467 -16.8476 37.9706 -25.7309 47.8746Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-25.6105 49.2324C-25.6105 49.0847 -25.6298 48.9387 -25.6394 48.8007C-16.5571 39.0234 -6.98209 31.5332 3.57032 26.1711C14.2158 20.7465 25.8227 16.7309 37.7906 12.7299L38.2384 13.0813C26.2303 17.1434 14.5769 21.2231 3.86242 26.659C-6.76061 32.0468 -16.4303 39.5129 -25.6105 49.2324Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47686C-1.12893 8.16871 -0.326466 7.86538 0.508097 7.6134C10.815 17.511 19.5329 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C42.6038 78.5369 41.4595 79.1644 40.32 79.7823C35.0622 67.1451 29.5509 54.4693 22.7685 42.3874C15.8971 30.1521 7.60656 18.7699 -1.9314 8.47686Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-1.9314 8.47687C-1.82066 8.43033 -1.70992 8.38539 -1.61041 8.35168C8.01522 18.565 16.3682 29.9067 23.266 42.129C30.0821 54.2077 35.5806 66.8867 40.7951 79.5255C40.6346 79.6154 40.4741 79.7021 40.3136 79.7823C35.0558 67.1451 29.5445 54.4693 22.7621 42.3874C15.8927 30.1524 7.60431 18.7703 -1.9314 8.47687Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-3.14795 8.93903C-3.03721 8.89409 -2.93128 8.86039 -2.82696 8.81384C6.46005 19.295 14.5676 30.7647 21.3497 43.0165C28.0663 55.153 33.6306 67.8095 39.0039 80.421C38.8515 80.5013 38.6829 80.5815 38.5225 80.6617C33.1138 68.0598 27.5191 55.4178 20.8329 43.2669C14.0861 31.002 6.04672 19.4939 -3.14795 8.93903Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.43896 7.32129C1.55612 7.2908 1.66847 7.25389 1.79044 7.21216C12.4375 16.8562 21.3931 27.7264 28.4564 39.7024C35.4506 51.5628 40.7212 64.2353 45.4686 76.9223C45.3242 77.017 45.1669 77.1085 45.0128 77.2064C40.2141 64.5162 34.9226 51.8485 27.9364 39.9527C20.8891 27.9655 11.9946 17.0375 1.43896 7.32129Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.79529 7.21211C1.91455 7.18209 2.03148 7.14347 2.14516 7.09656C12.8709 16.6796 21.8954 27.5 28.97 39.4423C35.9803 51.2835 41.2252 63.9512 45.9116 76.635C45.7736 76.7264 45.6211 76.8243 45.4638 76.9158C40.7213 64.2369 35.4507 51.5579 28.4516 39.6975C21.3931 27.7263 12.4376 16.8561 1.79529 7.21211Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.155029 7.72093C0.277004 7.68081 0.394164 7.65032 0.506509 7.6134C10.815 17.511 19.533 28.5626 26.5224 40.6092C33.4493 52.5514 38.8178 65.2368 43.7481 77.9093C43.5876 77.9912 43.4272 78.0827 43.2667 78.1661C38.2914 65.4872 32.9052 52.8163 25.9944 40.8403C19.037 28.776 10.372 17.6908 0.155029 7.72093Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-0.891357 8.07878C-0.788642 8.03705 -0.674692 8.00014 -0.570371 7.9552C9.33431 17.9331 17.894 29.1614 24.8918 41.3554C31.7722 53.3699 37.1888 66.0537 42.2604 78.7117C42.1079 78.8113 41.9555 78.8899 41.7966 78.9734C36.6785 66.3153 31.2233 53.6379 24.3751 41.6026C17.4115 29.3998 8.92095 18.1344 -0.891357 8.07878Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.8619C-24.3346 57.8829 -24.5272 56.8927 -24.7278 55.9056C-14.6062 47.4873 -3.51314 40.3108 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C45.3964 19.8364 46.2856 20.7737 47.0993 21.7495C34.661 27.1052 22.3031 32.4047 10.311 38.176C-1.61037 43.9136 -13.0808 50.6399 -24.1372 58.8619Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.1372 58.862C-24.1677 58.7224 -24.1966 58.5827 -24.2223 58.4511C-13.2317 50.1585 -1.8271 43.3969 10.0494 37.6769C21.982 31.9104 34.3127 26.6575 46.7557 21.3403C46.8681 21.48 46.9869 21.61 47.0992 21.7512C34.661 27.1052 22.303 32.4047 10.311 38.176C-1.61043 43.9121 -13.0809 50.6399 -24.1372 58.862Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.7985 60.3385C-23.8354 60.2021 -23.8707 60.0737 -23.9044 59.9437C-12.6523 51.919 -0.997252 45.3067 11.0381 39.545C23.1393 33.7512 35.539 28.3281 48.0029 22.8345C48.1152 22.9757 48.2292 23.1153 48.3351 23.2421C35.8712 28.7807 23.4587 34.2503 11.3158 40.0457C-0.769354 45.8155 -12.4805 52.3812 -23.7985 60.3385Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9091 54.7791C-24.9316 54.6362 -24.9524 54.4934 -24.9701 54.3586C-14.7403 45.3806 -4.05143 38.2804 7.30666 32.6712C18.753 27.017 30.8269 22.2568 43.1319 17.4773C43.2571 17.6041 43.3855 17.7389 43.4994 17.8609C31.1832 22.6981 19.0756 27.5097 7.57628 33.1831C-3.83958 38.8004 -14.6103 45.8749 -24.9091 54.7791Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.9702 54.3585C-24.9965 54.2221 -25.0158 54.0844 -25.028 53.946C-14.8832 44.899 -4.27781 37.7587 7.03213 32.1591C18.4271 26.521 30.4641 21.8137 42.7627 17.0936C42.8879 17.2204 43.0098 17.3392 43.1318 17.4772C30.8268 22.2567 18.7529 27.0169 7.30657 32.6711C-4.05152 38.2803 -14.7404 45.3805 -24.9702 54.3585Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.6459 56.3021C-24.6732 56.1705 -24.6989 56.0389 -24.7278 55.8992C-14.6058 47.4831 -3.51282 40.3088 8.31451 34.5296C19.9567 28.8289 32.1461 23.8745 44.5057 18.8992C44.6245 19.0292 44.7496 19.156 44.8748 19.2827C32.4912 24.3174 20.2857 29.312 8.6034 35.0255C-3.26448 40.8093 -14.422 47.9491 -24.6459 56.3021Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3924 57.5781C-24.4181 57.4464 -24.455 57.31 -24.4742 57.1752C-13.7212 48.6691 -2.52201 41.7968 9.20521 36.0993C20.995 30.3681 33.2519 25.2564 45.6644 20.111C45.7816 20.241 45.91 20.3758 46.0335 20.4945C33.6146 25.6945 21.3401 30.8544 9.49089 36.5968C-2.28448 42.3055 -13.5479 49.1377 -24.3924 57.5781Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058C-7.48127 10.987 -6.75905 10.5825 -5.98708 10.2246C2.31446 21.3571 9.72289 33.1285 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C32.8843 83.212 31.6694 83.7352 30.424 84.2087C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-8.20349 11.4058L-7.89855 11.2277C-0.236618 22.6692 6.65816 34.6404 12.919 46.9438C19.1606 59.2102 24.964 71.6822 30.9327 84.0176C30.7722 84.0899 30.6021 84.1541 30.424 84.2167C24.4231 71.894 18.6117 59.4462 12.399 47.1829C6.17668 34.8924 -0.637851 22.8859 -8.20349 11.4058Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-9.29956 12.0414C-9.19845 11.9724 -9.09894 11.9242 -9.00265 11.8665C-1.74355 23.4717 4.86876 35.536 10.9916 47.8169C17.1144 60.0978 22.9451 72.4814 29.0294 84.7174C28.8689 84.7848 28.6972 84.8522 28.5206 84.9165C22.401 72.6933 16.5575 60.3241 10.4716 48.0592C4.38568 35.7944 -2.14318 23.6884 -9.29956 12.0414Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-5.14124 9.81852C-5.03371 9.76555 -4.93099 9.72383 -4.83148 9.67407C3.85406 20.5771 11.53 32.2476 18.1014 44.5412C24.6527 56.7547 30.3277 69.3646 35.953 81.9056C35.7797 81.9762 35.624 82.0484 35.4507 82.119C29.7933 69.615 24.1102 57.0051 17.5814 44.7803C11.0387 32.4875 3.43563 20.7892 -5.14124 9.81852Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-4.83789 9.67406C-4.73036 9.62109 -4.62123 9.57616 -4.5169 9.52319C4.25997 20.3669 12.0033 32.0079 18.6117 44.294C25.192 56.4915 30.8477 69.1142 36.4265 81.6552C36.266 81.7466 36.1151 81.8157 35.945 81.9055C30.3277 69.363 24.6527 56.7547 18.1014 44.5412C11.5279 32.2473 3.84988 20.5769 -4.83789 9.67406Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-6.30481 10.3723C-6.20163 10.3151 -6.09434 10.2658 -5.98382 10.2246C2.31661 21.3572 9.72397 33.1286 16.169 45.4287C22.6112 57.6663 28.3424 70.2297 34.1009 82.6968C33.9276 82.7691 33.7575 82.8477 33.6002 82.9119C27.8016 70.4592 22.0623 57.9103 15.6474 45.6599C9.23325 33.3589 1.89477 21.5625 -6.30481 10.3723Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-7.27258 10.8649L-6.96765 10.6868C1.01468 22.0067 8.20119 33.8673 14.54 46.183C20.8779 58.4415 26.6572 70.9615 32.5233 83.3773C32.3628 83.4447 32.1878 83.5233 32.0209 83.5907C26.118 71.191 20.3274 58.7015 14.0152 46.4237C7.68857 34.1268 0.626882 22.1893 -7.27258 10.8649Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.017 66.6812C-22.3027 65.7567 -22.5948 64.8163 -22.8837 63.8838C-10.9911 56.6151 1.2593 50.42 13.6927 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.9301 27.9494 52.6668 28.9669 53.3232 30.0069C40.8626 36.2758 28.2959 42.413 15.6074 48.2742C2.93966 54.1226 -9.70238 59.971 -22.0122 66.6764L-22.017 66.6812Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.0171 66.6811C-22.0556 66.564 -22.0941 66.4276 -22.139 66.2944C-9.87898 59.5119 2.7117 53.6042 15.3521 47.7574C28.0182 41.8978 40.5864 35.8055 53.0535 29.5784C53.1402 29.7212 53.2365 29.8689 53.3232 30.0117C40.8625 36.2806 28.2959 42.4178 15.6073 48.279C2.9396 54.1274 -9.70243 59.9757 -22.0122 66.6811H-22.0171Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-21.5356 68.1063C-21.5901 67.9763 -21.6399 67.8544 -21.6848 67.7212C-9.23056 61.234 3.54308 55.4996 16.2846 49.6561C29.055 43.7997 41.6233 37.5549 54.0695 31.1849C54.1562 31.3294 54.2508 31.469 54.3359 31.6118C41.9121 38.0316 29.3391 44.3117 16.5478 50.1632C3.75654 56.0148 -9.03636 61.6882 -21.5356 68.1063Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.1758 62.8085C-23.2079 62.6881 -23.2368 62.5485 -23.2737 62.4121C-11.6042 54.8689 0.437553 48.507 12.7329 42.7003C25.0732 36.8744 37.5612 31.1801 50.0684 25.3864C50.1727 25.5308 50.2674 25.6704 50.3685 25.8068C37.8709 31.6424 25.3621 37.3768 12.9945 43.2107C0.657429 49.0222 -11.4486 55.3311 -23.1758 62.8085Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-23.277 62.412C-23.3075 62.2917 -23.3444 62.1553 -23.3749 62.0156C-11.7728 54.389 0.21759 47.9869 12.4632 42.1851C24.7843 36.3512 37.261 30.7163 49.7473 24.9771C49.8468 25.1071 49.9512 25.2499 50.0539 25.3863C37.5627 31.1801 25.0731 36.8744 12.7328 42.7003C0.437466 48.5069 -11.6043 54.8641 -23.277 62.412Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.7665 64.2626C-22.8082 64.1374 -22.8451 64.0009 -22.8837 63.8838C-10.9911 56.6151 1.25929 50.42 13.6911 44.5973C26.1742 38.7506 38.6975 32.899 51.1871 26.9431C51.285 27.0715 51.3909 27.216 51.4952 27.3588C39.0056 33.3505 26.4963 39.2588 13.9671 45.0836C1.49683 50.9047 -10.813 57.0644 -22.7665 64.2561V64.2626Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-22.3894 65.4791C-22.4343 65.3475 -22.4792 65.2143 -22.5129 65.0859C-10.4374 58.0595 1.99113 52.0009 14.5369 46.167C27.1131 40.3186 39.6481 34.3461 52.142 28.2495C52.2479 28.394 52.349 28.5304 52.4517 28.6668C39.975 34.8094 27.4277 40.8134 14.8097 46.6789C2.23026 52.5177 -10.2545 58.5249 -22.3894 65.4791Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.3539C-13.2028 14.8034 -12.5528 14.2529 -11.8659 13.7666C-5.71258 25.7795 0.0747986 38.001 5.82687 50.2258C11.5645 62.4233 17.4674 74.5581 23.8486 86.4972C22.5647 86.8974 21.2807 87.2687 19.9968 87.611C13.423 75.9609 7.51044 64.0763 2.08096 51.9816C-3.34852 39.8868 -8.53084 27.5867 -13.8464 15.3539H-13.8528Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.8528 15.354C-13.7661 15.2737 -13.6762 15.1935 -13.5815 15.118C-8.15368 27.338 -2.89432 39.6205 2.59454 51.7425C8.0834 63.8646 13.9895 75.7732 20.5232 87.4715L19.9904 87.6095C13.423 75.9609 7.51044 64.0764 2.08096 51.9817C-3.34852 39.8869 -8.53084 27.5867 -13.8464 15.354H-13.8528Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-14.8607 16.203C-14.758 16.1163 -14.6713 16.0425 -14.5815 15.9639C-9.59173 28.2833 -4.65978 40.5658 0.681424 52.6317C6.01356 64.7208 11.9749 76.5225 18.5411 87.9883C18.3581 88.0365 18.1752 88.0846 18.0083 88.1247C11.4042 76.7167 5.44385 64.9481 0.155007 52.8741C-5.12842 40.8371 -9.97531 28.5369 -14.8591 16.203H-14.8607Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-11.0988 13.2306L-10.8195 13.0364C-4.23929 24.9032 1.85945 37.0879 7.75115 49.3415C13.6428 61.5951 19.5185 73.8263 25.8018 85.8841C25.6204 85.9403 25.4407 85.9965 25.277 86.0446C18.9696 74.0157 13.0795 61.8102 7.23115 49.579C1.38278 37.3479 -4.64533 25.1472 -11.0988 13.2306Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-10.8195 13.0364C-10.7264 12.9706 -10.6317 12.9064 -10.5225 12.8358C-3.85727 24.677 2.34257 36.8327 8.27921 49.0992C14.2158 61.3657 20.069 73.6274 26.3299 85.7125C26.1533 85.7767 25.9736 85.8328 25.8018 85.8842C19.5185 73.8264 13.6365 61.5743 7.75119 49.3416C1.86591 37.1088 -4.24566 24.9033 -10.8195 13.0364Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-12.1532 13.964L-11.8659 13.7634C-5.71261 25.7763 0.0747623 37.9979 5.82683 50.2226C11.5645 62.4201 17.4674 74.555 23.8486 86.494L23.3158 86.6545C16.9025 74.7508 11.0043 62.6464 5.30363 50.4569C-0.397086 38.2675 -6.11706 26.0074 -12.1532 13.964Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-13.0054 14.6253C-12.9131 14.5588 -12.8262 14.4853 -12.7454 14.4054C-6.94355 26.5322 -1.40173 38.8003 4.21553 50.9721C9.83278 63.1439 15.7405 75.1841 22.2052 87.0076L21.6724 87.1681C15.1788 75.3927 9.27266 63.3734 3.70195 51.2273C-1.8816 39.0555 -7.33194 26.7794 -13.0054 14.6253Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.4765 73.2582 -19.8729 72.3787 -20.2677 71.48C-7.33838 65.7953 5.85254 60.5777 18.8332 54.7421C31.8139 48.9066 44.3516 42.3216 56.6373 35.552C57.2263 36.6289 57.8057 37.7106 58.3434 38.8004C46.2165 45.8252 33.7542 52.6622 20.6805 58.4511C7.58265 64.2498 -5.86185 69.0934 -19.0817 74.157Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.0817 74.157C-19.1347 74.0286 -19.1956 73.9019 -19.2422 73.7863C-6.05769 68.6393 7.35471 63.7362 20.4413 57.9328C33.5038 52.1406 45.9725 45.3341 58.1299 38.3527L58.3433 38.8085C46.2165 45.8252 33.7542 52.6622 20.6804 58.4496C7.58261 64.2498 -5.86189 69.0935 -19.0817 74.157Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.4622 75.5052C-18.5199 75.3864 -18.5857 75.2708 -18.6467 75.144C-5.32581 70.3212 8.20375 65.6236 21.3273 59.841C34.4187 54.0729 46.8456 47.1621 58.9003 40.041C58.9597 40.1855 59.0335 40.3427 59.1073 40.5C47.08 47.65 34.6787 54.5945 21.5824 60.3514C8.43807 66.1292 -5.10433 70.7835 -18.4622 75.5052Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.648 70.4608C-20.701 70.3308 -20.7395 70.2121 -20.7925 70.0821C-8.03008 64.1005 5.00838 58.6742 17.9168 52.8371C30.8253 47 43.3887 40.5209 55.7434 33.8989C55.8221 34.045 55.9039 34.1878 55.989 34.3419C43.6487 41.0136 31.0933 47.5152 18.164 53.3507C5.23467 59.1862 -7.83588 64.5563 -20.648 70.4608Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.7926 70.0821C-20.8455 69.9537 -20.8888 69.8285 -20.9386 69.7049C-8.22113 63.6351 4.78201 58.1655 17.6696 52.3235C30.5572 46.4816 43.127 40.0394 55.5026 33.4512L55.7433 33.9038C43.3854 40.5209 30.8268 47 17.9168 52.8419C5.0067 58.6839 -8.03015 64.1005 -20.7926 70.0821Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-20.1104 71.8443C-20.1569 71.7287 -20.2179 71.6019 -20.2709 71.48C-7.33835 65.7953 5.85257 60.5793 18.8332 54.7421C31.8139 48.905 44.3516 42.3216 56.6374 35.552C56.716 35.6981 56.7979 35.8521 56.8861 36.003C44.6116 42.7999 32.0803 49.4282 19.0932 55.2509C6.10615 61.0736 -7.13613 66.2447 -20.1104 71.8443Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.5856 72.995C-19.6434 72.8762 -19.6963 72.7462 -19.7461 72.6355C-6.68196 67.2157 6.60846 62.1505 19.6517 56.3294C32.6693 50.5196 45.1701 43.811 57.4045 36.9451C57.4799 37.0831 57.565 37.2372 57.642 37.3912C45.4446 44.2924 32.9438 51.0331 19.9069 56.8398C6.87007 62.6464 -6.47814 67.6666 -19.5744 72.995H-19.5856Z"
          fill="#985B00"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.3353 19.9842 -17.7431 19.2443 -17.1621 18.5253C-13.3552 31.0437 -9.32526 43.2797 -4.4559 55.0711C0.394197 66.816 6.29713 78.1629 13.1309 89.1759C11.8053 89.4247 10.4587 89.6269 9.12983 89.8179C2.19011 79.2671 -3.68072 68.3487 -8.17773 56.8975C-12.7004 45.3902 -16.0387 33.2778 -18.9436 20.7048Z"
          fill="#98338E"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.9436 20.7048C-18.8633 20.6024 -18.777 20.5049 -18.6852 20.4127C-15.6615 32.9729 -12.2238 45.1078 -7.66416 56.6488C-3.1222 68.1433 2.74703 79.1179 9.67711 89.7409C9.50218 89.7682 9.31761 89.7906 9.12823 89.8227C2.19011 79.2719 -3.68072 68.3536 -8.17773 56.9024C-12.6972 45.3902 -16.0419 33.2778 -18.9436 20.7048Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-19.8151 21.9487C-19.7396 21.8395 -19.6546 21.7176 -19.5807 21.5956C-17.0209 34.1766 -13.9314 46.2184 -9.55154 57.6134C-5.20057 68.9362 0.651003 79.694 7.63887 90.0475C7.46393 90.0731 7.27134 90.0988 7.08517 90.1133C0.074831 79.8417 -5.75427 69.1545 -10.0619 57.8766C-14.384 46.5362 -17.3788 34.5105 -19.8151 21.9487Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.4672 17.8207C-16.3757 17.7293 -16.2906 17.6426 -16.2072 17.5543C-11.9605 29.9989 -7.59029 42.2702 -2.55242 54.166C2.51942 66.1025 8.43297 77.6636 15.1435 88.7619L14.5995 88.8742C7.87557 77.8357 1.97483 66.3164 -3.05476 54.4099C-8.04609 42.5463 -12.3329 30.2878 -16.4607 17.8175L-16.4672 17.8207Z"
          fill="#E0FFCB"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-16.2073 17.5543L-15.9553 17.2911C-11.5851 29.7197 -7.12819 42.0071 -2.03253 53.9269C3.08934 65.887 9.00992 77.489 15.6891 88.6544C15.519 88.6881 15.3328 88.7298 15.1434 88.7619C8.43285 77.6636 2.51931 66.1025 -2.55253 54.166C-7.59041 42.2687 -11.9606 29.999 -16.2073 17.5543Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-17.4045 18.819C-17.3243 18.7227 -17.244 18.62 -17.1654 18.5253C-13.3585 31.0437 -9.32853 43.2797 -4.45918 55.0711C0.390923 66.8176 6.29386 78.1629 13.1276 89.1759C12.9415 89.216 12.7633 89.2529 12.582 89.2834C5.73694 78.3282 -0.157963 67.0471 -4.96473 55.3215C-9.77952 43.5734 -13.7325 31.3455 -17.4045 18.819Z"
          fill="#650028"
        />
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-18.162 19.761C-18.0817 19.6647 -18.0015 19.562 -17.9229 19.4673C-14.506 32.0002 -10.7777 44.1993 -6.06081 55.8671C-1.35837 67.4948 4.53172 78.6571 11.4201 89.4824C11.2275 89.5064 11.0381 89.5401 10.8648 89.5658C3.96358 78.8128 -1.91689 67.7051 -6.56958 56.111C-11.2399 44.4817 -14.8671 32.3083 -18.162 19.761Z"
          fill="#985B00"
        />
      </g>
      <path
        d="M41.6778 9.86072L41.6971 16.4024L24.619 26.2631L24.5997 19.7214L41.6778 9.86072Z"
        fill="#DB5B54"
      />
      <path
        d="M24.6189 26.2631L7.65158 16.4024L7.63232 9.86072L24.5996 19.7214L24.6189 26.2631Z"
        fill="#A24741"
      />
      <path
        d="M24.5996 19.7214L7.63232 9.86069L24.712 0L41.6777 9.86069L24.5996 19.7214Z"
        fill="#F2716A"
      />
      <path
        d="M38.569 21.3682C39.9885 20.5282 41.5237 19.9015 43.1254 19.5081V22.1241C41.5239 22.5174 39.9891 23.1447 38.5706 23.9858C36.9326 24.9462 35.4061 26.0851 34.019 27.3818V24.7658C35.406 23.4692 36.9319 22.3298 38.569 21.3682Z"
        fill="#DB5B54"
      />
      <path
        d="M34.0239 27.3818L28.3697 24.0965L28.3617 21.4789L34.0175 24.7658L34.0239 27.3818Z"
        fill="#A24741"
      />
      <path
        d="M34.0173 24.7657L28.3616 21.4788C29.7489 20.1825 31.2754 19.0435 32.9132 18.0828C34.5509 17.122 37.4326 16.1569 37.4326 16.1569L43.1301 19.5272C43.1301 19.5272 40.2066 20.4066 38.5689 21.3681C36.9312 22.3296 35.4048 23.469 34.0173 24.7657Z"
        fill="#F2716A"
      />
      <path
        d="M39.3793 34.042L38.2495 33.384L37.9493 33.1689L39.0808 33.8254L39.3793 34.042Z"
        fill="#898989"
      />
      <path
        d="M37.1967 36.4575L36.0652 35.7995L35.473 35.2747L36.6045 35.9311L37.1967 36.4575Z"
        fill="#898989"
      />
      <path
        d="M25.7439 39.3319L24.6124 38.6755L24.6108 38.255L25.7423 38.913L25.7439 39.3319Z"
        fill="#888888"
      />
      <path
        d="M42.9343 27.1151L42.9375 28.2691L42.9391 28.9849L42.9407 29.4037L39.3793 34.042L39.0808 33.8253L37.1966 36.4574L36.6044 35.931L34.3575 39.4073L32.0978 38.5326L31.5104 39.7411L29.6165 39.2901L29.318 39.8502L25.7438 39.3318L25.7422 38.913L25.7374 37.0448L42.9343 27.1151Z"
        fill="#D6D6D6"
      />
      <path
        d="M25.7423 38.913L24.6108 38.255L24.606 36.3868L25.7374 37.0449L25.7423 38.913Z"
        fill="#888888"
      />
      <path
        d="M25.7376 37.045L24.6061 36.387L41.8029 26.4589L42.9344 27.1153L25.7376 37.045Z"
        fill="#FDFDFD"
      />
      <path
        d="M16.161 28.0383L17.2925 27.3802L24.619 36.3791L23.4875 37.0355L16.161 28.0383Z"
        fill="#FDFDFD"
      />
      <path
        d="M23.4874 37.0354L24.6189 36.379L25.7568 37.0354L24.6269 37.6919L23.4874 37.0354Z"
        fill="#FDFDFD"
      />
      <path
        d="M24.6269 37.6918L25.7568 37.0354L25.7504 39.3305L24.6205 39.9885L24.6269 37.6918Z"
        fill="#D6D6D6"
      />
      <path
        d="M16.177 22.6665L16.161 28.0382L23.4875 37.0355L24.627 37.6919L24.6206 39.9885L16.1417 34.8239L14.1243 33.6587L13.1196 33.0777L13.126 30.7602L14.1436 26.873L14.1596 21.5013L16.177 22.6665Z"
        fill="#898989"
      />
      <path
        d="M16.1771 22.6666L17.3085 22.0085L17.2925 27.3802L16.161 28.0383L16.1771 22.6666Z"
        fill="#FDFDFD"
      />
      <path
        d="M14.1597 21.5014L15.2911 20.845L17.3085 22.0085L16.1771 22.6666L14.1597 21.5014Z"
        fill="#DCDCDC"
      />
    </>
  )
});

export default sJOE;
