// @ts-nocheck
// @ts-nocheck
import React from "react";
import { Flex, Box, Text, Spacer, Divider } from "@chakra-ui/react";
import StatColumn from "./StatColumn";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { format, formatWithDecimals, getNum } from "../../Utils/number";

const selector = ({ vePREONStaked, PREONPrice }: LiquityStoreState) => ({
  vePREONStaked,
  PREONPrice
});

const StakeSummary: React.FC = () => {
  const { vePREONStaked, PREONPrice } = useLiquitySelector(selector);

  const veBalance = formatWithDecimals(vePREONStaked.vePREONTotal, 36);

  return (
    <Box flex={1}>
      <Text textStyle="title3" textAlign={["center", "left"]} pb={6}>
        Stake Summary
      </Text>
      <Divider mb={5} height="0.5px" opacity="0.4" />
      <StatColumn
        iconName="PREON"
        amount={getNum(veBalance, 2)}
        units="vePREON"
        description="Accrued"
      />
      {/* <Text textStyle="title3" textAlign="center" pt={10} color="purple">
          PREON Price: ${PREONPrice.toString(3)}
        </Text> */}
    </Box>
  );
};

export default StakeSummary;
