// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

const qiUSDC = createIcon({
  displayName: "qiUSDC",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image xmlns="http://www.w3.org/2000/svg" width="3563" height="3564" transform="translate(144.76 144.72) scale(0.48)" href="https://i.ibb.co/8YrtJFc/benqi-circle.png"/>
        <circle xmlns="http://www.w3.org/2000/svg" cx="993.59" cy="999.85" r="642.06" fill="#fff"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M1134.84,1071.5c0-71.5-42.9-96-128.71-106.23-61.29-8.17-73.55-24.52-73.55-53.12s20.43-47,61.29-47c36.78,0,57.21,12.26,67.42,42.9a15.37,15.37,0,0,0,14.3,10.22h32.69a14,14,0,0,0,14.3-14.3v-2.05a102.08,102.08,0,0,0-91.93-83.76v-49c0-8.17-6.13-14.3-16.35-16.35H983.66c-8.18,0-14.31,6.13-16.35,16.35v47C906,824.3,867.2,865.16,867.2,916.24c0,67.42,40.86,94,126.67,104.19,57.21,10.22,75.59,22.47,75.59,55.16s-28.6,55.17-67.42,55.17c-53.12,0-71.5-22.48-77.63-53.13-2-8.17-8.17-12.25-14.3-12.25H875.37a14,14,0,0,0-14.29,14.3v2c8.16,51.08,40.85,87.85,108.27,98.07v49c0,8.17,6.13,14.3,16.35,16.34h30.64c8.18,0,14.31-6.13,16.35-16.34v-49c61.29-10.22,102.15-53.12,102.15-108.29Z" fill="#00b2ec"/>
        <path xmlns="http://www.w3.org/2000/svg" d="M895.81,1286C736.45,1228.82,654.73,1051.08,714,893.76,744.62,808,812,742.58,895.81,711.93c8.17-4.08,12.25-10.21,12.25-20.43V662.9c0-8.17-4.08-14.3-12.25-16.34-2,0-6.13,0-8.18,2-194.09,61.29-300.32,267.64-239,461.72,36.77,114.41,124.62,202.26,239,239,8.18,4.08,16.35,0,18.39-8.18,2-2,2-4.08,2-8.16v-28.61C908.06,1298.28,901.93,1290.11,895.81,1286ZM1112.37,648.6c-8.18-4.09-16.35,0-18.39,8.17-2,2.05-2,4.08-2,8.17v28.6c0,8.18,6.13,16.35,12.25,20.44,159.36,57.2,241.08,234.94,181.83,392.26-30.64,85.81-98.06,151.18-181.83,181.83-8.17,4.08-12.25,10.21-12.25,20.43v28.6c0,8.17,4.08,14.3,12.25,16.34,2,0,6.13,0,8.18-2,194.09-61.29,300.32-267.64,239-461.72-36.77-116.46-126.67-204.31-239-241.08Z" fill="#00b2ec"/>
      </g>
    </>
  )
});

export default qiUSDC;
