const Divider = {
  baseStyle: {
    opacity: 1,
    borderColor: "purple",
    borderWidth: 0,
    h: 0.5,
    bg: "gradient",
    borderRadius: "infinity"
  }
};

export default Divider;
