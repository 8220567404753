// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

const TooltipPrompt = createIcon({
  displayName: "TooltipPrompt",
  viewBox: "0 0 10 10",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.375C2.58369 9.375 0.625 7.41631 0.625 5C0.625 2.58369 2.58369 0.625 5 0.625C7.41631 0.625 9.375 2.58369 9.375 5C9.375 7.41631 7.41631 9.375 5 9.375ZM5 8.5C5.92826 8.5 6.8185 8.13125 7.47487 7.47487C8.13125 6.8185 8.5 5.92826 8.5 5C8.5 4.07174 8.13125 3.1815 7.47487 2.52513C6.8185 1.86875 5.92826 1.5 5 1.5C4.07174 1.5 3.1815 1.86875 2.52513 2.52513C1.86875 3.1815 1.5 4.07174 1.5 5C1.5 5.92826 1.86875 6.8185 2.52513 7.47487C3.1815 8.13125 4.07174 8.5 5 8.5ZM4.5625 6.75H5.4375V7.625H4.5625V6.75ZM4.5625 5.8785C4.5625 5.8785 5.4375 5.875 5.4375 5.8785C5.4375 5.44012 6.75 5 6.75 4.125C6.75 3.15812 5.97431 2.375 5.00394 2.375C4.77379 2.37448 4.54581 2.41937 4.33303 2.50708C4.12026 2.59479 3.92687 2.72361 3.76395 2.88617C3.60103 3.04872 3.47178 3.24182 3.38359 3.45439C3.29539 3.66697 3.25 3.89486 3.25 4.125H4.125C4.125 3.64375 4.51875 3.25 5 3.25C5.48125 3.25 5.875 3.64375 5.875 4.125C5.875 4.51875 4.5625 5.16056 4.5625 5.8785Z"
      fill="currentColor"
    />
  )
});

export default TooltipPrompt;
