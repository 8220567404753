// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/modal";
import {
  Flex,
  Text,
  Button,
  Input,
  Select,
  background,
  Divider,
  Box,
  Wrap,
  WrapItem,
  Center
} from "@chakra-ui/react";
import { Stack, HStack, VStack } from "@chakra-ui/react";

import Icon from "../../../components/Icon";
import { Collateral, CoinShow } from "../../../Types";
import { getNum, format } from "../../../Utils/number";
import { Form } from "react-final-form";
import Tooltip from "../../../components/Tooltip";
import { tokenDataMappingT } from "../../../TokenData";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { AdjustInput } from "../../../components";

export type AddCollateralTypeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  availableCollateral: Collateral[];
  show?: any;
  setShow?: any;
  borrowMode: string;
};

// @ts-expect-error
const selector = ({ prices, total, safetyRatios }: LiquityStoreState) => ({
  prices,
  total,
  safetyRatios
});

// ! TODO: #pending: uncomment this to know state of store
// const selector2 = ({
//   prices,
//   total,
//   safetyRatios,
//   redemptionRate,
//   borrowingRate,
//   fees,
//   trove,
//   numberOfTroves,
//   accountBalance,
//   tokenBalances,
//   lpTokenBalance,
//   starBalance,
//   preonBalance,
//   collateralSurplusBalance,
//   underlyingPrices,
//   poolRewardRate,
//   starInStabilityPool,
//   totalRedistributed,
//   troveBeforeRedistribution,
//   stabilityDeposit,
//   farm,
//   vePREONStaked,
//   remainingStabilityPoolPREONReward,
//   _feesInNormalMode,
//   underlyingDecimals,
//   underlyingPerReceiptRatios,
//   receiptPerUnderlyingRatios,
//   STARPrice,
//   PREONPrice,
//   decimals,
//   recoveryRatios,
//   vcValue,
//   underlyingTokens,
//   vaultTokens,
//   whitelistedCollaterals
// }: LiquityStoreState) => ({
//   prices,
//   total,
//   safetyRatios,
//   redemptionRate,
//   borrowingRate,
//   fees,
//   trove,
//   numberOfTroves,
//   accountBalance,
//   tokenBalances,
//   lpTokenBalance,
//   starBalance,
//   preonBalance,
//   collateralSurplusBalance,
//   underlyingPrices,
//   poolRewardRate,
//   starInStabilityPool,
//   totalRedistributed,
//   troveBeforeRedistribution,
//   stabilityDeposit,
//   farm,
//   vePREONStaked,
//   remainingStabilityPoolPREONReward,
//   _feesInNormalMode,
//   underlyingDecimals,
//   underlyingPerReceiptRatios,
//   receiptPerUnderlyingRatios,
//   STARPrice,
//   PREONPrice,
//   decimals,
//   recoveryRatios,
//   vcValue,
//   underlyingTokens,
//   vaultTokens,
//   whitelistedCollaterals
// });

const AddCollateralTypeModal: React.FC<AddCollateralTypeModalProps> = ({
  isOpen,
  onClose,
  availableCollateral,
  show,
  setShow,
  borrowMode
}) => {
  // ! TODO: #pending: uncomment this to know state of store
  // const sl = useLiquitySelector(selector2);
  // useEffect(() => {
  //   console.log("STORE:", sl);
  //   console.log("")
  // }, [sl]);

  const { prices, total, safetyRatios } = useLiquitySelector(selector);
  const [toggle, setToggle] = useState(false);

  let filteredCollater: Collateral[] = availableCollateral.filter(
    coin => coin.token !== "qiUSDTn" && coin.token !== "av3CRV" && coin.token !== "qiUSDTn"
  );
  console.log("Filtered Collateral", filteredCollater);
  filteredCollater = filteredCollater.filter(coin =>
    borrowMode !== "unlever" ? true : coin.troveBalance !== 0
  );
  const onSubmit = (values: CoinShow) => {
    setShow({ ...show, ...values });
    onClose();
  };

  const [filterCollateral, setFilterCollateral] = useState("");
  const sortCollateral = e => {
    console.log(e.target.value);
    if (!!e.target.value && e.target.value === "sortByWalletBalance") {
      filteredCollater.sort((a, b) => a.walletBalance - b.walletBalance);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <Form
          onSubmit={onSubmit}
          initialValues={[]}
          render={({ values }) => (
            <>
              <ModalHeader fontSize="2xl" pb={2}>
                Add Collateral Type
              </ModalHeader>
              {/* <Flex justifyContent={"space-between"}>
                <Input
                  ml={6}
                  placeholder="Search"
                  mr={3}
                  onChange={e => setFilterCollateral(e.target.value.toUpperCase())}
                />
                <Select w={"60%"} mr={6} onChange={sortCollateral}>
                  <option value="sortByWalletBalance">Sort by Wallet Balance</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </Flex> */}
              {/* <Divider m={5} height="0.5px" opacity="0.4" /> */}

              <ModalBody>
                <Box>
                  <VStack>
                    <Flex justifyContent={"space-between"}>
                      <Input
                        ml={6}
                        placeholder="Search"
                        mr={5}
                        onChange={e => setFilterCollateral(e.target.value.toUpperCase())}
                      />
                    </Flex>
                    <Select w={"70%"} mr={6} onChange={sortCollateral}>
                      <option value="sortByWalletBalance">Sort by Wallet Balance</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </Select>
                    {filteredCollater
                      .filter(tokenName => tokenName.token.toUpperCase().includes(filterCollateral))
                      .filter(tokenName => {
                        if (!filterCollateral) return true;
                        if (tokenName.token.includes(filterCollateral)) {
                          return true;
                        }
                      })
                      .map(({ token, walletBalance, address }) => (
                        <VStack>
                          {/* <Flex p={"1rem"} variant="tokenSelect" backgroundColor={"brand.1100"}>{getNum(format(total.collaterals[address]), 2)}</Flex> */}
                          <Button
                            ml={2}
                            mr={2}
                            height="150px"
                            backgroundColor="brand.1100"
                            variant="tokenSelect"
                            bg={values[token] === true ? "brand.1100" : "brand.1100"}
                            borderColor={values[token] === true ? "purple" : "whiteAlpha.200"}
                            // my={-8}
                            onClick={() => {
                              values[token] = values[token] === true ? false : true;
                              setToggle(!toggle);
                            }}
                            key={token + format(safetyRatios[tokenDataMappingT[token].address])}
                          >
                            <Flex direction={"column"} justifyContent={"space-between"}>
                              <Flex direction={"row"} justifyContent={"space-between"} pt={3}>
                                <Flex align={"center"} flex={1}>
                                  <Icon
                                    bg="rgba(255,255,255,1)"
                                    p="2px"
                                    borderRadius="1em"
                                    iconName={token}
                                    h={6}
                                    w={6}
                                  />
                                  <Text ml={3} mr={3} fontSize="20px">
                                    {token}
                                  </Text>
                                  <Text flex={2} textAlign="left">
                                    {getNum(walletBalance)}
                                  </Text>
                                </Flex>
                              </Flex>
                              <Divider mt={4} mb={4} height="0.5px" opacity="0.4" />
                              <Flex direction={"column"}>
                                <Flex direction={"row"}>
                                  <Text textStyle="body1" mr={3} pl={2} pr={2}>
                                    System Deposit :
                                  </Text>
                                  <Text>
                                    {/* @ts-expect-error */}$
                                    {/* #TODO!! pending, Temp fix, changed from {getNum(format(total.collaterals[address].mul(prices[address])), 2)} */}
                                    {getNum(format(total.collaterals[address]), 2)}
                                  </Text>
                                </Flex>
                                <Flex direction={"row"} pb={3}>
                                  <Text textStyle="body1" mr={3} pl={2} pr={2}>
                                    Safety Ratio :
                                  </Text>
                                  <Text flex={2} textAlign="left">
                                    {getNum(format(safetyRatios[tokenDataMappingT[token].address]))}{" "}
                                    <Tooltip>
                                      {"Effective Minimum Collateral Ratio: " +
                                        (
                                          (1.1 /
                                            format(safetyRatios[tokenDataMappingT[token].address])) *
                                          100
                                        ).toFixed(2) +
                                        "%"}
                                    </Tooltip>
                                  </Text>
                                </Flex>
                              </Flex>
                            </Flex>
                          </Button>
                        </VStack>
                      ))}
                  </VStack>
                </Box>
              </ModalBody>

              <ModalFooter justifyContent={"flex-start"}>
                <Button variant="gradient" mr={6} onClick={() => onSubmit(values)}>
                  Add
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        />
      </ModalContent>
    </Modal>
  );
};

export default AddCollateralTypeModal;
