// @ts-nocheck
import { createIcon } from "@chakra-ui/react";

// #TODO!! pending, getting error from this svg, but can't figure out why
const Balancer = createIcon({
  displayName: "Balancer",
  viewBox: "0 0 250 250",
  path: (
    <g>
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M250 125c0 69.035-55.965 125-125 125S0 194.035 0 125 55.965 0 125 0s125 55.965 125 125zm0 0"
      />
      <path d="M159.645 132.637c30.066 4.441 51.039 14.488 51.039 26.172 0 15.796-38.36 28.605-85.684 28.605s-85.684-12.809-85.684-28.605c0-11.684 20.973-21.73 51.04-26.172 10.324 2.097 22.12 3.285 34.644 3.285 12.21 0 23.727-1.129 33.863-3.129zM146.688 85.602c27.23 3.027 46.859 11.605 46.859 21.714 0 12.641-30.688 22.887-68.547 22.887s-68.547-10.246-68.547-22.887c0-10.109 19.629-18.687 46.86-21.714 6.671 1.09 13.996 1.691 21.687 1.691 7.422 0 14.504-.563 20.984-1.578zM176.41 65.55c0 9.481-23.015 17.165-51.41 17.165S73.59 75.03 73.59 65.55 96.605 48.387 125 48.387s51.41 7.683 51.41 17.164zm0 0" />
    </g>
  )
});

export default Balancer;
