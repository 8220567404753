const mainnetContracts = {
  vePREON: {
    address: "0x570ADc5880D778aE5935f199aa590091586CDf75",
    txHash: "0xDead"
  },
  vePREONEmissions: {
    address: "0x57a7b5cD15eA2c64A48104482837519B81a0619a",
    txHash: "0xDead"
  },
  lpToken: {
    address: "0xF38e01324f49abBeEffe408Cedfca74577f97B43",
    txHash: "0xDead"
  },
  boostedFarm: {
    address: "0x0d6e43d4d7944408d9a5A10BC57B4348d61cD764",
    txHash: "0xDead"
  },
  preonToken: {
    address: "0xED2c0B4fAE32e94a8e3A96a2F999A235ef95a746",
    txHash: "0xDead"
  },
  lptoken: {
    address: "0xF38e01324f49abBeEffe408Cedfca74577f97B43"
  },
  borrowerOperations: {
    address: "0xef2fEa1e2231c85416d09Dc74ca57eD79884C3A6",
    txHash: "0xDead"
  },
  starToken: {
    address: "0x24F2D7c50357C7B1156Ca85945D3A901c88651cB",
    txHash: "0xDead"
  },
  stMaticVault: {
    address: "0x0c5bEbB1c0c42752903099CF2c862C7B7f07eB5c",
    txHash: "0xDead"
  },
  usdPlusVault: {
    address: "0x1D0DC97cdD22b8D6D763083722962418eae8F2Ff",
    txHash: "0xDead"
  }
};

// current: testnet
export const contractAddresses = {
  vePREON: {
    address: "0xA6407325fF7DAAB36D20992dADC031c82D1C4390",
    txHash: "0xDead"
  },
  vePREONEmissions: {
    address: "0x2Cd63cc1Ff41E263c5E1493C078E2cEDd6c8f018",
    txHash: "0xDead"
  },
  lpToken: {
    address: "0xcff03CEAea2e34d4e2C986043c8ba1638811e8E0",
    txHash: "0xDead"
  },
  boostedFarm: {
    address: "0x329058C12E8B269BFA0896c8705b427c5Dd26b96",
    txHash: "0xDead"
  },
  preonToken: {
    address: "0xe4FEed16F54b4244C174b408Ba3B5F1f19DD1E4D",
    txHash: "0xDead"
  },
  lptoken: {
    address: "0xcff03CEAea2e34d4e2C986043c8ba1638811e8E0",
  },
  borrowerOperations: {
    address: "0x8B8776BEDB1eD89Ab6BD2388bCbef3C579ca53b9",
    txHash: "0xDead"
  },
  starToken: {
    address: "0xFCc4Ba754Ae127396D3c6dCA507389b5A5b6EFAe",
    txHash: "0xDead"
  },
  stMaticVault: {
    address: "0xe1647c832E112c532C79bDf3cFD05362b6e8c744",
    txHash: "0xDead"
  },
  usdPlusVault: {
    address: "0x8E02433C31B51ABe3Ac65908d59eF82ddB52714F",
    txHash: "0xDead"
  }
};
